import { gql, useMutation, useQuery } from "@apollo/client"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import ChannelPermissionsListView from "../features/channel-permissions/channel-permissions-list-view"
import {
  ChannelPermissionsQuery,
  ChannelPermissionsQueryVariables,
  UpdateChannelRoleMutation,
  UpdateChannelRoleMutationVariables,
} from "../generated/graphql"

const QUERY = gql`
  query ChannelPermissions($organizationSlug: String!, $channelSlug: String!) {
    channel(organizationSlug: $organizationSlug, channelSlug: $channelSlug) {
      id
      name
      slugs
      description
      roles(args: { limit: 100 }) {
        items {
          id
          name
          description
          permissions
        }
      }
    }
  }
`

const UPDATE_CHANNEL_ROLE = gql`
  mutation UpdateChannelRole($roleId: String!, $deleted: Boolean!) {
    updateRole(input: { roleId: $roleId, deleted: $deleted }) {
      id
    }
  }
`

export default function ChannelPermissionsScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const { organizationSlug, channelSlug } = useParams<{
    organizationSlug: string
    channelSlug: string
  }>()
  const { data: channelPeopleWithRolesData, refetch: refetchChannelRoles } =
    useQuery<ChannelPermissionsQuery, ChannelPermissionsQueryVariables>(QUERY, {
      variables: {
        organizationSlug: organizationSlug!,
        channelSlug: channelSlug!,
      },
      skip: !organizationSlug || !channelSlug,
    })
  const [updateChannelRole] = useMutation<
    UpdateChannelRoleMutation,
    UpdateChannelRoleMutationVariables
  >(UPDATE_CHANNEL_ROLE)

  useEffect(() => {
    refetchChannelRoles()
  }, [refetchChannelRoles])

  if (!channelPeopleWithRolesData?.channel) {
    return null
  }

  return (
    <div className="w-screen max-w-[512px]">
      <ChannelPermissionsListView
        organizationSlug={organizationSlug!}
        channelSlug={channelSlug!}
        channelRoles={channelPeopleWithRolesData.channel?.roles?.items ?? []}
        onDiscardClick={onDiscardClick}
        onDeleteClick={async (roleId: string) => {
          await updateChannelRole({
            variables: {
              roleId,
              deleted: true,
            },
          })
          await refetchChannelRoles()
        }}
      />
    </div>
  )
}
