import { useLayoutEffect, useMemo, useState } from "react"

export type UseMeasureRect = Pick<
  DOMRectReadOnly,
  "x" | "y" | "top" | "left" | "right" | "bottom" | "height" | "width"
>

export const useMeasure = <TElement extends HTMLElement = HTMLElement>(): [
  (element: TElement | null) => void,
  UseMeasureRect
] => {
  const [element, setElement] = useState<TElement | null>(null)
  const [rect, setRect] = useState<UseMeasureRect>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })

  const observer = useMemo(
    () =>
      new window.ResizeObserver(entries => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect
          setRect({ x, y, width, height, top, left, bottom, right })
        }
      }),
    []
  )

  useLayoutEffect(() => {
    if (!element) {
      return () => {}
    }

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [element, observer])

  return [setElement, rect]
}
