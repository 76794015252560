/**
 * Returns the organization slug extracted from the path of the provided URI.
 *
 * @param uri - The optional URI to use. Otherwise, the current browser location.
 * @returns An organization slug or null.
 */
export const getOrganizationSlug = (uri = window.location.href) => {
  try {
    const url = new URL(uri)
    return url.pathname.slice(1).split("/")[0] || null
  } catch (error: any) {
    if (/Invalid\sURL/.test(error.message)) {
      return null
    }
    throw error
  }
}
