export default function UploadBanner() {
  return (
    <div className="flex-col border border-tv-dandelion rounded-xl p-4">
      <div className="text-body1 text-tv-dandelion font-semibold">
        Upload your other events
      </div>
      <div className="text-body2 text-tv-dust mt-[0.25rem]">
        Bring your events from Zoom or Google Meet,{" "}
        <span className="text-tv-milk">
          drag and drop any video file to upload
        </span>
      </div>
    </div>
  )
}
