import { useIntl } from "react-intl"
import { IconType } from "../../atoms/icon/types"
import PeopleDropdown from "../../atoms/people-dropdown"

export default function VideoViewProps({
  items,
}: {
  items: Array<{
    initials: string
    name: string
    profileImageUri?: string
    props: number
  }>
}) {
  const intl = useIntl()

  return (
    <PeopleDropdown
      anonymousName={intl.formatMessage({
        description: "Video viewers anonymous name",
        defaultMessage: "Anonymous",
      })}
      disabled={!items.length}
      items={items.map(prop => ({
        initials: prop.initials || "",
        name: prop.name!,
        profileImageUri: prop.profileImageUri,
        actionCounter: intl.formatMessage(
          {
            description: "Video props for person prop count label",
            defaultMessage: "{props, plural, one {# prop} other {# props}}",
          },
          {
            props: prop.props,
          }
        ),
      }))}
      dropdownIcon={IconType.Props}
      dropdownLabel={intl.formatMessage(
        {
          description: "Video props prop count label",
          defaultMessage: "{props, plural, one {# prop} other {# props}}",
        },
        {
          props: items.reduce((total, prop) => total + prop.props, 0),
        }
      )}
    />
  )
}
