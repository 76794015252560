import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import _ from "lodash"
import { useContext, useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Button from "../atoms/button"
import SearchTextField from "../atoms/search-text-field"
import TeamRoleView from "../features/teams/team-role-view"
import TeamSearchView from "../features/teams/team-search-view"
import TeamUpdatedToast from "../features/teams/team-updated-toast"
import {
  Permission,
  TeamAddPeopleToRoleMutation,
  TeamAddPeopleToRoleMutationVariables,
  TeamCreatePersonMutation,
  TeamCreatePersonMutationVariables,
  TeamGetRolesQuery,
  TeamGetRolesQueryVariables,
  TeamRemovePersonFromRoleMutation,
  TeamRemovePersonFromRoleMutationVariables,
  TeamSearchPeopleQuery,
  TeamSearchPeopleQueryVariables,
} from "../generated/graphql"
import { UserContext } from "../user-provider"
import { FORMAT_PATTERNS, ValidationFormat } from "../utils/validate"

const GET_ROLES = gql`
  query TeamGetRoles(
    $organizationSlug: String!
    $hideInternalPeople: Boolean!
  ) {
    organization(organizationSlug: $organizationSlug) {
      id
      roles(args: { limit: 50 }) {
        items {
          id
          name
          description
          people(
            args: { limit: 50 }
            filter: { hideInternalPeople: $hideInternalPeople }
          ) {
            items {
              id
              name
              email
              profileImageUri
              initials
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalCount
        }
      }
    }
  }
`

const SEARCH_PEOPLE = gql`
  query TeamSearchPeople(
    $organizationSlug: String!
    $hideInternalPeople: Boolean!
    $query: String!
  ) {
    organization(organizationSlug: $organizationSlug) {
      id
      people(
        args: { limit: 20 }
        filter: { hideInternalPeople: $hideInternalPeople }
        query: $query
      ) {
        items {
          id
          name
          email
          profileImageUri
          initials
          roles {
            items {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalCount
        }
      }
    }
  }
`

const ADD_PEOPLE_TO_ROLE = gql`
  mutation TeamAddPeopleToRole($roleId: String!, $personIds: [String!]!) {
    addPeopleToRole(input: { roleId: $roleId, personIds: $personIds }) {
      id
    }
  }
`

const REMOVE_PERSON_FROM_ROLE = gql`
  mutation TeamRemovePersonFromRole($roleId: String!, $personId: String!) {
    removePersonFromRole(input: { roleId: $roleId, personId: $personId }) {
      id
    }
  }
`

const CREATE_PERSON = gql`
  mutation TeamCreatePerson($organizationId: String!, $email: String!) {
    createPerson(input: { organizationId: $organizationId, email: $email }) {
      id
      email
    }
  }
`

const INTERNAL_ORGANIZATION_SLUG = "venue"
const INTERNAL_ADMIN_EMAIL = "@venue.live"

/**
 * Whether to hide internal people.
 * Show internal people if organization is Venue, or if current user is a Venue user.
 */
const shouldHideInternalPeople = (
  userEmail: string,
  organizationSlug: string
) =>
  organizationSlug !== INTERNAL_ORGANIZATION_SLUG &&
  !userEmail.endsWith(INTERNAL_ADMIN_EMAIL)

export default function TeamScreen({
  onCancelClick,
}: {
  onCancelClick: () => void
}) {
  const intl = useIntl()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { email, hasPermission } = useContext(UserContext)
  const [searchQuery, setSearchQuery] = useState("")
  const hideInternalPeople = shouldHideInternalPeople(email!, organizationSlug!)
  const [roleAssignments, setRoleAssignments] = useState<
    Array<{
      personId: string
      oldRoleId: string | null
      newRoleId: string | null
    }>
  >([])
  const { data: rolesData, refetch: refetchRoles } = useQuery<
    TeamGetRolesQuery,
    TeamGetRolesQueryVariables
  >(GET_ROLES, {
    variables: { organizationSlug: organizationSlug!, hideInternalPeople },
  })
  const [runSearch, { data: searchData, refetch: reRunSearch }] = useLazyQuery<
    TeamSearchPeopleQuery,
    TeamSearchPeopleQueryVariables
  >(SEARCH_PEOPLE)
  const debouncedSearch = useRef(
    _.debounce(async (query: string) => {
      if (query.length >= 3) {
        await runSearch({
          variables: {
            organizationSlug: organizationSlug!,
            hideInternalPeople,
            query,
          },
        })
      }
    }, 250)
  ).current
  const [runAddPeopleMutation] = useMutation<
    TeamAddPeopleToRoleMutation,
    TeamAddPeopleToRoleMutationVariables
  >(ADD_PEOPLE_TO_ROLE)
  const [runRemovePersonMutation] = useMutation<
    TeamRemovePersonFromRoleMutation,
    TeamRemovePersonFromRoleMutationVariables
  >(REMOVE_PERSON_FROM_ROLE)
  const [runCreatePersonMutation] = useMutation<
    TeamCreatePersonMutation,
    TeamCreatePersonMutationVariables
  >(CREATE_PERSON)

  useEffect(() => {
    if (rolesData?.organization) {
      setRoleAssignments(
        rolesData.organization.roles.items
          .map(role =>
            role.people.items.map(person => ({
              personId: person.id,
              oldRoleId: role.id,
              newRoleId: role.id,
            }))
          )
          .reduce((arr, item) => [...arr, ...item], [])
      )
    }
  }, [rolesData])

  const createPersonLoading = false

  const handleChangeSearchQuery = async (query: string) => {
    await debouncedSearch(query)
    setSearchQuery(query)
  }

  const handleChangeRole = (personId: string, roleId: string | null) => {
    setRoleAssignments(prevState => {
      const newState = [...prevState]
      const index = newState.findIndex(item => item.personId === personId)
      if (index === -1) {
        newState.push({ personId, oldRoleId: null, newRoleId: roleId })
      } else {
        newState[index] = { ...newState[index], newRoleId: roleId }
      }
      return newState
    })
  }

  const handleUpdateClick = async () => {
    const peopleToAdd = roleAssignments
      .filter(it => it.oldRoleId !== it.newRoleId && it.newRoleId !== null)
      .reduce<Record<string, Array<string>>>(
        (map, item) => ({
          ...map,
          [item.newRoleId!]: [...(map[item.newRoleId!] ?? []), item.personId],
        }),
        {}
      )

    const peopleToRemove = roleAssignments.filter(
      it => it.oldRoleId !== it.newRoleId && it.oldRoleId !== null
    )

    for (const [roleId, personIds] of Object.entries(peopleToAdd)) {
      await runAddPeopleMutation({
        variables: { roleId, personIds },
      })
    }

    for (const { personId, oldRoleId: roleId } of peopleToRemove) {
      await runRemovePersonMutation({
        variables: { roleId: roleId!, personId },
      })
    }

    await refetchRoles() // refetch newly assigned roles for new (or existing) user

    toast(
      t => <TeamUpdatedToast onDismissClick={() => toast.dismiss(t.id)} />,
      {
        id: "team-updated",
      }
    )
  }

  const handleCreateUserClick = async () => {
    await runCreatePersonMutation({
      variables: {
        organizationId: rolesData!.organization.id,
        email: searchQuery,
      },
    })
    await reRunSearch() // refetch new search results with new user
    await refetchRoles() // refetch new people count and newly assigned roles for new user
  }

  const roles = rolesData?.organization.roles.items ?? []
  const people = searchData?.organization.people.items ?? []
  const roleItems = roles.map(role => ({ value: role.id, label: role.name }))

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-auto px-10">
        <div className="px-16 py-8 flex flex-col gap-8">
          <SearchTextField
            divClassName="w-full"
            placeholder={intl.formatMessage({
              description: "Team search placeholder",
              defaultMessage: "Enter email address to add a team member",
            })}
            onChangeText={handleChangeSearchQuery}
            value={searchQuery}
          />
          {searchQuery ? (
            <>
              {FORMAT_PATTERNS[ValidationFormat.Email].test(searchQuery) &&
              !people.length &&
              hasPermission(Permission.WritePeople) ? (
                <div className="flex flex-row items-center justify-between gap-4">
                  <FormattedMessage
                    defaultMessage="Do you want to create a user with the email address {searchQuery}?"
                    description=""
                    values={{ searchQuery }}
                  />
                  <Button
                    label="Create user"
                    theme="secondary"
                    onClick={handleCreateUserClick}
                    className="whitespace-nowrap"
                    disabled={createPersonLoading}
                  />
                </div>
              ) : null}
              <TeamSearchView
                onChangeValue={handleChangeRole}
                people={people.map(person => ({
                  email: person.email,
                  id: person.id,
                  initials: person.initials ?? null,
                  name: person.name ?? null,
                  profileImageUri: person.profileImageUri ?? null,
                  roleId:
                    roleAssignments.find(it => it.personId === person.id)
                      ?.newRoleId ??
                    person.roles.items[0]?.id ??
                    null,
                }))}
                roleItems={roleItems}
                title={intl.formatMessage(
                  {
                    description: "Team search result count",
                    defaultMessage:
                      "{people, plural, one {# person found} other {# people found}}",
                  },
                  {
                    people: people.length,
                  }
                )}
              />
            </>
          ) : (
            roles.map(role => (
              <TeamRoleView
                key={role.id}
                onChangeValue={handleChangeRole}
                people={role.people.items
                  .filter(
                    person =>
                      roleAssignments.find(it => it.personId === person.id)
                        ?.newRoleId !== null
                  )
                  .map(person => ({
                    email: person.email,
                    id: person.id,
                    initials: person.initials ?? null,
                    name: person.name ?? null,
                    profileImageUri: person.profileImageUri ?? null,
                    roleId:
                      roleAssignments.find(it => it.personId === person.id)
                        ?.newRoleId ?? role.id,
                  }))}
                roleDescription={role.description ?? undefined}
                roleItems={roleItems}
                roleName={role.name}
              />
            ))
          )}
        </div>
      </div>
      <div className="px-10 py-4 border-t border-tv-coal flex flex-row gap-4">
        <Button
          label={intl.formatMessage({
            defaultMessage: "Update",
            description: "Team update button label",
          })}
          onClick={handleUpdateClick}
          theme="primary"
        />
        <Button
          label={intl.formatMessage({
            defaultMessage: "Cancel",
            description: "Team cancel button label",
          })}
          onClick={onCancelClick}
          theme="tertiary"
        />
      </div>
    </div>
  )
}
