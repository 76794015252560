import { createContext, ReactNode, useMemo, useState } from "react"

type SideNavContextType = {
  draggingType: "video" | "channel" | "channel-folder" | null
  isExpanded: boolean
  isFolderExpanded: (folderId: string) => boolean
  isInitiallyExpanded: boolean
  setDraggingType: (type: "video" | "channel" | "channel-folder" | null) => void
  setExpanded: (b: boolean) => void
  setFolderExpanded: (folderId: string, expanded: boolean) => void
  setInitiallyExpanded: (b: boolean) => void
}

export const FOLDER_EXPANSION_KEY = "expanded_channel_folders"

export const SideNavContext = createContext<SideNavContextType>({
  draggingType: null,
  isExpanded: false,
  isFolderExpanded: () => {
    throw new Error(
      "SideNavContext was used without SideNavProvider being a parent."
    )
  },
  isInitiallyExpanded: false,
  setDraggingType: () => {
    throw new Error(
      "SideNavContext was used without SideNavProvider being a parent."
    )
  },
  setExpanded: () => {
    throw new Error(
      "SideNavContext was used without SideNavProvider being a parent."
    )
  },
  setFolderExpanded: () => {
    throw new Error(
      "SideNavContext was used without SideNavProvider being a parent."
    )
  },
  setInitiallyExpanded: () => {
    throw new Error(
      "SideNavContext was used without SideNavProvider being a parent."
    )
  },
})

export default function SideNavProvider({
  children,
}: {
  children?: ReactNode
}) {
  const [draggingType, setDraggingType] = useState<
    "video" | "channel" | "channel-folder" | null
  >(null)
  const [isExpanded, setExpanded] = useState(false)
  const [isInitiallyExpanded, setInitiallyExpanded] = useState(true)
  const [expandedFolders, setExpandedFolders] = useState<Array<string>>(() => {
    try {
      return JSON.parse(localStorage.getItem(FOLDER_EXPANSION_KEY) || "[]")
    } catch {
      return []
    }
  })

  const value = useMemo(
    () => ({
      draggingType,
      isExpanded,
      isFolderExpanded: (folderId: string) =>
        expandedFolders.includes(folderId),
      isInitiallyExpanded,
      setDraggingType,
      setExpanded,
      setFolderExpanded: (folderId: string, expanded: boolean) => {
        setExpandedFolders(prev => {
          let newExpandedFolders = [...prev]
          if (expanded && !newExpandedFolders.includes(folderId)) {
            newExpandedFolders.push(folderId)
          } else if (!expanded) {
            newExpandedFolders = newExpandedFolders.filter(
              id => id !== folderId
            )
          }
          localStorage.setItem(
            FOLDER_EXPANSION_KEY,
            JSON.stringify(newExpandedFolders)
          )
          return newExpandedFolders
        })
      },
      setInitiallyExpanded,
    }),
    [draggingType, expandedFolders, isExpanded, isInitiallyExpanded]
  )

  return (
    <SideNavContext.Provider value={value}>{children}</SideNavContext.Provider>
  )
}
