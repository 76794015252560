import classNames from "classnames"
import _ from "lodash"
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  KeyboardEventHandler,
  forwardRef,
  useRef,
  useState,
} from "react"
import HelperText from "../helper-text"
import Icon from "../icon"
import { IconType } from "../icon/types"
import Label from "../label"
import TextField from "../text-field"

const MultiTextField = forwardRef<
  HTMLInputElement,
  {
    disabled?: boolean
    helperText?: string
    label: string
    onChangeValue: (newValue: Array<string>) => void
    prefix?: string
    value: Array<string>
  } & Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "ref"
  >
>(
  (
    {
      disabled = false,
      helperText,
      label,
      onChangeValue,
      prefix = "",
      value,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState("")
    const inputId = useRef(_.uniqueId())

    const handleInputKeyUp: KeyboardEventHandler<HTMLInputElement> = event => {
      if (event.key === "Enter") {
        event.preventDefault()

        if (disabled) {
          return
        }

        let valueToAdd = event.currentTarget.value.trim().toLowerCase()
        if (prefix && valueToAdd.startsWith(prefix)) {
          valueToAdd = valueToAdd.slice(prefix.length)
        }
        if (valueToAdd && !value.includes(valueToAdd)) {
          onChangeValue([...value, valueToAdd])
          setInputValue("")
        }
      }
    }

    const handleRemoveClick = (valueToRemove: string) => {
      if (disabled) {
        return
      }

      if (value.includes(valueToRemove)) {
        onChangeValue(value.filter(it => it !== valueToRemove))
      }
    }

    return (
      <>
        <div>
          <Label htmlFor={inputId.current}>{label}</Label>
        </div>
        <TextField
          id={inputId.current}
          className="w-full"
          disabled={disabled}
          onChangeText={setInputValue}
          onKeyDown={handleInputKeyUp}
          ref={ref}
          value={inputValue}
          {...props}
        />
        {!!helperText && <HelperText>{helperText}</HelperText>}
        <div>
          {value.map(valueItem => (
            <div key={valueItem} className="py-1">
              <div
                className={classNames(
                  "appearance-none flex flex-row items-center px-2 py-[7px] bg-tv-coal rounded-lg border",
                  !disabled && "border-tv-coal text-tv-dust",
                  disabled && "border-tv-raven text-tv-gunmetal"
                )}
              >
                <span className="flex-1 text-[14px] leading-[14px]">{`${prefix}${valueItem}`}</span>
                <Icon
                  color={disabled ? "#5e626a" : "#666666"}
                  type={IconType.Close}
                  size="medium"
                  className="basis-[24px] cursor-pointer"
                  onClick={() => handleRemoveClick(valueItem)}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
)

MultiTextField.displayName = "MultiTextField"
export default MultiTextField
