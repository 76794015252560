/**
 * Returns the URI to link to for Okta SSO login.
 * @param redirect - The URL to redirect to after logging in. If not provided, uses the current URL.
 * @returns The URI to link to for Okta SSO login.
 */
export const getOktaAuthUri = (
  baseUri: string,
  clientId: string,
  pkceChallenge: string,
  redirect = window.location.href
) => {
  const redirectUrl = new URL(redirect)
  const currentUrl = new URL(window.location.href)

  const authUrl = new URL(`${baseUri}oauth2/v1/authorize`)
  authUrl.searchParams.append("client_id", clientId)
  authUrl.searchParams.append(
    "redirect_uri",
    `${currentUrl.protocol}//${currentUrl.host}/okta-callback`
  )
  authUrl.searchParams.append("response_type", "code")
  authUrl.searchParams.append("code_challenge", pkceChallenge)
  authUrl.searchParams.append("code_challenge_method", "S256")
  authUrl.searchParams.append("scope", "openid email profile groups")
  authUrl.searchParams.append(
    "state",
    JSON.stringify({
      redirect: `${redirectUrl.protocol}//${redirectUrl.host}${redirectUrl.pathname}${redirectUrl.search}`,
    })
  )
  return authUrl.toString()
}
