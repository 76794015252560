import classNames from "classnames"
import { DetailedHTMLProps, LabelHTMLAttributes } from "react"

export default function Label({
  children,
  className,
  ...props
}: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>) {
  return (
    <label
      className={classNames(
        "inline-block text-tv-dust text-body2 font-semibold pb-2",
        className
      )}
      {...props}
    >
      {children}
    </label>
  )
}
