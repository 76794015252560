import classNames from "classnames"
import { Link, useParams } from "react-router-dom"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Tooltip from "../../atoms/tooltip"

export default function CreateChannelDivider({
  expanded,
}: {
  expanded: boolean
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-center mt-1 mb-4"
      )}
    >
      <div className="grow h-[2px] rounded-[2px] bg-tv-beast/[0.32]" />

      <Link
        to={`/${organizationSlug}/create-channel`}
        className={classNames(
          "flex items-center justify-center mx-2 rounded-3xl bg-tv-dandelion",
          expanded ? "w-6 h-6" : "w-4 h-4"
        )}
      >
        <Tooltip label="Create Channel" placement="right">
          <Icon
            className={classNames(expanded ? "w-4 h-4" : "w-2 h-2")}
            size="small"
            type={IconType.Plus}
          />
        </Tooltip>
      </Link>

      <div className="grow h-[2px] rounded-[2px] bg-tv-beast/[0.32]" />
    </div>
  )
}
