import classNames from "classnames"
import { Fragment, useContext } from "react"
import { useParams } from "react-router-dom"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Link from "../../atoms/link"
import {
  ChannelFolderNavItem as GraphQLChannelFolderNavItem,
  ChannelNavItem as GraphQLChannelNavItem,
  NavItem as GraphQLNavItem,
  Permission,
} from "../../generated/graphql"
import { UserContext } from "../../user-provider"
import CreateChannelDivider from "./create-channel-divider"
import NavItemIcon from "./nav-item-icon"
import { SideNavContext } from "./side-nav-provider"

export default function CollapsedSideNav({
  navItems,
  onExpandClick,
  organizationLogoImageUri,
}: {
  navItems: Array<GraphQLNavItem>
  onExpandClick: () => void
  organizationLogoImageUri?: string
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { isLoggedIn, hasPermission, logOut } = useContext(UserContext)
  const { isFolderExpanded } = useContext(SideNavContext)

  return (
    <div
      className={classNames(
        "w-[48px] h-full py-4",
        "bg-tv-coal",
        "flex flex-col justify-start items-center"
      )}
    >
      <div className="mb-[2rem]">
        <button
          className="appearance-none w-[32px] h-[32px] flex items-center justify-center"
          onClick={onExpandClick}
          type="button"
        >
          <Icon size="small" type={IconType.Expand} />
        </button>
      </div>
      <div className="overflow-y-auto flex flex-col gap-1">
        {navItems.map(navItem => {
          if (navItem.__typename === "ChannelNavItem") {
            const channelNavItem = navItem as GraphQLChannelNavItem

            return (
              <Link
                key={channelNavItem.id}
                to={`/${organizationSlug}/channels/${channelNavItem.slugs[0]}`}
                className="p-1 border-2 border-transparent"
              >
                <NavItemIcon
                  imageUri={channelNavItem.logoImageUri ?? undefined}
                  initials={channelNavItem.name.slice(0, 1)}
                />
              </Link>
            )
          }

          if (navItem.__typename === "ChannelFolderNavItem") {
            const channelFolderNavItem = navItem as GraphQLChannelFolderNavItem
            const isExpanded = isFolderExpanded(channelFolderNavItem.id)
            return (
              <Fragment key={channelFolderNavItem.id}>
                <div className="h-[32px] border-2 border-transparent" />
                {channelFolderNavItem.children
                  .filter(
                    childNavItem =>
                      isExpanded && childNavItem.__typename === "ChannelNavItem"
                  )
                  .map(childNavItem => {
                    const channelNavItem = childNavItem as GraphQLChannelNavItem
                    return (
                      <Link
                        key={channelNavItem.id}
                        to={`/${organizationSlug}/channels/${channelNavItem.slugs[0]}`}
                        className="p-1 border-2 border-transparent"
                      >
                        <NavItemIcon
                          imageUri={channelNavItem.logoImageUri ?? undefined}
                          initials={channelNavItem.name.slice(0, 1)}
                        />
                      </Link>
                    )
                  })}
              </Fragment>
            )
          }

          return null
        })}
        {hasPermission(Permission.WriteChannels) ? (
          <CreateChannelDivider expanded={false} />
        ) : null}
      </div>
      <div className="flex-1 flex flex-col justify-end items-center gap-1">
        {hasPermission(Permission.UpdateOrganization) && (
          <Link
            to={`/${organizationSlug}/edit`}
            className="p-1 border-2 border-transparent"
          >
            <NavItemIcon imageUri={organizationLogoImageUri} initials="W" />
          </Link>
        )}
        {isLoggedIn === true && (
          <button
            type="button"
            onClick={logOut}
            className="appearance-none p-1 border-2 border-transparent"
          >
            <NavItemIcon icon={IconType.Logout} initials="" />
          </button>
        )}
      </div>
    </div>
  )
}
