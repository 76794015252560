import { useParams } from "react-router-dom"
import Link from "../../atoms/link"
import Squircle from "../../atoms/squircle"

export default function OrganizationLink({
  logoImageUri,
}: {
  logoImageUri?: string
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()

  return (
    <Link to={`/${organizationSlug}`}>
      <Squircle
        size={56}
        className="bg-tv-milk bg-center bg-cover"
        style={{
          backgroundImage: logoImageUri ? `url(${logoImageUri})` : undefined,
        }}
      />
    </Link>
  )
}
