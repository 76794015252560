import { useIntl } from "react-intl"
import Button from "../../atoms/button"
import TranscriptionPlaceholder from "./transcription-placeholder"

export default function TranscriptionsNotReadyPanel({
  onStartTranscribingClick,
  status,
}: {
  onStartTranscribingClick: () => void
  status: "missing" | "preparing_video" | "transcribing"
}) {
  const intl = useIntl()

  let buttonLabel: string
  switch (status) {
    case "preparing_video":
      buttonLabel = intl.formatMessage({
        defaultMessage: "Preparing Video...",
        description: "Transcriptions preparing video button label",
      })
      break
    case "transcribing":
      buttonLabel = intl.formatMessage({
        defaultMessage: "Transcribing...",
        description: "Transcriptions processing transcriptions button label",
      })
      break
    default:
      buttonLabel = intl.formatMessage({
        defaultMessage: "Start transcribing",
        description: "Transcriptions start transcribing button label",
      })
      break
  }

  return (
    <div className="relative h-full overflow-hidden flex items-center justify-center">
      <div className="absolute inset-0 flex flex-col gap-8 opacity-[15%] -z-[1]">
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
        <TranscriptionPlaceholder />
      </div>
      <div className="absolute top-0 inset-x-0 h-[50px] max-x-full bg-gradient-to-b from-tv-obsidian -z-[1]" />
      <div className="absolute bottom-0 inset-x-0 h-[50px] max-x-full bg-gradient-to-t from-tv-obsidian -z-[1]" />
      <Button
        beta={status === "missing"}
        disabled={status !== "missing"}
        label={buttonLabel}
        theme="tertiary"
        onClick={onStartTranscribingClick}
      />
    </div>
  )
}
