import { useEffect, useState } from "react"
import { generatePkceVerifierAndComputeChallenge } from "../pkce"

export const usePkceChallenge = () => {
  const [pkceChallenge, setPkceChallenge] = useState<string | null>(null)

  useEffect(() => {
    async function loadPkceChallenge() {
      setPkceChallenge(await generatePkceVerifierAndComputeChallenge())
    }

    loadPkceChallenge()
  }, [])

  return {
    pkceChallenge,
  }
}
