import Rollbar from "rollbar"
import {
  getUserToken,
  getUserTokenClaims,
  subscribeToUserTokenChanges,
} from "./auth"
import env from "./env"

let rollbar: Rollbar
let loggedIn = false

const updateIdentity = (newUserToken: string | null | undefined) => {
  const newLoggedIn = !!newUserToken
  if (newLoggedIn === loggedIn) {
    return
  }

  loggedIn = newLoggedIn

  if (loggedIn) {
    const { personId, email } = getUserTokenClaims()

    rollbar!.configure({
      payload: {
        person: {
          id: personId!,
          email: email!,
        },
      },
    })
  } else {
    rollbar!.configure({
      payload: {
        person: undefined,
      },
    })
  }
}

export const getRollbarInstance = () => {
  if (!rollbar) {
    rollbar = new Rollbar({
      accessToken: env.rollbarAccessToken(),
      environment: env.rollbarEnvironment(),
      version: env.rollbarVersion(),
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: env.rollbarEnvironment() !== "development",
    })

    subscribeToUserTokenChanges(updateIdentity)

    updateIdentity(getUserToken())
  }

  return rollbar
}
