import { useCallback } from "react"
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Button from "../../atoms/button"
import { IconType } from "../../atoms/icon/types"
import TextField from "../../atoms/text-field"
import ValidationError from "../../atoms/validation-error"
import { FORMAT_PATTERNS, ValidationFormat } from "../../utils/validate"
import type { ValueType } from "./edit-channel-form"

export default function MultiSlugEditor({
  currentLocation,
  disabled = false,
  errors,
  register,
  setValue,
  watch,
}: {
  currentLocation: string
  disabled?: boolean
  errors: FieldErrors<ValueType>
  register: UseFormRegister<ValueType>
  setValue: UseFormSetValue<ValueType>
  watch: UseFormWatch<ValueType>
}) {
  const intl = useIntl()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const url = new URL(currentLocation)
  const urlPrefix = `${url.protocol}//${url.host}/${organizationSlug}/channels/`

  const slugs = watch("slugs")

  const handleDeleteClick = useCallback(
    (index: number) => {
      const newValue = [...slugs]
      newValue.splice(index, 1)
      setValue("slugs", newValue)
    },
    [setValue, slugs]
  )

  return (
    <div className="flex flex-col gap-2">
      {slugs.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          <div className="flex flex-row items-center gap-2">
            <span>{urlPrefix}</span>
            <div className="flex-1">
              <TextField
                id="slug"
                autoComplete="off"
                invalid={!!errors.slugs?.[index]}
                placeholder={intl.formatMessage({
                  defaultMessage: "team-socials",
                  description: "Edit channel form slug placeholder",
                })}
                maxLength={255}
                size={40}
                className="w-full"
                {...register(`slugs.${index}`, {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      defaultMessage: "Channel URL is required",
                      description: "Create channel form slug required message",
                    }),
                  },
                  pattern: {
                    value: FORMAT_PATTERNS[ValidationFormat.Slug],
                    message: intl.formatMessage({
                      defaultMessage:
                        "Channel URL suffix must start with letter, and contain only letters, numbers, and dashes",
                      description: "Create channel form slug format message",
                    }),
                  },
                })}
              />
            </div>
            <div>
              <Button
                disabled={disabled}
                icon={IconType.Delete}
                iconSize="small"
                iconColor="#bf444e"
                onClick={() => handleDeleteClick(index)}
                theme="danger"
              />
            </div>
          </div>
          {errors.slugs?.[index] && (
            <ValidationError>{errors.slugs[index]!.message!}</ValidationError>
          )}
        </div>
      ))}
      <div className="flex flex-row justify-end">
        <Button
          disabled={disabled}
          icon={IconType.Plus}
          iconSize="small"
          theme="secondary"
          onClick={() => {
            setValue("slugs", [...slugs, ""])
          }}
        />
      </div>
    </div>
  )
}
