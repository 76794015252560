/**
 * NOTE: Getters have to be wrapped in try-catch because import.meta.env is very brittle.
 * If the env variables are not supplied, the app will break at build time unless the use is wrapped in a try-catch.
 */

export default {
  apiBaseUri: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_API_URI
    } catch {
      value = undefined
    }
    let baseUri = value || "http://localhost:3001"
    if (!baseUri.endsWith("/")) {
      baseUri += "/"
    }
    return baseUri
  },

  dashboardBaseUri: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_DASHBOARD_BASE_URI
    } catch {
      value = undefined
    }
    let baseUri = value || "http://localhost:3000/dashboard"
    if (!baseUri.endsWith("/")) {
      baseUri += "/"
    }
    return baseUri
  },

  googleClientId: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_GOOGLE_CLIENT_ID
    } catch {
      value = undefined
    }
    return value || ""
  },

  microsoftClientId: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_MICROSOFT_CLIENT_ID
    } catch {
      value = undefined
    }
    return value || ""
  },

  postHogApiKey: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_POST_HOG_API_KEY
    } catch {
      value = undefined
    }
    return value || "phc_5Fe1YmeR5XCqnQU6hYR2NHNK6nmtuUWy0xmw8F3PW47"
  },

  rollbarAccessToken: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN
    } catch {
      value = undefined
    }
    return value || "628bb516bf024722bacb1b033c54ae4a"
  },

  rollbarEnabled: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_ROLLBAR_ENABLED
    } catch {
      value = undefined
    }
    return value?.toLowerCase() === "true"
  },

  rollbarEnvironment: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_ROLLBAR_ENVIRONMENT
    } catch {
      value = undefined
    }
    return value || "development"
  },

  rollbarVersion: () => {
    let value: string | undefined
    try {
      value = import.meta.env.VITE_ROLLBAR_VERSION
    } catch {
      value = undefined
    }
    return value || ""
  },
}
