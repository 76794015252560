import { useIntl } from "react-intl"
import Button from "../../atoms/button"
import ButtonDiv from "../../atoms/button-div"
import { IconType } from "../../atoms/icon/types"
import Link from "../../atoms/link"

export default function ChannelPersonRoleView({
  roleName,
  roleDescription,
  assignRolePathUri,
  editRolePathUri,
  onDeleteClick,
}: {
  roleName: string
  roleDescription?: string
  assignRolePathUri: string
  editRolePathUri: string
  onDeleteClick: () => void
}) {
  const intl = useIntl()
  return (
    <div className="flex flex-col gap-2 pb-2">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <div className="text-title4">{roleName}</div>
          <div className="text-body2 text-tv-gray">{roleDescription}</div>
        </div>

        <div className="flex flex-row items-center gap-2">
          <Link to={assignRolePathUri} onClick={evt => evt.stopPropagation()}>
            <ButtonDiv
              icon={IconType.Plus}
              label={intl.formatMessage({
                defaultMessage: "Add to role",
                description: "Channel permissions form submit button label",
              })}
              iconSize="small"
              theme="tertiary"
            />
          </Link>

          <Link to={editRolePathUri} onClick={evt => evt.stopPropagation()}>
            <ButtonDiv
              icon={IconType.Edit}
              label={intl.formatMessage({
                defaultMessage: "Edit role",
                description: "Channel permissions form submit button label",
              })}
              iconSize="small"
              theme="tertiary"
            />
          </Link>
          <Button
            icon={IconType.Delete}
            iconSize="small"
            iconColor="#bf444e"
            onClick={onDeleteClick}
            theme="danger"
          />
        </div>
      </div>
    </div>
  )
}
