import { flip, offset, preventOverflow } from "@popperjs/core"
import classNames from "classnames"
import { useSelect } from "downshift"
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  useEffect,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { ChevronSvgBg } from "../../types"
import { mergeRefs } from "../../utils/merge-refs"
import ChannelLogo from "./channel-logo"

export type ChannelPickerItem = {
  id: string
  logoImageUri: string | null
  name: string
}

const ChannelPicker = forwardRef<
  HTMLButtonElement,
  {
    channels: Array<ChannelPickerItem>
    invalid?: boolean
    onChangeSelectedChannelId: (channelId: string) => void
    placeholder?: string
    selectedChannelId?: string
  } & Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "children"
  >
>(
  (
    {
      channels: channelsFromProps,
      className,
      disabled,
      invalid = false,
      onChangeSelectedChannelId,
      placeholder,
      selectedChannelId,
      style,
      ...props
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] =
      useState<HTMLButtonElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
    const [channels, setChannels] = useState(channelsFromProps)

    useEffect(() => {
      setChannels(channelsFromProps)
    }, [channelsFromProps])

    const {
      styles,
      attributes,
      update: updatePopper,
    } = usePopper(referenceElement, popperElement, {
      placement: "bottom-start",
      modifiers: [
        preventOverflow,
        flip,
        { ...offset, options: { offset: [0, 8] } },
      ],
    })

    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect<ChannelPickerItem>({
      items: channels,
      itemToString: it => it?.name ?? "",
      onIsOpenChange: evt => {
        if (evt.isOpen) {
          updatePopper?.()
        }
      },
      onSelectedItemChange: evt => {
        onChangeSelectedChannelId(evt.selectedItem?.id ?? "")
      },
      selectedItem:
        channelsFromProps.find(channel => channel.id === selectedChannelId) ??
        null,
    })

    return (
      <>
        <button
          className={classNames(
            "appearance-none",
            "h-[40px] m-0 pl-[10px] pr-[28px] rounded-lg",
            "flex flex-row items-center gap-2",
            "bg-tv-coal bg-no-repeat bg-right",
            "border border-tv-coal",
            "outline-none outline-offset-0 focus:outline focus:outline-tv-milk",
            "text-[14px] leading-none",
            invalid && "border-tv-poppy",
            disabled && "border-tv-raven",
            className
          )}
          disabled={disabled}
          style={{
            backgroundImage: `url("data:image/svg+xml;utf8,${
              isOpen ? ChevronSvgBg.Up : ChevronSvgBg.Down
            }")`,
            ...style,
          }}
          type="button"
          {...getToggleButtonProps(
            { ref: mergeRefs([ref, setReferenceElement]) },
            { suppressRefError: true }
          )}
          {...props}
        >
          {selectedItem ? (
            <>
              <ChannelLogo
                logoImageUri={selectedItem.logoImageUri ?? undefined}
                name={selectedItem.name}
              />
              <span className={disabled ? "text-tv-gunmetal" : "text-tv-milk"}>
                {selectedItem.name}
              </span>
            </>
          ) : null}
          {!selectedItem && !!placeholder ? (
            <span className={disabled ? "text-tv-gunmetal" : "text-tv-dust"}>
              {placeholder}
            </span>
          ) : null}
          {!selectedItem && !placeholder ? <span>&nbsp;</span> : null}
        </button>
        <ul
          className={classNames(
            "bg-tv-coal p-2 rounded-lg cursor-pointer z-10 drop-shadow-lg",
            (!isOpen || !channels.length) && "hidden"
          )}
          style={styles.popper}
          {...getMenuProps(
            { ref: setPopperElement },
            { suppressRefError: true }
          )}
          {...attributes.popper}
        >
          {isOpen &&
            channels.map((item, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames(
                  "text-[14px] leading-none rounded text-tv-dust p-2 py-1",
                  "flex flex-row items-center gap-2",
                  highlightedIndex === index && "bg-[#393939]",
                  selectedItem?.id === item.id && "text-tv-milk"
                )}
                {...getItemProps({ item, index })}
              >
                <ChannelLogo
                  logoImageUri={item.logoImageUri ?? undefined}
                  name={item.name}
                />
                <span>{item.name}</span>
              </li>
            ))}
        </ul>
      </>
    )
  }
)

ChannelPicker.displayName = "ChannelPicker"
export default ChannelPicker
