import { useDraggable } from "@dnd-kit/core"
import classNames from "classnames"
import {
  FormattedDate,
  FormattedMessage,
  FormattedRelativeTime,
} from "react-intl"
import { VideoStatus } from "../../generated/graphql"
import { useDurationDescription } from "../../hooks/use-duration-description"
import VisibilityBadge from "./visibility-badge"

export default function VideoTile({
  date,
  durationSeconds,
  id,
  isChannelPublic,
  isDraggable,
  isVideoPublic,
  status,
  thumbnailUri,
  title,
  viewCount,
}: {
  date: Date
  durationSeconds: number
  id: string
  isChannelPublic: boolean
  isDraggable: boolean
  isVideoPublic: boolean
  status: VideoStatus
  thumbnailUri?: string
  title: string
  viewCount: number
}) {
  const getDurationDescription = useDurationDescription()
  const dateOffsetSeconds = Math.floor((date.valueOf() - Date.now()) / 1000)
  const { isDragging, setNodeRef, listeners, attributes } = useDraggable({
    id,
    data: { title },
    disabled: !isDraggable,
  })

  return (
    <div
      aria-label="Draggable"
      className={classNames(
        "group flex flex-col touch-none",
        isDragging && "opacity-5"
      )}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <div
        className={classNames(
          "relative aspect-video rounded-xl transition-transform duration-100",
          "bg-tv-coal bg-center bg-contain bg-no-repeat",
          "group-focus:outline-milk group-focus:outline-4 group-focus:outline group-hover:scale-105"
        )}
        style={{
          backgroundImage:
            status === VideoStatus.Ready && thumbnailUri
              ? `url(${thumbnailUri})`
              : undefined,
        }}
      >
        <div className="absolute top-0">
          <VisibilityBadge
            isChannelPublic={isChannelPublic}
            isVideoPublic={isVideoPublic}
          />
        </div>
        <div className="absolute bottom-2 right-2 rounded bg-tv-midnight text-body2 px-2">
          {getDurationDescription(durationSeconds, "short")}
        </div>
        &nbsp;
      </div>
      <div className="text-title3 font-bold mt-4 group-hover:text-tv-dandelion group-focus:text-tv-dandelion">
        {title}
      </div>
      <div className="flex flex-row items-center gap-6 mt-2">
        <div className="text-tv-dust">
          {dateOffsetSeconds < -7 * 24 * 60 * 60 ? (
            <FormattedDate
              value={date}
              year="numeric"
              month="long"
              day="numeric"
            />
          ) : (
            <FormattedRelativeTime
              value={dateOffsetSeconds}
              updateIntervalInSeconds={30}
            />
          )}
        </div>
        <div className="text-tv-dust">
          <FormattedMessage
            defaultMessage="{viewCount, plural, one {# view} other {# views}}"
            description="View count on video tile"
            values={{
              viewCount,
            }}
          />
        </div>
      </div>
    </div>
  )
}
