import classNames from "classnames"
import { DetailedHTMLProps, HTMLAttributes } from "react"

export default function Container({
  className,
  children,
  fluid = false,
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  fluid?: boolean
}) {
  return (
    <div
      className={classNames(
        "mx-auto",
        !fluid && "px-6 lg:max-w-[1400px]",
        className
      )}
    >
      {children}
    </div>
  )
}
