import { useContext } from "react"
import { UserContext } from "../../user-provider"
import DynamicUpcomingEvents from "./dynamic-upcoming-events"
import StaticUpcomingEvents from "./static-upcoming-events"

export default function UpcomingEvents() {
  const { getFeatureFlag } = useContext(UserContext)

  return getFeatureFlag("show-upcoming-events-bar") === "true" ? (
    <DynamicUpcomingEvents />
  ) : (
    <StaticUpcomingEvents />
  )
}
