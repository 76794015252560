import { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import Link from "../../atoms/link"
import { Permission } from "../../generated/graphql"
import { UserContext } from "../../user-provider"

export default function ChannelHeader() {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { getFeatureFlag, hasPermission } = useContext(UserContext)

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <div className="text-tv-dust text-subhead4 leading-[2rem]">
          <FormattedMessage
            defaultMessage="Channels"
            description="Channel list channels header"
          />
        </div>
        {hasPermission(Permission.WriteChannels) && (
          <div className="flex flex-row gap-3">
            {getFeatureFlag("channel-folders") === "true" && (
              <Link to={`/${organizationSlug}/create-channel-folder`}>
                <span className="text-subhead4 text-tv-dandelion">
                  <FormattedMessage
                    defaultMessage="+ Folder"
                    description="Channel list create folder label"
                  />
                </span>
              </Link>
            )}
            <Link to={`/${organizationSlug}/create-channel`}>
              <span className="text-subhead4 text-tv-dandelion">
                <FormattedMessage
                  defaultMessage="+ Create"
                  description="Channel list create channel label"
                />
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
