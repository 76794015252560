import { useContext } from "react"
import { useIntl } from "react-intl"
import DropdownMenu, { DropdownMenuItemType } from "../../atoms/dropdown-menu"
import { IconType } from "../../atoms/icon/types"
import { Permission } from "../../generated/graphql"
import { UserContext } from "../../user-provider"

export default function ChannelOverflowMenu({
  isMobile = false,
  channelDeleted,
  channelId,
  onShareChannelClick,
  onToggleChannelDeletedClick,
}: {
  isMobile?: boolean
  channelDeleted: boolean
  channelId: string
  onShareChannelClick: () => void
  onToggleChannelDeletedClick: () => void
}) {
  const intl = useIntl()
  const { hasPermission, getFeatureFlag } = useContext(UserContext)

  const dropdownMenuItems: Array<DropdownMenuItemType> = []

  if (isMobile) {
    dropdownMenuItems.push({
      icon: IconType.Link,
      label: intl.formatMessage({
        defaultMessage: "Share",
        description: "Share channel button label",
      }),
      onClick: onShareChannelClick,
    })
  }

  if (hasPermission(Permission.WriteChannels, channelId)) {
    dropdownMenuItems.push({
      icon: IconType.Edit,
      label: intl.formatMessage({
        defaultMessage: "Edit Channel",
        description: "Channel overflow menu edit channel label",
      }),
      href: "edit",
    })

    if (
      getFeatureFlag("modify-channel-permissions") === "true" &&
      hasPermission(Permission.WriteChannels, channelId)
    ) {
      dropdownMenuItems.push({
        icon: IconType.Settings,
        label: intl.formatMessage({
          defaultMessage: "Modify Permissions",
          description: "Channel overflow menu edit channel permissions label",
        }),
        href: "permissions",
      })
    }

    dropdownMenuItems.push(
      channelDeleted
        ? {
            label: intl.formatMessage({
              defaultMessage: "Restore Channel",
              description: "Channel overflow menu restore channel label",
            }),
            onClick: onToggleChannelDeletedClick,
          }
        : {
            icon: IconType.Hidden,
            label: intl.formatMessage({
              defaultMessage: "Archive Channel",
              description: "Channel overflow menu archive channel label",
            }),
            onClick: onToggleChannelDeletedClick,
          }
    )
  }

  return dropdownMenuItems.length ? (
    <DropdownMenu
      icon={IconType.Overflow}
      iconSize="large"
      items={dropdownMenuItems}
      theme="tertiary"
    />
  ) : null
}
