import { flip, offset, Placement, preventOverflow } from "@popperjs/core"
import classNames from "classnames"
import { useSelect } from "downshift"
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { mergeRefs } from "../../utils/merge-refs"
import Button from "../button"
import { ButtonProps } from "../button/types"
import Icon from "../icon"
import { IconType } from "../icon/types"
import Link from "../link"

export type DropdownMenuItemType = {
  icon?: IconType
  href?: string
  target?: string
  label: string
  onClick?: () => void
}

const DropdownMenu = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    items?: Array<DropdownMenuItemType>
    placement?: Placement
  } & Omit<
      DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "children"
    >
>(({ items = [], placement = "bottom-end", ...props }, ref) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const {
    styles,
    attributes,
    update: updatePopper,
  } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      preventOverflow,
      flip,
      { ...offset, options: { offset: [0, 8] } },
    ],
  })

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<DropdownMenuItemType>({
    items,
    itemToString: it => it?.label ?? "",
    onIsOpenChange: evt => {
      if (evt.isOpen) {
        updatePopper?.()
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      selectedItem?.onClick?.()
    },
  })

  return (
    <>
      <Button
        {...getToggleButtonProps({
          ref: mergeRefs([ref, setReferenceElement]),
        })}
        {...props}
      />
      <ul
        className={classNames(
          "bg-tv-coal p-2 rounded-lg cursor-pointer z-10 drop-shadow-lg",
          !isOpen && "hidden"
        )}
        style={styles.popper}
        {...getMenuProps({ ref: setPopperElement }, { suppressRefError: true })}
        {...attributes.popper}
      >
        {isOpen &&
          items.map((item, index) => {
            const innerElement = (
              <div
                className={classNames(
                  "p-[8px] rounded flex flex-row items-center justify-start gap-2",
                  "text-[14px] leading-none",
                  highlightedIndex === index && "bg-[#393939]"
                )}
              >
                {item.icon && (
                  <Icon color="#666666" size="small" type={item.icon} />
                )}
                <span>{item.label}</span>
              </div>
            )
            return (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                tabIndex={index}
                {...getItemProps({ item, index })}
              >
                {item.href ? (
                  <Link to={item.href} target={item.target}>
                    {innerElement}
                  </Link>
                ) : (
                  innerElement
                )}
              </li>
            )
          })}
      </ul>
    </>
  )
})

DropdownMenu.displayName = "DropdownMenu"
export default DropdownMenu
