import classNames from "classnames"
import { DetailedHTMLProps, HTMLAttributes } from "react"
import { useDropzone } from "react-dropzone"
import Overlay from "./overlay"

export default function VideoUploadDropTarget({
  children,
  className,
  enabled = true,
  onDropAccepted,
  ...props
}: {
  enabled?: boolean
  onDropAccepted: (file: File) => void
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    noClick: true,
    noDrag: !enabled,
    accept: { "video/*": [] },
    onDropAccepted: ([file]) => onDropAccepted(file),
  })

  return (
    <div
      {...getRootProps()}
      className={classNames("h-full", className)}
      {...props}
    >
      <input {...getInputProps()} />
      {children}
      {isDragActive && (
        <Overlay isAccept={isDragAccept} isReject={isDragReject} />
      )}
    </div>
  )
}
