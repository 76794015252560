import { SelectItem } from "../../atoms/select"
import TeamPersonItem from "./team-person-item"

export default function TeamSearchView({
  onChangeValue,
  people,
  roleItems,
  title,
}: {
  onChangeValue: (personId: string, roleId: string | null) => void
  people: Array<{
    email: string
    id: string
    initials: string | null
    name: string | null
    profileImageUri: string | null
    roleId: string | null
  }>
  roleItems: Array<SelectItem>
  title: string
}) {
  return (
    <>
      <h2 className="text-2xl mb-2">{title}</h2>
      <div className="flex flex-col gap-4">
        {people.map(person => (
          <TeamPersonItem
            key={person.id}
            email={person.email}
            initials={person.initials ?? undefined}
            name={person.name ?? undefined}
            profileImageUri={person.profileImageUri ?? undefined}
            onChangeRole={roleId => onChangeValue(person.id, roleId)}
            roleId={person.roleId ?? undefined}
            roleItems={roleItems}
          />
        ))}
      </div>
    </>
  )
}
