import { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import ButtonDiv from "../../atoms/button-div"
import Link from "../../atoms/link"
import { Permission } from "../../generated/graphql"
import { UserContext } from "../../user-provider"
import { getCreateEventDashboardUrl } from "../../utils/dashboard-urls"
import Illustration from "./illustration"

export default function EmptyChannelState({
  channelId,
}: {
  channelId: string
}) {
  const intl = useIntl()
  const { organizationSlug, channelSlug } = useParams<{
    organizationSlug: string
    channelSlug: string
  }>()
  const { hasPermission } = useContext(UserContext)

  return (
    <div className="w-full rounded-4xl flex flex-col items-center bg-tv-coal px-8 py-20 my-6">
      <Illustration />
      <div className="font-semibold text-title3 text-tv-dust text-center">
        <FormattedMessage
          defaultMessage="Nothing to see (yet)"
          description="Empty channel state title"
        />
      </div>
      <p className="max-w-[260px] text-tv-dust text-center text-sm">
        {hasPermission(Permission.WriteVideos, channelId) ? (
          <FormattedMessage
            defaultMessage="Got something to share? Upload a video now to get your channel started."
            description="Empty channel state description with permission"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Nothing to see (yet)"
            description="There aren't any videos to display here, check back later."
          />
        )}
      </p>
      {hasPermission(Permission.WriteVideos, channelId) && (
        <div className="flex flex-row gap-3">
          <Link
            className="inline-block"
            to={`/${organizationSlug}/upload-video?channel=${channelSlug}`}
          >
            <ButtonDiv
              label={intl.formatMessage({
                defaultMessage: "Upload Video",
                description: "Empty channel state upload video button label",
              })}
              theme="tertiary"
            />
          </Link>
          <Link
            target="_blank"
            to={getCreateEventDashboardUrl(organizationSlug!)}
          >
            <ButtonDiv
              label={intl.formatMessage({
                description: "Create event from channel",
                defaultMessage: "Create Event",
              })}
              theme="secondary"
            />
          </Link>
        </div>
      )}
    </div>
  )
}
