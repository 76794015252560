import { useContext, useEffect } from "react"
import { SideNavContext } from "../features/side-nav/side-nav-provider"

export const useNavigationExpanded = (state: boolean) => {
  const { setInitiallyExpanded } = useContext(SideNavContext)

  useEffect(() => {
    setInitiallyExpanded(state)
  }, [setInitiallyExpanded, state])
}
