import { gql, useMutation, useQuery } from "@apollo/client"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Button from "../atoms/button"
import Label from "../atoms/label"
import TextField from "../atoms/text-field"
import ValidationError from "../atoms/validation-error"
import {
  EditChannelFolderGetQuery,
  EditChannelFolderGetQueryVariables,
  EditChannelFolderUpdateMutation,
  EditChannelFolderUpdateMutationVariables,
} from "../generated/graphql"

const QUERY = gql`
  query EditChannelFolderGet($folderId: String!) {
    node(id: $folderId) {
      id
      ... on ChannelFolder {
        name
        organization {
          id
        }
      }
    }
  }
`

const UPDATE_CHANNEL_FOLDER = gql`
  mutation EditChannelFolderUpdate(
    $folderId: String!
    $name: String
    $deleted: Boolean
  ) {
    updateChannelFolder(
      input: { folderId: $folderId, name: $name, deleted: $deleted }
    ) {
      id
      name
      deleted
    }
  }
`

export default function EditChannelFolderScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const { channelFolderId } = useParams<{
    organizationSlug: string
    channelFolderId: string
  }>()
  const { data } = useQuery<
    EditChannelFolderGetQuery,
    EditChannelFolderGetQueryVariables
  >(QUERY, {
    variables: {
      folderId: channelFolderId!,
    },
  })
  const [runUpdateFolderMutation] = useMutation<
    EditChannelFolderUpdateMutation,
    EditChannelFolderUpdateMutationVariables
  >(UPDATE_CHANNEL_FOLDER)
  const folder = data?.node.__typename === "ChannelFolder" ? data.node : null

  const handleFormValid = async (formData: { name: string }) => {
    await runUpdateFolderMutation({
      variables: {
        folderId: channelFolderId!,
        name: formData.name,
      },
      update: cache => {
        // Delete all cached channels on organization (force side nav to reload)
        cache.modify({
          id: cache.identify(folder!.organization!),
          fields: {
            navItems(_, { DELETE }) {
              return DELETE
            },
            channels(_, { DELETE }) {
              return DELETE
            },
          },
        })
      },
    })

    onDiscardClick()
  }
  const intl = useIntl()
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm({ values: { name: folder?.name ?? "" }, mode: "all" })

  const handleDeleteClick = async () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm("Are you sure?")) {
      await runUpdateFolderMutation({
        variables: {
          folderId: channelFolderId!,
          deleted: true,
        },
        update: cache => {
          // Delete all cached channels on organization (force side nav to reload)
          cache.modify({
            id: cache.identify(folder!.organization!),
            fields: {
              navItems(_, { DELETE }) {
                return DELETE
              },
              channels(_, { DELETE }) {
                return DELETE
              },
            },
          })
        },
      })

      onDiscardClick()
    }
  }

  return (
    <div className="w-screen max-w-[512px]">
      <form
        onSubmit={handleSubmit(handleFormValid)}
        className="max-h-[inherit]"
      >
        <div className="flex flex-col max-h-[inherit]">
          {/* Form */}
          <div className="flex-1 overflow-auto px-6 py-4 flex flex-col gap-4 border-b border-tv-coal">
            <h3 className="text-title3 text-center">
              <FormattedMessage
                defaultMessage="Edit Channel Folder"
                description="Edit channel folder form title"
              />
            </h3>
            {/* Name */}
            <div>
              <div>
                <Label htmlFor="name">
                  <FormattedMessage
                    defaultMessage="Name"
                    description="Edit channel folder name label"
                  />
                </Label>
              </div>
              <TextField
                id="name"
                className="w-full"
                disabled={isSubmitting}
                invalid={!!errors.name}
                maxLength={255}
                placeholder={intl.formatMessage({
                  defaultMessage: "Team Socials",
                  description: "Edit channel form name placeholder",
                })}
                {...register("name", {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      defaultMessage: "Name is required",
                      description: "Edit channel form name required message",
                    }),
                  },
                })}
              />
              {errors.name && (
                <ValidationError>{errors.name.message!}</ValidationError>
              )}
            </div>
          </div>
          {/* Buttons */}
          <div className="px-6 py-4 flex flex-row justify-end gap-3">
            <Button
              disabled={isSubmitting}
              label={intl.formatMessage({
                defaultMessage: "Cancel",
                description: "Update channel folder cancel button label",
              })}
              onClick={onDiscardClick}
              theme="tertiary"
            />
            <Button
              disabled={isSubmitting || !!errors.name}
              label={intl.formatMessage({
                defaultMessage: "Delete Folder",
                description: "Update channel folder delete button label",
              })}
              onClick={handleDeleteClick}
              theme="danger"
            />
            <Button
              disabled={isSubmitting || !!errors.name}
              label={intl.formatMessage({
                defaultMessage: "Update Folder",
                description: "Update channel folder submit button label",
              })}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  )
}
