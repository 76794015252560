import { useParams } from "react-router-dom"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Link from "../../atoms/link"

export default function ChannelLink({ channelSlug }: { channelSlug: string }) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()

  return (
    <Link
      to={`/${organizationSlug}/channels/${channelSlug}`}
      className="flex flex-row items-center font-bold"
    >
      <Icon type={IconType.Back} />
      Back to Channel
    </Link>
  )
}
