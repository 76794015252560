import classNames from "classnames"
import { DetailedHTMLProps, HTMLAttributes } from "react"

export default function ValidationError({
  children,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={classNames("text-tv-poppy text-body2", className)}
      {...props}
    >
      {children}
    </div>
  )
}
