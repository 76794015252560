import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import Link from "../../atoms/link"
import Squircle from "../../atoms/squircle"

export default function ChannelSummary({
  episodeCount,
  name,
  slug,
  logoImageUri,
}: {
  episodeCount?: number
  name: string
  slug: string
  logoImageUri?: string
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()

  return (
    <Link to={`/${organizationSlug}/channels/${slug}`}>
      <div className="inline-block">
        <div className="flex flex-row items-center gap-4">
          <Squircle
            size={64}
            className="bg-tv-beast bg-center bg-cover"
            style={{
              backgroundImage: logoImageUri
                ? `url(${logoImageUri})`
                : undefined,
            }}
          />
          <div className="flex flex-col items-start">
            <div className="text-title4 font-semibold">{name}</div>
            {episodeCount !== undefined && (
              <div className="text-tv-dust">
                <FormattedMessage
                  defaultMessage="{episodeCount, plural, one {# episode} other {# episodes}}"
                  description="Episode count in channel summary"
                  values={{
                    episodeCount,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
