import { gql, useQuery } from "@apollo/client"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams, useSearchParams } from "react-router-dom"
import Link from "../atoms/link"
import Squircle from "../atoms/squircle"
import SsoButton from "../atoms/sso-button"
import { getGoogleAuthUri } from "../features/oauth-login/get-google-auth-uri"
import { getMicrosoftAuthUri } from "../features/oauth-login/get-microsoft-auth-uri"
import { getOktaAuthUri } from "../features/oauth-login/get-okta-auth-uri"
import { usePkceChallenge } from "../features/oauth-login/hooks/use-pkce-challenge"
import {
  LoginGetOrganizationSummaryQuery,
  LoginGetOrganizationSummaryQueryVariables,
} from "../generated/graphql"

const QUERY = gql`
  query LoginGetOrganizationSummary($organizationSlug: String!) {
    organizationSummary(organizationSlug: $organizationSlug) {
      id
      name
      logoImageUri
      googleSsoEnabled
      microsoftSsoEnabled
      oktaSsoEnabled
      oktaBaseUri
      oktaClientId
    }
  }
`

export default function LoginScreen() {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    LoginGetOrganizationSummaryQuery,
    LoginGetOrganizationSummaryQueryVariables
  >(QUERY, {
    variables: { organizationSlug: organizationSlug! },
    fetchPolicy: "no-cache",
  })
  const intl = useIntl()
  const { pkceChallenge } = usePkceChallenge()

  const organization = data?.organizationSummary

  // Venue staff provides customer support to all orgs via Google SSO.
  // In the event that an org doesn't have Google (Okta-only org)
  // a member of Venue staff can pass the parameter "internalOnlyGoogleEnabled"
  // in the URL to show a Google login button.
  // for example: app.venue.video/venue?internalOnlyGoogleEnabled
  const [searchParams] = useSearchParams()
  const forceGoogleSsoEnabled =
    searchParams.get("internalOnlyGoogleEnabled") !== null

  if (pkceChallenge === null || !organization) {
    return null
  }

  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="fixed border border-tv-coal mx-auto rounded-[25%] w-[85%] h-[150%] -top-[25%]" />
      <div className="fixed border border-tv-coal mx-auto rounded-[25%] w-[67%] h-[125%] -top-[12.5%]" />
      <div className="fixed border border-tv-coal mx-auto rounded-[25%] w-[50%] h-[105%] -top-[2.5%]" />
      <div className="flex flex-col items-center bg-tv-coal w-full max-w-lg m-8 p-8 pt-16 gap-6 rounded-2xl shadow-lg relative">
        <Squircle
          size={82}
          className="absolute top-[-41px] bg-white flex justify-center items-center"
        >
          <Squircle
            size={72}
            className="bg-white bg-cover bg-center"
            style={{
              backgroundImage: organization.logoImageUri
                ? `url(${organization.logoImageUri})`
                : undefined,
            }}
          >
            &nbsp;
          </Squircle>
        </Squircle>
        <h1 className="text-title1">
          <FormattedMessage
            defaultMessage="Sign in to Venue"
            description="Login page message"
          />
        </h1>
        <div className="text-tv-dust">
          <FormattedMessage
            defaultMessage="Continue with your {organization} account"
            values={{
              organization: (
                <span className="font-bold text-tv-milk px-0.5">
                  {organization.name}
                </span>
              ),
            }}
          />
        </div>
        <div className="w-full flex flex-col items-stretch gap-2">
          {organization.googleSsoEnabled || forceGoogleSsoEnabled ? (
            <Link to={getGoogleAuthUri(pkceChallenge)}>
              <SsoButton
                label={intl.formatMessage({
                  defaultMessage: "Sign in with Google",
                  description: "Google SSO button label",
                })}
                provider="google"
              />
            </Link>
          ) : null}
          {organization.microsoftSsoEnabled ? (
            <Link to={getMicrosoftAuthUri(pkceChallenge)}>
              <SsoButton
                label={intl.formatMessage({
                  defaultMessage: "Sign in with Microsoft",
                  description: "Microsoft SSO button label",
                })}
                provider="microsoft"
              />
            </Link>
          ) : null}
          {organization.oktaSsoEnabled ? (
            <Link
              to={getOktaAuthUri(
                organization.oktaBaseUri!,
                organization.oktaClientId!,
                pkceChallenge
              )}
            >
              <SsoButton
                label={intl.formatMessage({
                  defaultMessage: "Sign in with Okta",
                  description: "Okta SSO button label",
                })}
                provider="okta"
              />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  )
}
