import { flip, offset, Placement, preventOverflow } from "@popperjs/core"
import classNames from "classnames"
import { useSelect } from "downshift"
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { ChevronSvgBg } from "../../types"
import { mergeRefs } from "../../utils/merge-refs"
import Icon from "../icon"
import { IconType } from "../icon/types"

export type SelectItem = {
  value: string
  icon: IconType
  label: string
  description: string
  selectedLabel?: string
}

const SelectWithIcon = forwardRef<
  HTMLButtonElement,
  {
    items: Array<SelectItem>
    onChangeValue: (value: string) => void
    placeholder?: string
    placement?: Placement
    value: string
  } & Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "children"
  >
>(
  (
    {
      className,
      disabled = false,
      items,
      onChangeValue,
      placeholder,
      placement = "bottom-start",
      style,
      value,
      ...props
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] =
      useState<HTMLButtonElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

    const {
      styles,
      attributes,
      update: updatePopper,
    } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [
        preventOverflow,
        flip,
        { ...offset, options: { offset: [0, 8] } },
      ],
    })

    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect<SelectItem>({
      items,
      itemToString: it => it?.value ?? "",
      onIsOpenChange: evt => {
        if (evt.isOpen) {
          updatePopper?.()
        }
      },
      selectedItem: items.find(it => it.value === value) ?? null,
      onSelectedItemChange: evt => {
        onChangeValue(evt.selectedItem?.value ?? "")
      },
    })

    return (
      <>
        <button
          className={classNames(
            "appearance-none m-0 py-1 pl-[10px] pr-[28px] rounded-4xl",
            "bg-tv-coal bg-no-repeat bg-right",
            "border border-tv-coal",
            "outline-none outline-offset-0 focus:outline focus:outline-tv-milk",
            "text-[14px] leading-[14px]",
            disabled && "border-tv-raven",
            className
          )}
          disabled={disabled}
          style={{
            backgroundImage: `url("data:image/svg+xml;utf8,${
              isOpen ? ChevronSvgBg.Up : ChevronSvgBg.Down
            }")`,
            ...style,
          }}
          type="button"
          {...getToggleButtonProps(
            { ref: mergeRefs([ref, setReferenceElement]) },
            { suppressRefError: true }
          )}
          {...props}
        >
          {selectedItem ? (
            <span
              className={classNames(
                "flex items-center",
                disabled ? "text-tv-gunmetal" : "text-tv-milk"
              )}
            >
              <Icon
                className="inline-block mr-1"
                size="small"
                type={selectedItem.icon}
              />
              {selectedItem.selectedLabel || selectedItem.label}
            </span>
          ) : null}
          {!selectedItem && !!placeholder ? (
            <span className={disabled ? "text-tv-gunmetal" : "text-tv-dust"}>
              {placeholder}
            </span>
          ) : null}
          {!selectedItem && !placeholder && <span>&nbsp;</span>}
        </button>
        <ul
          className={classNames(
            "bg-tv-coal p-2 rounded-lg cursor-pointer z-10 drop-shadow-lg min-w-[240px]",
            !isOpen && "hidden"
          )}
          style={styles.popper}
          {...getMenuProps(
            { ref: setPopperElement },
            { suppressRefError: true }
          )}
          {...attributes.popper}
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames(
                  "flex flex-row",
                  "text-[14px] leading-[14px] outline-none outline-offset-0 rounded text-tv-dust p-2",
                  highlightedIndex === index && "bg-[#393939]",
                  selectedItem?.value === item.value && "text-tv-milk"
                )}
                {...getItemProps({ item, index })}
              >
                <Icon className="mr-2" size="medium" type={item.icon} />
                <div className="flex flex-col flex-1">
                  <div className="flex flex-row justify-between">
                    <div className="text-base">{item.label}</div>
                    {selectedItem?.value === item.value && (
                      <Icon
                        className="text-tv-dandelion"
                        type={IconType.Check}
                      />
                    )}
                  </div>
                  <div className="text-tv-dust mt-2">{item.description}</div>
                </div>
              </li>
            ))}
        </ul>
      </>
    )
  }
)

SelectWithIcon.displayName = "SelectWithIcon"
export default SelectWithIcon
