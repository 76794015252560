import { gql, useQuery } from "@apollo/client"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Container from "../atoms/container"
import ChannelGrid from "../features/channel-grid"
import HeaderBar from "../features/header-bar"
import UpcomingEvents from "../features/upcoming-events/upcoming-events"
import VideoCarousel from "../features/video-carousel"
import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
} from "../generated/graphql"
import { useNavigationExpanded } from "../hooks/use-navigation-expanded"
import { isNotFoundError } from "../utils/is-not-found-error"
import ErrorScreen from "./error-screen"

const QUERY = gql`
  query GetOrganization($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      liveEvents(args: { page: 1 }) {
        items {
          id
        }
      }
      channels(args: { limit: 5 }) {
        items {
          id
          name
          slugs
          coverImageUri
          logoImageUri
          allVideos: videos(args: { limit: 1 }) {
            pageInfo {
              totalCount
            }
          }
        }
      }
      videos(args: { limit: 7 }) {
        items {
          id
          title
          status
          thumbnailUri
          durationSeconds
          eventAttendeeCount
          channel {
            id
            name
          }
        }
      }
    }
  }
`

export default function OrganizationScreen() {
  const intl = useIntl()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  useNavigationExpanded(true)

  const { data, loading, error } = useQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(QUERY, { variables: { organizationSlug: organizationSlug! } })

  if (error) {
    return isNotFoundError(error) ? (
      <ErrorScreen
        message={intl.formatMessage({
          defaultMessage: "Organization not found",
        })}
      />
    ) : (
      <ErrorScreen />
    )
  }

  return (
    <Container>
      <HeaderBar />
      <VideoCarousel
        isLoading={loading}
        videos={(data?.organization.videos.items ?? []).map(video => ({
          channelName: video.channel?.name ?? undefined,
          durationSeconds: video.durationSeconds,
          eventAttendeeCount: video.eventAttendeeCount ?? undefined,
          id: video.id,
          thumbnailUri: video.thumbnailUri ?? undefined,
          title: video.title,
        }))}
      />
      <div className="my-6">
        <UpcomingEvents />
      </div>
      <div className="my-6">
        <div className="text-title3 text-tv-beast">
          <FormattedMessage
            defaultMessage="Channels"
            description="Organization screen channels title"
          />
        </div>
        <ChannelGrid
          channels={(data?.organization.channels.items ?? []).map(channel => ({
            coverImageUri: channel.coverImageUri ?? undefined,
            episodeCount: channel.allVideos.pageInfo.totalCount,
            id: channel.id,
            logoImageUri: channel.logoImageUri ?? undefined,
            name: channel.name,
            slug: channel.slugs[0],
          }))}
          isLoading={loading}
        />
      </div>
    </Container>
  )
}
