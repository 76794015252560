import { useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import Button from "../../atoms/button"
import { CaptionType } from "./caption"
import PageOfCaptions from "./page-of-captions"

type PageOfCaptionsType = {
  captions: Array<CaptionType>
  nextPageLoaded: boolean
  previousPageLoaded: boolean
}

const AUTO_SCROLL_DISTANCE_FROM_TOP_PX = 70

const mergeState = (
  pages: Array<PageOfCaptionsType>,
  newCaptions: Array<CaptionType>,
  overlapsWithPreviousPage: boolean,
  overlapsWithNextPage: boolean,
  hasPreviousPage: boolean,
  hasNextPage: boolean
) => {
  // Get index at which to insert page.
  let insertIndex = pages.findIndex(
    page => page.captions[0].startTimeMs >= newCaptions[0].startTimeMs
  )
  if (insertIndex < 0) {
    insertIndex = pages.length
  }

  const earlierPages = pages.slice(0, Math.max(0, insertIndex - 1))
  const pageBefore = pages[insertIndex - 1]
  const pageAfter = pages[insertIndex]
  const laterPages = pages.slice(insertIndex + 1)

  const { nonOverlappingCaptions, overlapsWithPrevious, overlapsWithNext } =
    newCaptions.reduce(
      (acc, caption) => {
        const newOverlapsWithPrevious =
          !!pageBefore &&
          caption.startTimeMs <=
            pageBefore.captions[pageBefore.captions.length - 1].startTimeMs
        const newOverlapsWithNext =
          !!pageAfter &&
          caption.startTimeMs >= pageAfter.captions[0].startTimeMs

        const newNonOverlappingCaptions =
          !newOverlapsWithPrevious && !newOverlapsWithNext
            ? [...acc.nonOverlappingCaptions, caption]
            : acc.nonOverlappingCaptions

        return {
          nonOverlappingCaptions: newNonOverlappingCaptions,
          overlapsWithPrevious:
            acc.overlapsWithPrevious || newOverlapsWithPrevious,
          overlapsWithNext: acc.overlapsWithNext || newOverlapsWithNext,
        }
      },
      {
        nonOverlappingCaptions: [] as Array<CaptionType>,
        overlapsWithPrevious: overlapsWithPreviousPage,
        overlapsWithNext: overlapsWithNextPage,
      }
    )

  if (nonOverlappingCaptions.length) {
    return [
      ...earlierPages,
      ...(pageBefore
        ? [
            {
              ...pageBefore,
              nextPageLoaded: overlapsWithPrevious,
            },
          ]
        : []),
      {
        captions: nonOverlappingCaptions,
        previousPageLoaded: overlapsWithPrevious || !hasPreviousPage,
        nextPageLoaded: overlapsWithNext || !hasNextPage,
      },
      ...(pageAfter
        ? [
            {
              ...pageAfter,
              previousPageLoaded: overlapsWithNext,
            },
          ]
        : []),
      ...laterPages,
    ]
  }

  // There are no captions to insert.
  // Don't insert page, connect pages before and after.
  return [
    ...earlierPages,
    ...(pageBefore
      ? [
          {
            ...pageBefore,
            nextPageLoaded: true,
          },
        ]
      : []),
    ...(pageAfter
      ? [
          {
            ...pageAfter,
            previousPageLoaded: true,
          },
        ]
      : []),
    ...laterPages,
  ]
}

export default function TranscriptionsReadyPanel({
  loadCaptions,
  onChangeTime,
  timeMs,
}: {
  loadCaptions: (args: {
    afterTimeMs?: number
    beforeTimeMs?: number
  }) => Promise<{
    captions: Array<CaptionType>
    hasPreviousPage: boolean
    hasNextPage: boolean
  }>
  onChangeTime: (timeMs: number) => void
  timeMs: number
}) {
  const intl = useIntl()
  const containerRef = useRef<HTMLDivElement>(null)
  const isLoading = useRef(false)
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true)
  const [isScrolled, setIsScrolled] = useState(false)
  const lastArrowKeyAt = useRef(0)
  const [pagesOfCaptions, setPagesOfCaptions] = useState<
    Array<PageOfCaptionsType>
  >([])

  const handleLoadCaptionsAfter = useCallback(
    async (afterTimeMs: number) => {
      if (isLoading.current) {
        return
      }

      isLoading.current = true
      try {
        const newCaptions = await loadCaptions({ afterTimeMs })

        setPagesOfCaptions(prevState => {
          const overlapsWithPreviousPage = prevState.some(
            page =>
              page.captions[page.captions.length - 1].startTimeMs ===
              afterTimeMs
          )
          return mergeState(
            prevState,
            newCaptions.captions,
            overlapsWithPreviousPage,
            false,
            newCaptions.hasPreviousPage,
            newCaptions.hasNextPage
          )
        })
      } finally {
        isLoading.current = false
      }
    },
    [loadCaptions]
  )

  const handleLoadCaptionsBefore = useCallback(
    async (beforeTimeMs: number) => {
      if (isLoading.current) {
        return
      }

      isLoading.current = true
      try {
        const newCaptions = await loadCaptions({ beforeTimeMs })

        setPagesOfCaptions(prevState => {
          const overlapsWithNextPage = prevState.some(
            page => page.captions[0].startTimeMs === beforeTimeMs
          )

          return mergeState(
            prevState,
            newCaptions.captions,
            false,
            overlapsWithNextPage,
            newCaptions.hasPreviousPage,
            newCaptions.hasNextPage
          )
        })
      } finally {
        isLoading.current = false
      }
    },
    [loadCaptions]
  )

  useEffect(() => {
    const containerElement = containerRef.current
    if (!containerElement) {
      return () => {}
    }

    const keyDownListener = (event: KeyboardEvent) => {
      switch (event.code) {
        case "ArrowDown":
        case "ArrowUp":
          lastArrowKeyAt.current = Date.now()
          break
        default:
          break
      }
    }

    const wheelListener = () => {
      setIsAutoScrollEnabled(false)
    }

    window.addEventListener("keydown", keyDownListener)
    containerElement.addEventListener("wheel", wheelListener)

    return () => {
      window.removeEventListener("keydown", keyDownListener)
      containerElement.removeEventListener("wheel", wheelListener)
    }
  }, [])

  useEffect(() => {
    let scrolledTimeout: ReturnType<typeof setTimeout> | undefined

    const scrollListener = () => {
      if (scrolledTimeout) {
        clearTimeout(scrolledTimeout)
      }

      // If this scroll was likely the direct consequence of an arrow key press, disable auto-scroll.
      if (Date.now() - lastArrowKeyAt.current < 50) {
        setIsAutoScrollEnabled(false)
      }

      scrolledTimeout = setTimeout(() => {
        setIsScrolled(true)
        scrolledTimeout = undefined
      }, 100)
    }

    const containerElement = containerRef.current
    containerElement?.addEventListener("scroll", scrollListener)

    return () => {
      if (scrolledTimeout) {
        clearTimeout(scrolledTimeout)
      }
      containerElement?.removeEventListener("scroll", scrollListener)
    }
  }, [])

  useEffect(() => {
    if (!isAutoScrollEnabled) {
      return
    }

    // If current time is before first caption, do nothing.
    const firstPage = pagesOfCaptions[0]
    const firstCaption = firstPage?.captions[0]
    if (firstPage?.previousPageLoaded && timeMs < firstCaption!.startTimeMs) {
      return
    }

    // If current time is represented on a loaded page, do nothing.
    if (
      pagesOfCaptions.some(page => {
        const pageFirstCaption = page.captions[0]
        const pageLastCaption = page.captions[page.captions.length - 1]
        return (
          pageFirstCaption.startTimeMs <= timeMs &&
          pageLastCaption.startTimeMs + pageLastCaption.durationMs >= timeMs
        )
      })
    ) {
      return
    }

    // If current time is between two loaded pages, do nothing.
    const pageAfterIndex = pagesOfCaptions.findIndex(
      page => page.captions[0].startTimeMs > timeMs
    )
    if (
      pageAfterIndex >= 0 &&
      pagesOfCaptions[pageAfterIndex].previousPageLoaded
    ) {
      return
    }

    // If current time is after final caption, do nothing.
    const finalPage = pagesOfCaptions[pagesOfCaptions.length - 1]
    const finalPageCaptions = finalPage?.captions ?? []
    const finalCaption = finalPageCaptions[finalPageCaptions.length - 1]
    if (
      finalPage?.nextPageLoaded &&
      timeMs > finalCaption!.startTimeMs + finalCaption!.durationMs
    ) {
      return
    }

    // Otherwise, load captions around current time.
    handleLoadCaptionsAfter(Math.max(0, timeMs - 5000))
  }, [handleLoadCaptionsAfter, isAutoScrollEnabled, pagesOfCaptions, timeMs])

  const handleScrollToCaption = useCallback((element: HTMLDivElement) => {
    const containerElement = containerRef.current!
    const { top: containerTop } = containerElement.getBoundingClientRect()
    const { top: elementTop } = element.getBoundingClientRect()
    const offset = elementTop - containerTop - AUTO_SCROLL_DISTANCE_FROM_TOP_PX

    if (offset !== 0) {
      containerElement.scrollTo({
        top: containerElement.scrollTop + offset,
        behavior: "smooth",
      })
    }
  }, [])

  const handleGoBackToLiveClick = () => {
    setIsAutoScrollEnabled(true)
  }

  return (
    <div className="relative h-full">
      <div
        className="h-full overflow-y-auto flex flex-col gap-6 pr-6"
        ref={containerRef}
      >
        {pagesOfCaptions.map(pageOfCaptions => (
          <div key={pageOfCaptions.captions[0].startTimeMs}>
            <PageOfCaptions
              captions={pageOfCaptions.captions}
              hasEndSentry={isScrolled && !pageOfCaptions.nextPageLoaded}
              hasStartSentry={isScrolled && !pageOfCaptions.previousPageLoaded}
              isAutoScrollEnabled={isAutoScrollEnabled}
              onChangeTime={onChangeTime}
              onLoadCaptionsAfter={handleLoadCaptionsAfter}
              onLoadCaptionsBefore={handleLoadCaptionsBefore}
              onScrollToCaption={handleScrollToCaption}
              timeMs={timeMs}
            />
          </div>
        ))}
      </div>
      {!isAutoScrollEnabled && (
        <Button
          className="absolute bottom-0 left-1/2 -translate-x-1/2"
          label={intl.formatMessage({
            defaultMessage: "Go back to live",
            description: "Transcription panel go back to live button label",
          })}
          compact
          onClick={handleGoBackToLiveClick}
        />
      )}
    </div>
  )
}
