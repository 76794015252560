export enum IdType {
  Channel = "chn",
  ChannelFolder = "cfl",
  ChannelSubscription = "csb",
  Organization = "org",
  Person = "per",
  PersonAction = "pac",
  Playlist = "pls",
  PlaylistVideo = "plv",
  RefreshToken = "rft",
  Role = "rol",
  Video = "vid",
  VideoCaptionSet = "vcs",
  VideoTranscriptionJob = "vtj",
}

const ID_TYPE_KEYS = Object.keys(IdType) as Array<keyof typeof IdType>

export const entityId = {
  is(value: string, type: IdType) {
    return value.startsWith(`${type}-`) && value.length === type.length + 12
  },

  type(value: string) {
    const matchingKey = ID_TYPE_KEYS.find(key => this.is(value, IdType[key]))
    return matchingKey ? IdType[matchingKey as keyof typeof IdType] : null
  },
}
