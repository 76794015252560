import env from "../../env"

/**
 * Returns the URI to link to for Microsoft SSO login.
 * @param redirect - The URL to redirect to after logging in. If not provided, uses the current URL.
 * @returns The URI to link to for Microsoft SSO login.
 */
export const getMicrosoftAuthUri = (
  pkceChallenge: string,
  redirect = window.location.href
) => {
  const redirectUrl = new URL(redirect)
  const currentUrl = new URL(window.location.href)

  const authUrl = new URL(
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize"
  )
  authUrl.searchParams.append("client_id", env.microsoftClientId())
  authUrl.searchParams.append(
    "redirect_uri",
    `${currentUrl.protocol}//${currentUrl.host}/microsoft-callback`
  )
  authUrl.searchParams.append("response_type", "code")
  authUrl.searchParams.append("code_challenge", pkceChallenge)
  authUrl.searchParams.append("code_challenge_method", "S256")
  authUrl.searchParams.append(
    "scope",
    "openid email profile user.read user.readbasic.all"
  )
  authUrl.searchParams.append(
    "state",
    JSON.stringify({
      redirect: `${redirectUrl.protocol}//${redirectUrl.host}${redirectUrl.pathname}${redirectUrl.search}`,
    })
  )
  return authUrl.toString()
}
