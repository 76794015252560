import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import HelperText from "../../atoms/helper-text"
import Label from "../../atoms/label"
import TextArea from "../../atoms/text-area"
import TextField from "../../atoms/text-field"
import ValidationError from "../../atoms/validation-error"
import MultiSlugEditor from "./multi-slug-editor"

export type ValueType = {
  name: string
  slugs: Array<string>
  description: string
}

export default function EditChannelForm({
  currentLocation,
  disabled = false,
  onUpdateChannelClick,
  onDiscardClick,
  initialValue,
}: {
  currentLocation: string
  disabled?: boolean
  onUpdateChannelClick: (newValue: ValueType) => void
  onDiscardClick: () => void
  initialValue: ValueType
}) {
  const intl = useIntl()
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<ValueType>({ defaultValues: initialValue, mode: "all" })

  return (
    <form
      onSubmit={handleSubmit(onUpdateChannelClick)}
      className="max-h-[inherit]"
    >
      <div className="flex flex-col max-h-[inherit]">
        {/* Form */}
        <div className="flex-1 overflow-auto px-6 py-4 flex flex-col gap-4 border-b border-tv-coal">
          <h3 className="text-title3 text-center">
            <FormattedMessage
              defaultMessage="Edit Channel"
              description="Edit channel form title"
            />
          </h3>
          {/* Name */}
          <div>
            <div>
              <Label htmlFor="name">
                <FormattedMessage
                  defaultMessage="Name"
                  description="Edit channel form name label"
                />
              </Label>
            </div>
            <TextField
              id="name"
              className="w-full"
              disabled={disabled}
              invalid={!!errors.name}
              maxLength={255}
              placeholder={intl.formatMessage({
                defaultMessage: "Team Socials",
                description: "Edit channel form name placeholder",
              })}
              {...register("name", {
                required: {
                  value: true,
                  message: intl.formatMessage({
                    defaultMessage: "Name is required",
                    description: "Edit channel form name required message",
                  }),
                },
              })}
            />
            {errors.name && (
              <ValidationError>{errors.name.message!}</ValidationError>
            )}
          </div>
          {/* Slugs */}
          <div>
            <div>
              <Label htmlFor="slug">
                <FormattedMessage
                  defaultMessage="Channel URLs"
                  description="Edit channel form channel URLs label"
                />
              </Label>
            </div>
            <MultiSlugEditor
              currentLocation={currentLocation}
              disabled={disabled}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </div>
          {/* Description */}
          <div>
            <div>
              <Label htmlFor="slug">
                <FormattedMessage
                  defaultMessage="Description"
                  description="Edit channel form description label"
                />
              </Label>
            </div>
            <TextArea
              rows={3}
              id="description"
              disabled={disabled}
              placeholder={intl.formatMessage({
                defaultMessage:
                  "Tell everyone what to expect from your channel...",
                description: "Edit channel form description placeholder",
              })}
              autoComplete="off"
              maxLength={1000}
              className="w-full"
              {...register("description")}
            />
            <HelperText className="w-full text-right">
              {`${1000 - watch("description").length} characters remaining`}
            </HelperText>
          </div>
        </div>
        {/* Buttons */}
        <div className="px-6 py-4 flex flex-row justify-end gap-3">
          <Button
            disabled={disabled}
            label={intl.formatMessage({
              defaultMessage: "Discard",
              description: "Update channel form discard button label",
            })}
            onClick={onDiscardClick}
            theme="tertiary"
          />
          <Button
            disabled={disabled || !!errors.name || !!errors.slugs}
            label={intl.formatMessage({
              defaultMessage: "Update Channel",
              description: "Update channel form submit button label",
            })}
            type="submit"
          />
        </div>
      </div>
    </form>
  )
}
