import classNames from "classnames"
import { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import Link from "../../atoms/link"
import { Permission } from "../../generated/graphql"
import { ScreenSize, useScreenSize } from "../../hooks/use-screen-size"
import { UserContext } from "../../user-provider"
import {
  getCreateEventDashboardUrl,
  getUpcomingEventsDashboardUrl,
} from "../../utils/dashboard-urls"
import CreateEventTile from "./create-event-tile"
import EventTile from "./event-tile"

const TILES_BY_SCREEN_SIZE: Record<ScreenSize, number> = {
  sm: 3,
  md: 2,
  lg: 3,
  xl: 3,
  "2xl": 3,
}
export default function UpcomingEventsGrid({
  events,
}: {
  events: Array<{
    creatorInitials?: string
    creatorName?: string
    id: string
    imageUri?: string
    startedAt: Date
    title: string
    uri: string
  }>
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const screenSize = useScreenSize()
  const { hasPermission } = useContext(UserContext)

  // Pick as many channels as will fit in the grid
  const eventCount = TILES_BY_SCREEN_SIZE[screenSize] - 1

  return (
    <div className={classNames("bg-tv-coal rounded-4xl p-6")}>
      <div className="flex flex-row justify-between">
        <div className="text-title3">
          <FormattedMessage
            defaultMessage="Upcoming Events"
            description="Upcoming events feature title"
          />
        </div>
        <Link
          to={getUpcomingEventsDashboardUrl(organizationSlug!)}
          target="_blank"
        >
          <div className="text-subhead1 text-tv-dandelion font-semibold">
            <FormattedMessage
              defaultMessage="view all"
              description="Upcoming events view-all link"
            />
          </div>
        </Link>
      </div>
      <div
        className={classNames(
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6"
        )}
      >
        {events
          .slice(0, eventCount)
          .map(
            ({
              creatorInitials,
              creatorName,
              id,
              imageUri,
              startedAt,
              title,
              uri,
            }) => (
              <Link key={id} to={uri} target="_blank">
                <EventTile
                  creatorInitials={creatorInitials}
                  creatorName={creatorName}
                  imageUri={imageUri}
                  startedAt={startedAt}
                  title={title}
                />
              </Link>
            )
          )}
        {hasPermission(Permission.WriteLiveEvents) && (
          <Link
            to={getCreateEventDashboardUrl(organizationSlug!)}
            target="_blank"
          >
            <CreateEventTile />
          </Link>
        )}
      </div>
    </div>
  )
}
