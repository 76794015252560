import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { useDurationDescription } from "../../hooks/use-duration-description"

export type CaptionType = {
  speakerIndex: number
  text: string
  startTimeMs: number
  durationMs: number
}

export default function Caption({
  isActive = false,
  caption,
  onClickTimestamp,
}: {
  isActive?: boolean
  caption: CaptionType
  onClickTimestamp: (timeMs: number) => void
}) {
  const getDurationDescription = useDurationDescription()

  return (
    <div
      className={classNames(
        "flex flex-col gap-[0.375rem] text-subhead2",
        isActive ? "text-tv-milk" : "text-tv-dust"
      )}
    >
      <div className="flex flex-row justify-between items-start">
        <div className="font-semibold">
          <FormattedMessage
            description="Speaker label"
            defaultMessage="Speaker {index}"
            values={{
              index: caption.speakerIndex + 1,
            }}
          />
        </div>
        <div
          className={classNames(
            "cursor-pointer hover:text-tv-dandelion hover:underline",
            isActive ? "text-tv-dandelion" : "text-tv-dust"
          )}
          onClick={() => onClickTimestamp(caption.startTimeMs)}
          tabIndex={0}
          role="button"
          onKeyUp={() => {}}
        >
          {getDurationDescription(
            Math.floor(caption.startTimeMs / 1000),
            "clock"
          )}
        </div>
      </div>
      <div>{caption.text}</div>
    </div>
  )
}
