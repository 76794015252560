import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import _ from "lodash"
import { useContext, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import ButtonDiv from "../atoms/button-div"
import Link from "../atoms/link"
import SearchTextField from "../atoms/search-text-field"
import TeamRoleView from "../features/teams/team-role-view"
import TeamSearchView from "../features/teams/team-search-view"
import {
  ChannelPermissionAddRoleToPeopleMutation,
  ChannelPermissionAddRoleToPeopleMutationVariables,
  ChannelPermissionRemovePersonFromRoleMutation,
  ChannelPermissionRemovePersonFromRoleMutationVariables,
  GetChannelRoleQuery,
  GetChannelRoleQueryVariables,
  Permission,
  Person,
  TeamSearchPeopleQuery,
  TeamSearchPeopleQueryVariables,
} from "../generated/graphql"
import { UserContext } from "../user-provider"

const INTERNAL_ORGANIZATION_SLUG = "venue"
const INTERNAL_ADMIN_EMAIL = "@venue.live"

const QUERY = gql`
  query GetChannelRole($roleId: String!) {
    node(id: $roleId) {
      id
      ... on Role {
        id
        name
        description
        permissions
        people(args: { limit: 100 }) {
          items {
            id
            name
            email
            profileImageUri
            initials
          }
        }
      }
    }
  }
`

const SEARCH_PEOPLE = gql`
  query ChannelPermissionSearchPeople(
    $organizationSlug: String!
    $hideInternalPeople: Boolean!
    $query: String!
  ) {
    organization(organizationSlug: $organizationSlug) {
      id
      people(
        args: { limit: 20 }
        filter: { hideInternalPeople: $hideInternalPeople }
        query: $query
      ) {
        items {
          id
          name
          email
          profileImageUri
          initials
          roles {
            items {
              id
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          totalCount
        }
      }
    }
  }
`

const ADD_PEOPLE_TO_ROLE = gql`
  mutation ChannelPermissionAddRoleToPeople(
    $roleId: String!
    $personIds: [String!]!
  ) {
    addPeopleToRole(input: { roleId: $roleId, personIds: $personIds }) {
      id
    }
  }
`

const REMOVE_PERSON_FROM_ROLE = gql`
  mutation ChannelPermissionRemovePersonFromRole(
    $roleId: String!
    $personId: String!
  ) {
    removePersonFromRole(input: { roleId: $roleId, personId: $personId }) {
      id
    }
  }
`

const shouldHideInternalPeople = (
  userEmail: string,
  organizationSlug: string
) =>
  organizationSlug !== INTERNAL_ORGANIZATION_SLUG &&
  !userEmail.endsWith(INTERNAL_ADMIN_EMAIL)

export default function ChannelRoleAssignmentScreen() {
  const intl = useIntl()
  const { channelSlug } = useParams<{ channelSlug: string }>()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { roleId } = useParams<{ roleId: string }>()
  const [searchQuery, setSearchQuery] = useState("")
  const { email, hasPermission } = useContext(UserContext)
  const hideInternalPeople = shouldHideInternalPeople(email!, organizationSlug!)

  const { data: channelRoleData, refetch: refetchChannelRoleData } = useQuery<
    GetChannelRoleQuery,
    GetChannelRoleQueryVariables
  >(QUERY, {
    variables: {
      roleId: roleId!,
    },
    skip: !roleId,
  })

  const [runSearch, { data: searchData, refetch: reRunSearch }] = useLazyQuery<
    TeamSearchPeopleQuery,
    TeamSearchPeopleQueryVariables
  >(SEARCH_PEOPLE)

  const [addPeopleToRole] = useMutation<
    ChannelPermissionAddRoleToPeopleMutation,
    ChannelPermissionAddRoleToPeopleMutationVariables
  >(ADD_PEOPLE_TO_ROLE)

  const [removePersonFromRole] = useMutation<
    ChannelPermissionRemovePersonFromRoleMutation,
    ChannelPermissionRemovePersonFromRoleMutationVariables
  >(REMOVE_PERSON_FROM_ROLE)

  const people = searchData?.organization.people.items ?? []

  const debouncedSearch = useRef(
    _.debounce(async (query: string) => {
      if (query.length >= 3) {
        await runSearch({
          variables: {
            organizationSlug: organizationSlug!,
            hideInternalPeople,
            query,
          },
        })
      }
    }, 250)
  ).current

  const handleChangeSearchQuery = async (query: string) => {
    await debouncedSearch(query)
    setSearchQuery(query)
  }

  const role =
    channelRoleData?.node.__typename === "Role" ? channelRoleData?.node : null

  if (!role) {
    return null
  }

  const peopleInRole: Array<Partial<Person>> = role.people?.items.map(
    person => ({
      ...person,
      __typename: undefined,
    })
  )

  return (
    <div className="w-screen max-w-[512px]">
      <div className="px-6 pt-6 flex flex-col gap-4 border-b border-tv-coal">
        {hasPermission(Permission.WritePeople) ? (
          <SearchTextField
            key={`channel-role-search-${role.id}`}
            divClassName="w-full"
            placeholder={intl.formatMessage({
              description: "Team search placeholder",
              defaultMessage: "Enter email address to add role",
            })}
            onChangeText={handleChangeSearchQuery}
            value={searchQuery}
          />
        ) : null}

        {searchQuery ? (
          <div className="pt-5">
            <TeamSearchView
              onChangeValue={async (personId, _roleId) => {
                await addPeopleToRole({
                  variables: {
                    roleId: _roleId!,
                    personIds: [personId!],
                  },
                })
                await refetchChannelRoleData()
                await reRunSearch()
                setSearchQuery("")
              }}
              // Only people who don't have the current role
              people={people
                .filter(person => {
                  const personHasRole = person.roles.items.find(
                    personRole => personRole.id === role.id
                  )
                  return !personHasRole
                })
                .map(person => ({
                  email: person.email,
                  id: person.id,
                  initials: person.initials ?? null,
                  name: person.name ?? null,
                  profileImageUri: person.profileImageUri ?? null,
                  roleId: person.roles.items[0]?.id ?? null,
                }))}
              roleItems={[
                {
                  label: role.name, // only add to the role in this screen
                  value: role.id,
                },
              ]}
              title={intl.formatMessage(
                {
                  description: "Team search result count",
                  defaultMessage:
                    "{people, plural, one {# person found} other {# people found}}",
                },
                {
                  people: people.length,
                }
              )}
            />
          </div>
        ) : (
          <div className="pt-5">
            <TeamRoleView
              onChangeValue={async (_personId: string) => {
                await removePersonFromRole({
                  variables: {
                    roleId: role.id,
                    personId: _personId,
                  },
                })
                await refetchChannelRoleData()
              }}
              people={peopleInRole.map(person => {
                const roledPerson = {
                  ...person,
                  __typename: undefined,
                  roleId: role.id,
                }
                return roledPerson
              })}
              roleItems={[
                {
                  label: role.name,
                  value: role.id,
                },
              ]}
              roleDescription={role.description ?? undefined}
              roleName={role.name}
            />
          </div>
        )}

        <div className="py-4 flex flex-row justify-end">
          <Link to={`/${organizationSlug}/channels/${channelSlug}/permissions`}>
            <ButtonDiv
              theme="tertiary"
              label={intl.formatMessage({
                defaultMessage: "Back to Channel Roles",
                description: "Channel permissions form submit button label",
              })}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
