import classNames from "classnames"
import {
  ChangeEventHandler,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
} from "react"
import Icon from "../icon"
import { IconType } from "../icon/types"
import { TextFieldProps } from "../text-field/types"

/**
 * Rounded text-field with a search icon and centered placeholder.
 */
const SearchTextField = forwardRef<
  HTMLInputElement,
  TextFieldProps & { divClassName?: string } & DetailedHTMLProps<
      InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
>(
  (
    {
      className,
      divClassName,
      invalid = false,
      disabled = false,
      onChange,
      onChangeText,
      type = "text",
      ...otherProps
    },
    ref
  ) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      onChange?.(event)
      onChangeText?.(event.target.value)
    }

    return (
      <div
        className={classNames(
          "relative inline-block",
          "rounded-full bg-tv-coal",
          "border border-tv-coal",
          "outline-none outline-offset-0 focus-within:outline focus-within:outline-tv-milk",
          !disabled && "border-tv-coal text-tv-dust",
          disabled && "border-tv-raven text-tv-gunmetal",
          invalid && "border-tv-poppy",
          divClassName
        )}
      >
        <Icon
          className="absolute left-[8px] top-[50%] -mt-[12px]"
          color="#666666"
          type={IconType.Search}
        />
        <input
          type={type}
          className={classNames(
            "appearance-none w-full m-0 px-[40px] py-[9px] bg-transparent",
            "border-0 outline-none",
            "text-[14px] leading-[20px]",
            "placeholder:text-tv-dust placeholder:text-center",
            {
              "text-tv-dust": !disabled,
              "text-tv-gunmetal": disabled,
            },
            className
          )}
          onChange={handleChange}
          ref={ref}
          {...otherProps}
        />
      </div>
    )
  }
)

SearchTextField.displayName = "SearchTextField"
export default SearchTextField
