import classNames from "classnames"
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react"
import { FormattedMessage } from "react-intl"
import ButtonLabel from "../button-label"
import type { ButtonProps } from "../button/types"
import Icon from "../icon"

const ButtonDiv = forwardRef<
  HTMLDivElement,
  ButtonProps &
    Omit<
      DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      "children"
    >
>(
  (
    {
      beta,
      className,
      compact = false,
      disabled = false,
      icon,
      iconColor,
      iconSize = "small",
      label,
      tabIndex = 0,
      theme = "primary",
      ...props
    },
    ref
  ) => (
    <div
      className={classNames(
        "appearance-none inline-block rounded-lg border select-none",
        "outline-none outline-offset-0 focus:outline focus:outline-[#f6f6f6]",
        compact && "h-[32px] min-w-[32px]",
        !compact && "h-[40px] min-w-[40px]",
        !disabled && "cursor-pointer",
        disabled &&
          "bg-tv-coal border-[#313131] text-tv-gunmetal hover:bg-tv-coal hover:border-[#313131]",
        !disabled &&
          theme === "primary" &&
          "bg-tv-dandelion border-tv-dandelion text-tv-gunmetal hover:bg-[#f8b259] hover:border-[#f8b259]",
        !disabled &&
          theme === "secondary" &&
          "bg-[#f6f6f6] border-[#c8c8c8] text-tv-gunmetal hover:bg-[#f8eed1] hover:border-[#c8c8c8]",
        !disabled &&
          theme === "tertiary" &&
          "bg-[#181818] border-tv-raven text-tv-milk hover:bg-[#262626] hover:border-tv-raven",
        !disabled &&
          theme === "danger" &&
          "bg-[#181818] border-tv-raven text-tv-poppy hover:bg-[#262626] hover:border-tv-raven",
        className
      )}
      tabIndex={tabIndex}
      {...props}
      ref={ref}
    >
      <div
        className={classNames(
          "w-full h-full flex flex-row items-center justify-center gap-2",
          // Add horizontal padding if there is a label
          !!label && compact && "px-[8px]",
          !!label && !compact && "px-[12px]"
        )}
      >
        {icon ? (
          <Icon color={iconColor || "#666666"} size={iconSize} type={icon} />
        ) : null}
        {label ? (
          <ButtonLabel
            compact={compact}
            disabled={disabled}
            theme={theme}
            value={label}
          />
        ) : null}
        {beta ? (
          <div className="inline-block px-[4px] py-[2px] bg-tv-dandelion rounded-full text-[0.5625rem] text-tv-coal">
            <FormattedMessage
              defaultMessage="BETA"
              description="Button beta tag"
            />
          </div>
        ) : null}
      </div>
    </div>
  )
)

ButtonDiv.displayName = "ButtonDiv"
export default ButtonDiv
