import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import Squircle from "../../atoms/squircle"

export default function ChannelTile({
  coverImageUri,
  episodeCount,
  logoImageUri,
  name,
}: {
  coverImageUri?: string
  episodeCount: number
  logoImageUri?: string
  name: string
}) {
  return (
    <div
      className={classNames(
        "aspect-[8/7] max-w-[400px] rounded-xl overflow-hidden",
        "bg-tv-coal",
        "flex flex-col",
        "group"
      )}
    >
      <div className="flex-[4_4_0%] overflow-hidden">
        <div
          className={classNames(
            "h-full",
            "bg-gradient-to-br from-tv-dandelion to-tv-milk bg-cover bg-center",
            "transition ease-in-out duration-300",
            "group-hover:scale-125"
          )}
          style={{
            backgroundImage: coverImageUri
              ? `url(${coverImageUri})`
              : undefined,
          }}
        >
          &nbsp;
        </div>
      </div>
      <div className="flex-[6_6_0%] relative flex flex-col items-center justify-center">
        <Squircle
          size={80}
          className={classNames(
            "absolute -top-[40px] left-[50%] -mx-[40px] p-[1px]",
            "bg-tv-raven group-hover:bg-tv-dandelion",
            "transition ease-in-out duration-300"
          )}
        >
          <Squircle size={78} className="bg-tv-obsidian p-[3px]">
            <Squircle
              size={72}
              className="bg-gray-500 bg-cover bg-center flex items-center justify-center"
              style={{
                backgroundImage: logoImageUri
                  ? `url(${logoImageUri})`
                  : undefined,
              }}
            >
              {!logoImageUri && (
                <div className="text-2xl font-semibold">{name.slice(0, 1)}</div>
              )}
            </Squircle>
          </Squircle>
        </Squircle>
        <p className="text-title4 text-tv-milk font-bold group-hover:text-tv-dandelion">
          {name}
        </p>
        <p className="text-subhead4 text-tv-dust">
          <FormattedMessage
            defaultMessage="{episodeCount, plural, one {# episode} other {# episodes}}"
            description="Episode count on channel tile"
            values={{
              episodeCount,
            }}
          />
        </p>
      </div>
    </div>
  )

  // return (
  //   <div className={classNames("aspect-[8/7] bg-tv-coal rounded-xl")}>
  //     {name}
  //   </div>
  // )
}
