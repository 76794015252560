import { FormattedMessage } from "react-intl"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"

export default function VisibilityBadge({
  isChannelPublic,
  isVideoPublic,
}: {
  isChannelPublic: boolean
  isVideoPublic: boolean
}) {
  if (isChannelPublic && !isVideoPublic) {
    return (
      <div className="absolute flex flex-row items-center top-[12px] left-[12px] bg-tv-coal rounded-4xl pl-[6px] pr-[12px] py-1 text-xs">
        <Icon type={IconType.Lock} size="small" className="flex mr-1" />
        <div className="flex text-tv-dust">
          <FormattedMessage defaultMessage="Private" />
        </div>
      </div>
    )
  }

  if (!isChannelPublic && isVideoPublic) {
    return (
      <div className="absolute flex flex-row items-center top-[12px] left-[12px] bg-tv-coal rounded-4xl pl-[6px] pr-[12px] py-1 text-xs">
        <Icon type={IconType.Globe} size="small" className="flex mr-1" />
        <div className="flex text-tv-dust">
          <FormattedMessage defaultMessage="Public" />
        </div>
      </div>
    )
  }

  return null
}
