export default function PlayIcon() {
  return (
    <svg
      width="42"
      height="50"
      viewBox="0 0 42 50"
      fill="#f6f6f4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M40.1545 21.5464L5.79773 0.5744C5.22544 0.211739 4.56452 0.0132645 3.88711 0.000642022C3.2097 -0.0119804 2.54185 0.161734 1.95644 0.502824C1.36239 0.828752 0.867159 1.30878 0.522871 1.89239C0.178583 2.476 -0.00203625 3.14162 1.73176e-05 3.81921V45.8108C-0.00203625 46.4884 0.178583 47.154 0.522871 47.7377C0.867159 48.3213 1.36239 48.8013 1.95644 49.1272C2.54185 49.4683 3.2097 49.642 3.88711 49.6294C4.56452 49.6168 5.22544 49.4183 5.79773 49.0556L40.1545 28.0837C40.7176 27.744 41.1834 27.2645 41.5068 26.6919C41.8301 26.1192 42 25.4727 42 24.815C42 24.1574 41.8301 23.5109 41.5068 22.9382C41.1834 22.3655 40.7176 21.8861 40.1545 21.5464Z" />
    </svg>
  )
}
