import { UpChunk } from "@mux/upchunk"
import _ from "lodash"
import { ReactNode, createContext, useMemo, useRef, useState } from "react"
import toast from "react-hot-toast"
import UploadCompleteToast from "./upload-complete-toast"
import UploadFailedToast from "./upload-failed-toast"
import UploadingToast from "./uploading-toast"

type VideoUploadContextType = {
  uploads: Array<{
    videoId: string
    videoTitle: string
    percentComplete: number
    status: "uploading" | "complete" | "failed"
    failureCause?: string
  }>
  addUpload: (videoId: string, videoTitle: string, upload: UpChunk) => void
}

export const VideoUploadContext = createContext<VideoUploadContextType>({
  uploads: [],
  addUpload: () => {
    throw new Error(
      "VideoUploadContext was used without provider being a parent."
    )
  },
})

export default function VideoUploadProvider({
  children,
}: {
  children?: ReactNode
}) {
  const [uploads, setUploads] = useState<VideoUploadContextType["uploads"]>([])
  const uploadRefs = useRef<Array<UpChunk>>([])

  const value = useMemo<VideoUploadContextType>(
    () => ({
      uploads,
      addUpload: (videoId, videoTitle, upload) => {
        const index = uploadRefs.current.length
        uploadRefs.current.push(upload)
        setUploads(prev => {
          const result = [...prev]
          result[index] = {
            videoId,
            videoTitle,
            percentComplete: 0,
            status: "uploading",
          }
          return result
        })

        const progressHandler = (evt: CustomEvent<number>) => {
          const percentComplete = _.clamp(Math.round(evt.detail), 0, 100)
          setUploads(prev => {
            const result = [...prev]
            result[index] = {
              ...result[index],
              percentComplete,
            }
            return result
          })
          toast(
            <UploadingToast
              percentComplete={percentComplete}
              videoTitle={videoTitle}
            />,
            {
              id: `${videoId}-upload`,
            }
          )
        }

        upload.on("progress", progressHandler)
        upload.once("error", evt => {
          setUploads(prev => {
            const result = [...prev]
            result[index] = {
              ...result[index],
              failureCause: evt.detail,
              status: "failed",
            }
            return result
          })
          upload.off("progress", progressHandler)
          toast(
            t => (
              <UploadFailedToast
                onDismissClick={() => toast.dismiss(t.id)}
                videoTitle={videoTitle}
              />
            ),
            {
              id: `${videoId}-upload`,
            }
          )
        })
        upload.once("success", () => {
          setUploads(prev => {
            const result = [...prev]
            result[index] = {
              ...result[index],
              percentComplete: 100,
              status: "complete",
            }
            return result
          })
          upload.off("progress", progressHandler)
          toast(
            t => (
              <UploadCompleteToast
                onDismissClick={() => toast.dismiss(t.id)}
                videoTitle={videoTitle}
              />
            ),
            {
              id: `${videoId}-upload`,
            }
          )
        })
      },
    }),
    [uploads]
  )

  return (
    <VideoUploadContext.Provider value={value}>
      {children}
    </VideoUploadContext.Provider>
  )
}
