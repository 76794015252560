import { gql, useMutation, useQuery } from "@apollo/client"
import { useNavigate, useParams } from "react-router-dom"
import CreateChannelForm, {
  ValueType,
} from "../features/create-channel/create-channel-form"
import {
  CreateChannelGetOrganizationQuery,
  CreateChannelGetOrganizationQueryVariables,
  CreateChannelMutation,
  CreateChannelMutationVariables,
} from "../generated/graphql"
import { TrackEventName, trackEvent } from "../track-event"

const QUERY = gql`
  query CreateChannelGetOrganization($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
    }
  }
`

const CREATE_CHANNEL = gql`
  mutation CreateChannel(
    $organizationId: String!
    $name: String!
    $slug: String!
    $description: String
  ) {
    createChannel(
      input: {
        organizationId: $organizationId
        name: $name
        slug: $slug
        description: $description
      }
    ) {
      id
    }
  }
`

export default function CreateChannelScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    CreateChannelGetOrganizationQuery,
    CreateChannelGetOrganizationQueryVariables
  >(QUERY, {
    variables: {
      organizationSlug: organizationSlug!,
    },
  })
  const [runCreateChannelMutation, { loading }] = useMutation<
    CreateChannelMutation,
    CreateChannelMutationVariables
  >(CREATE_CHANNEL)
  const navigate = useNavigate()

  const handleCreateChannelClick = async (value: ValueType) => {
    await runCreateChannelMutation({
      variables: {
        organizationId: data!.organization.id,
        name: value.name,
        slug: value.slug,
        description: value.description,
      },
      update: cache => {
        // Delete all cached channels on organization (force side nav to reload)
        cache.modify({
          id: cache.identify(data!.organization),
          fields: {
            channels(_, { DELETE }) {
              return DELETE
            },
          },
        })
      },
    })

    trackEvent({
      name: TrackEventName.ChannelCreated,
      attributes: { status: "complete" },
    })

    navigate(`/${organizationSlug}/channels/${value.slug.toLowerCase()}`, {
      replace: true,
    })
  }

  if (!data?.organization) {
    return null
  }

  return (
    <div className="w-screen max-w-[512px]">
      <CreateChannelForm
        currentLocation={window.location.href}
        disabled={loading}
        onCreateChannelClick={handleCreateChannelClick}
        onDiscardClick={onDiscardClick}
      />
    </div>
  )
}
