import { flip, offset, Placement, preventOverflow } from "@popperjs/core"
import classNames from "classnames"
import { useSelect } from "downshift"
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { ChevronSvgBg } from "../../types"
import { mergeRefs } from "../../utils/merge-refs"
import Icon from "../icon"
import { IconType } from "../icon/types"

export type SelectItem = {
  // The optional icon to display in items and toggle.
  icon?: IconType
  // The main-line text to display in items. Used as selected label if no selected label is provided.
  label: string
  // The optional label to use for the toggle when the item is selected.
  selectedLabel?: string
  // The optional sub-line text to display in items.
  subLabel?: string
  // The value of the item.
  value: string
}

const Select = forwardRef<
  HTMLButtonElement,
  {
    compact?: boolean
    invalid?: boolean
    items: Array<SelectItem>
    onChangeValue: (value: string) => void
    placeholder?: string
    placement?: Placement
    rounded?: boolean
    value: string
  } & Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "children"
  >
>(
  (
    {
      className,
      compact = false,
      disabled = false,
      invalid = false,
      items,
      onChangeValue,
      placeholder,
      placement = "bottom-start",
      rounded = false,
      style,
      value,
      ...props
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] =
      useState<HTMLButtonElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

    const {
      styles,
      attributes,
      update: updatePopper,
    } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [
        preventOverflow,
        flip,
        { ...offset, options: { offset: [0, 8] } },
      ],
    })

    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
    } = useSelect<SelectItem>({
      items,
      itemToString: it => it?.value ?? "",
      onIsOpenChange: evt => {
        if (evt.isOpen) {
          updatePopper?.()
        }
      },
      selectedItem: items.find(it => it.value === value) ?? null,
      onSelectedItemChange: evt => {
        onChangeValue(evt.selectedItem?.value ?? "")
      },
    })

    return (
      <>
        <button
          className={classNames(
            "appearance-none m-0 pl-[10px] pr-[28px]",
            "bg-tv-coal bg-no-repeat bg-right",
            "flex flex-row items-center justify-center gap-2",
            "border border-tv-coal",
            "outline-none outline-offset-0 focus:outline focus:outline-tv-milk",
            "text-[14px] leading-[14px]",
            compact ? "h-[32px]" : "h-[40px]",
            rounded ? "rounded-full" : "rounded-lg",
            invalid && "border-tv-poppy",
            disabled && "border-tv-raven",
            className
          )}
          disabled={disabled}
          style={{
            backgroundImage: `url("data:image/svg+xml;utf8,${
              isOpen ? ChevronSvgBg.Up : ChevronSvgBg.Down
            }")`,
            ...style,
          }}
          type="button"
          {...getToggleButtonProps(
            { ref: mergeRefs([ref, setReferenceElement]) },
            { suppressRefError: true }
          )}
          {...props}
        >
          {selectedItem?.icon ? (
            <Icon color="#666666" size="small" type={selectedItem.icon} />
          ) : null}
          {selectedItem ? (
            <span className={disabled ? "text-tv-gunmetal" : "text-tv-milk"}>
              {selectedItem.selectedLabel ?? selectedItem.label}
            </span>
          ) : null}
          {!selectedItem && !!placeholder ? (
            <span className={disabled ? "text-tv-gunmetal" : "text-tv-dust"}>
              {placeholder}
            </span>
          ) : null}
          {!selectedItem && !placeholder && <span>&nbsp;</span>}
        </button>
        <ul
          className={classNames(
            "bg-tv-coal p-2 rounded-lg cursor-pointer z-10 drop-shadow-lg",
            !isOpen && "hidden"
          )}
          style={styles.popper}
          {...getMenuProps(
            { ref: setPopperElement },
            { suppressRefError: true }
          )}
          {...attributes.popper}
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames(
                  "text-[14px] leading-[14px] outline-none outline-offset-0 rounded text-tv-dust p-2",
                  "flex flex-row items-center justify-start gap-2",
                  highlightedIndex === index && "bg-[#393939]",
                  selectedItem?.value === item.value && "text-tv-milk"
                )}
                {...getItemProps({ item, index })}
              >
                {item.icon ? (
                  <Icon color="#666666" size="small" type={item.icon} />
                ) : null}
                <div className="flex flex-col gap-1">
                  <span className="text-subhead2">{item.label}</span>
                  {!!item.subLabel && (
                    <span className="text-subhead3">{item.subLabel}</span>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </>
    )
  }
)

Select.displayName = "Select"
export default Select
