/**
 * Returns a polygon describing the squircle of the provided size.
 *
 * @param size - The size (width and height) of the squircle.
 * @returns A polygon describing the squircle.
 */
export const getSquirclePolygon = (size: number) => {
  const pct100 = Math.round(100 * size) / 100
  const pct90 = Math.round(90 * size) / 100
  const pct50 = Math.round(50 * size) / 100
  const pct10 = Math.round(10 * size) / 100
  return `M ${pct50} 0 C ${pct10} 0 0 ${pct10} 0 ${pct50} C 0 ${pct90} ${pct10} ${pct100} ${pct50} ${pct100} C ${pct90} ${pct100} ${pct100} ${pct90} ${pct100} ${pct50} C ${pct100} ${pct10} ${pct90} 0 ${pct50} 0 Z`
}
