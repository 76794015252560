import { useIntl } from "react-intl"
import { Route, Routes } from "react-router-dom"
import ChannelScreen from "./screens/channel-screen"
import ErrorScreen from "./screens/error-screen"
import HomeScreen from "./screens/home-screen"
import LoginScreen from "./screens/login-screen"
import MicrosoftCallbackScreen from "./screens/microsoft-callback-screen"
import OAuthCallbackScreen from "./screens/oauth-callback-screen"
import OktaCallbackScreen from "./screens/okta-callback-screen"
import VideoScreen from "./screens/video-screen"

export default function LoggedOutRouter() {
  const intl = useIntl()
  return (
    <Routes>
      {/* Create explicit routes for any screens that are accessible to a logged out user. */}
      <Route path="oauth-callback" element={<OAuthCallbackScreen />} />
      <Route path="microsoft-callback" element={<MicrosoftCallbackScreen />} />
      <Route path="okta-callback" element={<OktaCallbackScreen />} />
      <Route
        path=":organizationSlug/channels/:channelSlug"
        element={<ChannelScreen />}
      />
      <Route
        path=":organizationSlug/videos/:videoId"
        element={<VideoScreen />}
      />

      <Route path=":organizationSlug/*" element={<LoginScreen />} />
      <Route path="/" element={<HomeScreen />} />
      <Route
        path="*"
        element={
          <ErrorScreen
            message={intl.formatMessage({ defaultMessage: "Not found" })}
          />
        }
      />
    </Routes>
  )
}
