import { assertNever } from "../../utils/assert-never"
import { IconType } from "./types"

export default function SvgFragment({ type }: { type: IconType }) {
  switch (type) {
    case IconType.AddEmoji:
      return (
        <>
          <path
            d="M21.1973 18.9995C20.6701 19.9113 19.9124 20.6683 19.0001 21.1947C18.0879 21.721 17.0532 21.9981 16 21.9981C14.9468 21.9981 13.9122 21.721 12.9999 21.1947C12.0876 20.6684 11.3299 19.9114 10.8027 18.9996"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.4999 15.6666C13.1443 15.6666 13.6666 15.1443 13.6666 14.5C13.6666 13.8556 13.1443 13.3333 12.4999 13.3333C11.8556 13.3333 11.3333 13.8556 11.3333 14.5C11.3333 15.1443 11.8556 15.6666 12.4999 15.6666Z"
            fill="currentcolor"
          />
          <path
            d="M19.4999 15.6666C20.1443 15.6666 20.6666 15.1443 20.6666 14.5C20.6666 13.8556 20.1443 13.3333 19.4999 13.3333C18.8556 13.3333 18.3333 13.8556 18.3333 14.5C18.3333 15.1443 18.8556 15.6666 19.4999 15.6666Z"
            fill="currentcolor"
          />
          <path
            d="M27.85 14.1125C27.9511 14.7366 28.0012 15.3678 28 16C28 18.3734 27.2962 20.6935 25.9776 22.6669C24.6591 24.6403 22.7849 26.1783 20.5922 27.0866C18.3995 27.9948 15.9867 28.2325 13.6589 27.7694C11.3312 27.3064 9.19295 26.1635 7.51472 24.4853C5.83649 22.8071 4.6936 20.6689 4.23058 18.3411C3.76756 16.0133 4.0052 13.6005 4.91345 11.4078C5.8217 9.21511 7.35977 7.34096 9.33316 6.02239C11.3066 4.70381 13.6266 4.00002 16 4.00002C16.6322 3.99878 17.2634 4.04895 17.8875 4.15002"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.5 8.0625H21.5"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25 4.5625V11.5625"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.AddEvent:
      return (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 4 6 C 4 4.895 4.895 4 6 4 L 26 4 C 27.105 4 28 4.895 28 6 L 28 26 C 28 27.105 27.105 28 26 28 L 6 28 C 4.895 28 4 27.105 4 26 L 4 6 Z M 26 6 L 6 6 L 6 26 L 26 26 L 26 6 Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 22 2.333 C 22.368 2.333 22.667 2.632 22.667 3 L 22.667 7 C 22.667 7.368 22.368 7.667 22 7.667 C 21.632 7.667 21.333 7.368 21.333 7 L 21.333 3 C 21.333 2.632 21.632 2.333 22 2.333 Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 10 2.333 C 10.368 2.333 10.667 2.632 10.667 3 L 10.667 7 C 10.667 7.368 10.368 7.667 10 7.667 C 9.632 7.667 9.333 7.368 9.333 7 L 9.333 3 C 9.333 2.632 9.632 2.333 10 2.333 Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 4.333 11 C 4.333 10.632 4.632 10.333 5 10.333 L 27 10.333 C 27.368 10.333 27.667 10.632 27.667 11 C 27.667 11.368 27.368 11.667 27 11.667 L 5 11.667 C 4.632 11.667 4.333 11.368 4.333 11 Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 11.5 19 C 11.5 18.448 11.948 18 12.5 18 L 19.5 18 C 20.052 18 20.5 18.448 20.5 19 C 20.5 19.552 20.052 20 19.5 20 L 12.5 20 C 11.948 20 11.5 19.552 11.5 19 Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 16 14.5 C 16.552 14.5 17 14.948 17 15.5 L 17 22.5 C 17 23.052 16.552 23.5 16 23.5 C 15.448 23.5 15 23.052 15 22.5 L 15 15.5 C 15 14.948 15.448 14.5 16 14.5 Z"
            fill="currentcolor"
          />
        </>
      )
    case IconType.AddUser:
      return (
        <>
          <path
            d="M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20Z"
            stroke="currentcolor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M7.9751 24.925C8.72749 23.443 9.87555 22.1983 11.292 21.3289C12.7085 20.4594 14.3381 19.9992 16.0001 19.9992C17.6621 19.9992 19.2917 20.4594 20.7082 21.3289C22.1246 22.1983 23.2727 23.443 24.0251 24.925"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 7H28"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25 4V10"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.85 14.1125C27.9511 14.7366 28.0012 15.3678 28 16C28 18.3734 27.2962 20.6935 25.9776 22.6669C24.6591 24.6403 22.7849 26.1783 20.5922 27.0866C18.3995 27.9948 15.9867 28.2325 13.6589 27.7694C11.3312 27.3064 9.19295 26.1635 7.51472 24.4853C5.83649 22.8071 4.6936 20.6689 4.23058 18.3411C3.76756 16.0133 4.0052 13.6005 4.91345 11.4078C5.8217 9.21511 7.35977 7.34096 9.33316 6.02239C11.3066 4.70381 13.6266 4.00002 16 4.00002C16.6322 3.99878 17.2634 4.04895 17.8875 4.15002"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.AddVideo:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 3C28 2.44772 27.5523 2 27 2C26.4477 2 26 2.44772 26 3V5H24C23.4477 5 23 5.44772 23 6C23 6.55228 23.4477 7 24 7H26V9C26 9.55228 26.4477 10 27 10C27.5523 10 28 9.55228 28 9V7H30C30.5523 7 31 6.55228 31 6C31 5.44772 30.5523 5 30 5H28V3ZM25.5 22C25.5 22.2761 25.2761 22.5 25 22.5H5C4.72386 22.5 4.5 22.2761 4.5 22V8C4.5 7.72386 4.72386 7.5 5 7.5H20C20.8284 7.5 21.5 6.82843 21.5 6C21.5 5.17157 20.8284 4.5 20 4.5H5C3.067 4.5 1.5 6.067 1.5 8V22C1.5 23.933 3.067 25.5 5 25.5H25C26.933 25.5 28.5 23.933 28.5 22V13C28.5 12.1716 27.8284 11.5 27 11.5C26.1716 11.5 25.5 12.1716 25.5 13V22ZM13.5547 10.168C13.2478 9.96338 12.8533 9.94431 12.5281 10.1183C12.203 10.2923 12 10.6312 12 11V19C12 19.3688 12.203 19.7077 12.5281 19.8817C12.8533 20.0557 13.2478 20.0366 13.5547 19.8321L19.5547 15.8321C19.8329 15.6466 20 15.3344 20 15C20 14.6656 19.8329 14.3534 19.5547 14.168L13.5547 10.168ZM17.1972 15L14 17.1315V12.8685L17.1972 15Z"
          fill="currentcolor"
        />
      )
    case IconType.Back:
      return (
        <>
          <line
            stroke="currentcolor"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="11"
            y1="16"
            x2="21"
            y2="16"
          />
          <polyline
            stroke="currentcolor"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="15 12 11 16 15 20"
          />
        </>
      )
    case IconType.Billing:
      return (
        <>
          <path
            d="M28 7H4C3.44772 7 3 7.44772 3 8V24C3 24.5523 3.44772 25 4 25H28C28.5523 25 29 24.5523 29 24V8C29 7.44772 28.5523 7 28 7Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 21H25"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 21H17"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.33325 12.1125H27.6666"
            stroke="currentcolor"
            strokeWidth="3.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Calendar:
      return (
        <>
          <path
            d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 3V7"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3V7"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 11H27"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Channel:
      return (
        <>
          <path
            d="M27 6H5C4.44772 6 4 6.44772 4 7V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V7C28 6.44772 27.5523 6 27 6Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 6V26"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 10H28"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 22H28"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6V10"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 6V10"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 22V26"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 22V26"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Check:
      return (
        <path
          d="M23 12L14.3284 20.2727L10 16.1364"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )
    case IconType.CheckCircle:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 16C5.5 10.201 10.201 5.5 16 5.5C21.799 5.5 26.5 10.201 26.5 16C26.5 21.799 21.799 26.5 16 26.5C10.201 26.5 5.5 21.799 5.5 16ZM16 2.5C8.54416 2.5 2.5 8.54416 2.5 16C2.5 23.4558 8.54416 29.5 16 29.5C23.4558 29.5 29.5 23.4558 29.5 16C29.5 8.54416 23.4558 2.5 16 2.5ZM22.1903 13.7236C22.5899 13.3423 22.6048 12.7093 22.2236 12.3097C21.8423 11.9101 21.2093 11.8952 20.8097 12.2765L14.1631 18.6174L11.1909 15.777C10.7916 15.3955 10.1586 15.4098 9.77704 15.8091C9.39547 16.2084 9.40983 16.8414 9.80912 17.223L13.4716 20.723C13.8579 21.0921 14.4662 21.0924 14.8528 20.7236L22.1903 13.7236Z"
          fill="currentcolor"
        />
      )
    case IconType.Close:
      return (
        <>
          <path
            d="M22.6666 9.33334L9.33325 22.6667"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6666 22.6667L9.33325 9.33334"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Collapse:
      return (
        <>
          <path
            d="M 21 16 L 2 16"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 11 25 L 2 16 L 11 7"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 30 27 L 30 5"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Delete:
      return (
        <>
          <path
            d="M27 7H5"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 13V21"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 13V21"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25 7L23.6667 26C23.6667 26.2652 23.5613 26.5196 23.3738 26.7071C23.1862 26.8946 22.9319 27 22.6667 27H9.33333C9.06812 27 8.81376 26.8946 8.62623 26.7071C8.43869 26.5196 8.33333 26.2652 8.33333 26L7 7"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 7V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H13C12.4696 3 11.9609 3.21071 11.5858 3.58579C11.2107 3.96086 11 4.46957 11 5V7"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Discover:
      return (
        <>
          <path
            d="M27 9H5C4.44772 9 4 9.44772 4 10V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V10C28 9.44772 27.5523 9 27 9Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3L16 9L22 3"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 26V9"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.5 16C24.3284 16 25 15.3284 25 14.5C25 13.6716 24.3284 13 23.5 13C22.6716 13 22 13.6716 22 14.5C22 15.3284 22.6716 16 23.5 16Z"
            fill="currentcolor"
          />
          <path
            d="M23.5 22C24.3284 22 25 21.3284 25 20.5C25 19.6716 24.3284 19 23.5 19C22.6716 19 22 19.6716 22 20.5C22 21.3284 22.6716 22 23.5 22Z"
            fill="currentcolor"
          />
        </>
      )
    case IconType.Download:
      return (
        <>
          <path
            d="M10.75 13.75L16 19L21.25 13.75"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 5V19"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Edit:
      return (
        <>
          <path
            d="M11.5875 27H6.00001C5.73479 27 5.48044 26.8947 5.2929 26.7071C5.10536 26.5196 5.00001 26.2652 5.00001 26V20.4125C4.99955 20.2827 5.02471 20.154 5.07404 20.0339C5.12338 19.9138 5.19591 19.8046 5.28751 19.7125L20.2875 4.71251C20.3806 4.61803 20.4915 4.54299 20.6138 4.49178C20.7361 4.44056 20.8674 4.41418 21 4.41418C21.1326 4.41418 21.2639 4.44056 21.3862 4.49178C21.5085 4.54299 21.6195 4.61803 21.7125 4.71251L27.2875 10.2875C27.382 10.3806 27.457 10.4915 27.5082 10.6138C27.5595 10.7361 27.5858 10.8674 27.5858 11C27.5858 11.1326 27.5595 11.2639 27.5082 11.3862C27.457 11.5086 27.382 11.6195 27.2875 11.7125L12.2875 26.7125C12.1955 26.8041 12.0863 26.8766 11.9661 26.926C11.846 26.9753 11.7174 27.0005 11.5875 27V27Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 8L24 15"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Email:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5.5C3.17157 5.5 2.5 6.17157 2.5 7V24C2.5 24.663 2.76339 25.2989 3.23223 25.7678C3.70108 26.2366 4.33696 26.5 5 26.5H27C27.663 26.5 28.2989 26.2366 28.7678 25.7678C29.2366 25.2989 29.5 24.663 29.5 24V7C29.5 6.17157 28.8284 5.5 28 5.5H4ZM5.5 9.73157V22.2666L12.3356 15.9976L5.5 9.73157ZM24.8859 23.5L18.1845 17.3541L16.6757 18.7372C16.2934 19.0876 15.7066 19.0876 15.3243 18.7372L13.8155 17.3541L7.11412 23.5H24.8859ZM19.6644 15.9976L26.5 9.73158V22.2666L19.6644 15.9976ZM7.11626 8.5L16 16.6434L24.8838 8.5H7.11626Z"
          fill="currentcolor"
        />
      )
    case IconType.Emoji:
      return (
        <>
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.1973 18.9995C20.6701 19.9113 19.9124 20.6683 19.0001 21.1947C18.0879 21.721 17.0532 21.9981 16 21.9981C14.9468 21.9981 13.9122 21.721 12.9999 21.1947C12.0876 20.6684 11.3299 19.9114 10.8027 18.9996"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.4999 15.6666C13.1443 15.6666 13.6666 15.1443 13.6666 14.5C13.6666 13.8556 13.1443 13.3333 12.4999 13.3333C11.8556 13.3333 11.3333 13.8556 11.3333 14.5C11.3333 15.1443 11.8556 15.6666 12.4999 15.6666Z"
            fill="currentcolor"
          />
          <path
            d="M19.4999 15.6666C20.1443 15.6666 20.6666 15.1443 20.6666 14.5C20.6666 13.8556 20.1443 13.3333 19.4999 13.3333C18.8556 13.3333 18.3333 13.8556 18.3333 14.5C18.3333 15.1443 18.8556 15.6666 19.4999 15.6666Z"
            fill="currentcolor"
          />
        </>
      )
    case IconType.Expand:
      return (
        <>
          <path
            d="M 11 16 L 30 16"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 21 25 L 30 16 L 21 7"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 2 27 L 2 5"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Feature:
      return (
        <path
          d="M19.8376 20.4375L16.9376 28.4125C16.8665 28.6039 16.7386 28.7689 16.571 28.8855C16.4034 29.002 16.2042 29.0645 16.0001 29.0645C15.7959 29.0645 15.5967 29.002 15.4291 28.8855C15.2615 28.7689 15.1336 28.6039 15.0626 28.4125L12.1626 20.4375C12.1119 20.3 12.032 20.1752 11.9284 20.0716C11.8249 19.968 11.7 19.8881 11.5626 19.8375L3.58756 16.9375C3.39618 16.8665 3.23113 16.7386 3.11458 16.571C2.99802 16.4034 2.93555 16.2041 2.93555 16C2.93555 15.7959 2.99802 15.5966 3.11458 15.429C3.23113 15.2614 3.39618 15.1335 3.58756 15.0625L11.5626 12.1625C11.7 12.1119 11.8249 12.032 11.9284 11.9284C12.032 11.8248 12.1119 11.7 12.1626 11.5625L15.0626 3.5875C15.1336 3.39612 15.2615 3.23107 15.4291 3.11452C15.5967 2.99796 15.7959 2.93549 16.0001 2.93549C16.2042 2.93549 16.4034 2.99796 16.571 3.11452C16.7386 3.23107 16.8665 3.39612 16.9376 3.5875L19.8376 11.5625C19.8882 11.7 19.9681 11.8248 20.0717 11.9284C20.1753 12.032 20.3001 12.1119 20.4376 12.1625L28.4126 15.0625C28.6039 15.1335 28.769 15.2614 28.8855 15.429C29.0021 15.5966 29.0646 15.7959 29.0646 16C29.0646 16.2041 29.0021 16.4034 28.8855 16.571C28.769 16.7386 28.6039 16.8665 28.4126 16.9375L20.4376 19.8375C20.3001 19.8881 20.1753 19.968 20.0717 20.0716C19.9681 20.1752 19.8882 20.3 19.8376 20.4375V20.4375Z"
          stroke="currentcolor"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )
    case IconType.Filter:
      return (
        <>
          <path
            d="M18.5 21.5H5"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 21.5H23.5"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 24C22.3807 24 23.5 22.8807 23.5 21.5C23.5 20.1193 22.3807 19 21 19C19.6193 19 18.5 20.1193 18.5 21.5C18.5 22.8807 19.6193 24 21 24Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5 10.5H5"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 10.5H15.5"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 13C14.3807 13 15.5 11.8807 15.5 10.5C15.5 9.11929 14.3807 8 13 8C11.6193 8 10.5 9.11929 10.5 10.5C10.5 11.8807 11.6193 13 13 13Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Folder:
      return (
        <path
          d="M 0 7.697 C 0 6.947 0 6.3 0.044 5.768 C 0.091 5.205 0.195 4.646 0.476 4.108 C 0.894 3.305 1.562 2.652 2.383 2.243 C 2.933 1.969 3.505 1.868 4.08 1.822 C 4.625 1.778 5.286 1.778 6.053 1.778 L 11.85 1.778 C 12.713 1.777 13.472 1.776 14.17 2.011 C 14.782 2.218 15.339 2.554 15.801 2.997 C 16.328 3.502 16.667 4.166 17.052 4.922 L 18.353 7.467 L 23.624 7.467 C 24.795 7.467 25.761 7.467 26.548 7.53 C 27.366 7.595 28.117 7.735 28.823 8.087 C 29.918 8.632 30.808 9.503 31.366 10.573 C 31.726 11.263 31.869 11.998 31.936 12.798 C 32 13.567 32 14.512 32 15.657 L 32 22.032 C 32 23.177 32 24.122 31.936 24.892 C 31.869 25.691 31.726 26.426 31.366 27.116 C 30.808 28.187 29.918 29.057 28.823 29.602 C 28.117 29.954 27.366 30.094 26.548 30.16 C 25.761 30.222 24.795 30.222 23.624 30.222 L 8.376 30.222 C 7.205 30.222 6.239 30.222 5.452 30.16 C 4.634 30.094 3.883 29.954 3.177 29.602 C 2.082 29.057 1.192 28.187 0.634 27.116 C 0.274 26.426 0.131 25.691 0.064 24.892 C 0 24.122 0 23.177 0 22.032 L 0 7.697 Z M 11.669 4.623 C 12.818 4.623 13.046 4.641 13.222 4.7 C 13.426 4.769 13.611 4.881 13.765 5.029 C 13.898 5.156 14.017 5.347 14.531 6.352 L 15.101 7.467 L 2.909 7.467 C 2.91 6.806 2.914 6.354 2.944 5.999 C 2.976 5.613 3.031 5.47 3.068 5.399 C 3.207 5.132 3.43 4.914 3.703 4.778 C 3.775 4.742 3.922 4.688 4.317 4.657 C 4.729 4.624 5.27 4.623 6.109 4.623 L 11.669 4.623 Z"
          fill="currentcolor"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      )
    case IconType.Forward:
      return (
        <>
          <line
            stroke="currentcolor"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            x1="11"
            y1="16"
            x2="21"
            y2="16"
          />
          <polyline
            stroke="currentcolor"
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            points="17 12 21 16 17 20"
          />
        </>
      )
    case IconType.Gift:
      return (
        <>
          <path
            d="M27 10H5C4.44772 10 4 10.4477 4 11V15C4 15.5523 4.44772 16 5 16H27C27.5523 16 28 15.5523 28 15V11C28 10.4477 27.5523 10 27 10Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26 16V25C26 25.2652 25.8946 25.5196 25.7071 25.7071C25.5196 25.8946 25.2652 26 25 26H7C6.73478 26 6.48043 25.8946 6.29289 25.7071C6.10536 25.5196 6 25.2652 6 25V16"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 10V26"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.6625 8.58751C20.2375 10 16 10 16 10C16 10 16 5.76251 17.4125 4.33751C17.9761 3.77392 18.7405 3.45731 19.5375 3.45731C20.3345 3.45731 21.0989 3.77392 21.6625 4.33751C22.2261 4.90109 22.5427 5.66548 22.5427 6.46251C22.5427 7.25954 22.2261 8.02392 21.6625 8.58751Z"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.3377 8.58751C11.7627 10 16.0002 10 16.0002 10C16.0002 10 16.0002 5.76251 14.5877 4.33751C14.0241 3.77392 13.2598 3.45731 12.4627 3.45731C11.6657 3.45731 10.9013 3.77392 10.3377 4.33751C9.77414 4.90109 9.45752 5.66548 9.45752 6.46251C9.45752 7.25954 9.77414 8.02392 10.3377 8.58751Z"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Globe:
      return (
        <>
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            d="M4.6875 12H27.3125"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.6875 20H27.3125"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 27.675C18.7614 27.675 21 22.4479 21 16C21 9.55209 18.7614 4.32501 16 4.32501C13.2386 4.32501 11 9.55209 11 16C11 22.4479 13.2386 27.675 16 27.675Z"
            stroke="currentcolor"
            strokeMiterlimit="10"
          />
        </>
      )
    case IconType.Hidden:
      return (
        <>
          <path
            d="M 4.991 3.891 C 5.604 3.333 6.553 3.379 7.109 3.991 L 27.109 25.991 C 27.667 26.604 27.623 27.553 27.009 28.109 C 26.396 28.667 25.448 28.623 24.891 28.009 L 4.891 6.009 C 4.333 5.396 4.379 4.448 4.991 3.891 Z"
            fill="currentcolor"
          />
          <path
            d="M 13.377 11.628 C 13.749 12.036 13.719 12.668 13.311 13.04 C 12.713 13.583 12.293 14.295 12.108 15.08 C 11.923 15.865 11.979 16.689 12.269 17.443 C 12.561 18.195 13.073 18.843 13.739 19.299 C 14.404 19.756 15.193 20 16 20 L 16.004 20 C 16.997 20.004 17.955 19.633 18.687 18.963 C 19.095 18.589 19.727 18.617 20.1 19.024 C 20.473 19.432 20.445 20.064 20.039 20.437 C 18.936 21.448 17.493 22.005 15.999 22 C 14.788 22 13.605 21.633 12.608 20.948 C 11.609 20.264 10.841 19.292 10.404 18.163 C 9.968 17.033 9.883 15.799 10.161 14.62 C 10.44 13.441 11.069 12.375 11.965 11.56 C 12.373 11.188 13.007 11.219 13.377 11.628 Z M 15.999 22 C 15.997 22 15.997 22 15.996 22 L 16 21 L 16 22 C 16 22 15.999 22 15.999 22 Z"
            fill="currentcolor"
          />
          <path
            d="M 10.589 7.899 C 10.963 8.639 10.665 9.54 9.925 9.915 C 7.636 11.071 5.987 12.749 4.903 14.165 C 4.364 14.869 3.973 15.497 3.72 15.943 C 3.709 15.963 3.699 15.981 3.688 16 C 3.785 16.173 3.907 16.379 4.051 16.607 C 4.536 17.379 5.281 18.411 6.311 19.44 C 8.36 21.488 11.499 23.5 16 23.5 L 16.012 23.5 C 18.117 23.517 20.195 23.032 22.075 22.085 C 22.815 21.713 23.717 22.011 24.089 22.751 C 24.463 23.491 24.164 24.392 23.424 24.765 C 21.121 25.925 18.573 26.52 15.993 26.5 C 10.499 26.499 6.639 24.011 4.189 21.561 C 2.968 20.34 2.088 19.121 1.512 18.205 C 1.223 17.747 1.008 17.36 0.863 17.083 C 0.789 16.944 0.735 16.832 0.696 16.752 C 0.676 16.712 0.661 16.68 0.651 16.656 L 0.636 16.625 L 0.632 16.616 L 0.631 16.612 C 0.631 16.612 0.629 16.609 2 16 C 0.628 15.392 0.629 15.392 0.629 15.391 L 0.631 15.388 L 0.633 15.381 L 0.641 15.364 L 0.668 15.307 C 0.689 15.26 0.72 15.196 0.76 15.116 C 0.84 14.957 0.956 14.733 1.111 14.461 C 1.42 13.917 1.885 13.171 2.521 12.341 C 3.788 10.688 5.764 8.655 8.573 7.236 C 9.313 6.863 10.216 7.16 10.589 7.899 Z M 2 16 L 0.629 15.391 C 0.457 15.779 0.457 16.221 0.629 16.609 L 2 16 Z"
            fill="currentcolor"
          />
          <path
            d="M 13.165 5.733 C 14.103 5.576 15.051 5.499 16.001 5.5 C 21.5 5.5 25.36 7.989 27.811 10.44 C 29.032 11.66 29.912 12.879 30.488 13.795 C 30.777 14.253 30.992 14.64 31.137 14.917 C 31.209 15.056 31.265 15.168 31.304 15.248 C 31.324 15.288 31.339 15.321 31.349 15.345 L 31.363 15.375 L 31.368 15.384 L 31.369 15.388 C 31.577 15.855 31.544 16.22 31.371 16.611 L 31.347 16.663 C 31.333 16.691 31.316 16.728 31.292 16.776 C 31.247 16.871 31.181 17.001 31.093 17.165 C 30.92 17.491 30.663 17.944 30.315 18.476 C 29.621 19.536 28.559 20.929 27.075 22.256 C 26.457 22.808 25.509 22.755 24.957 22.137 C 24.404 21.52 24.457 20.572 25.075 20.02 C 26.317 18.909 27.216 17.733 27.804 16.833 C 28.013 16.513 28.183 16.229 28.312 16 C 28.213 15.827 28.093 15.621 27.949 15.393 C 27.464 14.621 26.719 13.589 25.689 12.561 C 23.64 10.512 20.501 8.5 16 8.5 L 15.997 8.5 C 15.213 8.499 14.432 8.563 13.66 8.692 C 12.843 8.829 12.069 8.277 11.933 7.46 C 11.796 6.643 12.348 5.869 13.165 5.733 Z"
            fill="currentcolor"
          />
          <path
            d="M 15.955 10.901 C 16.059 10.359 16.581 10.003 17.124 10.105 C 18.4 10.347 19.564 10.997 20.437 11.959 C 21.312 12.919 21.851 14.139 21.971 15.432 C 22.023 15.983 21.617 16.469 21.068 16.521 C 20.519 16.572 20.031 16.168 19.98 15.617 C 19.899 14.756 19.541 13.944 18.959 13.304 C 18.376 12.664 17.601 12.231 16.752 12.071 C 16.209 11.967 15.852 11.444 15.955 10.901 Z"
            fill="currentcolor"
          />
        </>
      )
    case IconType.Highlights:
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 1C22.5523 1 23 1.44772 23 2V4H25C25.5523 4 26 4.44772 26 5C26 5.55228 25.5523 6 25 6H23V8C23 8.55228 22.5523 9 22 9C21.4477 9 21 8.55228 21 8V6H19C18.4477 6 18 5.55228 18 5C18 4.44772 18.4477 4 19 4H21V2C21 1.44772 21.4477 1 22 1ZM14.0005 9.34396L12.0704 14.5811C11.9448 14.9222 11.7465 15.232 11.4895 15.489C11.2325 15.7461 10.9227 15.9443 10.5816 16.07L5.34442 18L10.5816 19.93C10.9226 20.0557 11.2325 20.2539 11.4895 20.511C11.7465 20.768 11.9447 21.0777 12.0704 21.4188L14.0005 26.656L15.9305 21.4189C16.0561 21.0778 16.2544 20.768 16.5114 20.511C16.7684 20.2539 17.0782 20.0557 17.4193 19.93L22.6565 18L17.4193 16.07C17.0783 15.9443 16.7684 15.7461 16.5114 15.489C16.2544 15.232 16.0562 14.9223 15.9305 14.5812L14.0005 9.34396ZM18.6677 22.6672L24.9691 20.345L24.9724 20.3437C25.4509 20.1662 25.8635 19.8464 26.1549 19.4274C26.4463 19.0084 26.6025 18.5103 26.6025 18C26.6025 17.4897 26.4463 16.9915 26.1549 16.5726C25.8635 16.1536 25.4509 15.8338 24.9724 15.6562L18.6677 13.3328L16.3454 7.03131L16.3442 7.02802C16.1666 6.54957 15.8469 6.13694 15.4279 5.84555C15.0089 5.55417 14.5108 5.39798 14.0005 5.39798C13.4901 5.39798 12.992 5.55417 12.573 5.84555C12.1541 6.13694 11.8343 6.54957 11.6567 7.02802L11.6555 7.03131L9.33325 13.3328L3.03177 15.655L3.02847 15.6562C2.55003 15.8338 2.1374 16.1536 1.84601 16.5726C1.55462 16.9915 1.39844 17.4897 1.39844 18C1.39844 18.5103 1.55462 19.0084 1.84601 19.4274C2.1374 19.8464 2.55003 20.1662 3.02847 20.3437L3.03177 20.345L9.33325 22.6672L11.6555 28.9687L11.6567 28.972C11.8343 29.4504 12.1541 29.8631 12.573 30.1544C12.992 30.4458 13.4901 30.602 14.0005 30.602C14.5108 30.602 15.0089 30.4458 15.4279 30.1544C15.8469 29.8631 16.1666 29.4504 16.3442 28.972L16.3454 28.9687L18.6677 22.6672ZM28 8C28.5523 8 29 8.44772 29 9V10H30C30.5523 10 31 10.4477 31 11C31 11.5523 30.5523 12 30 12H29V13C29 13.5523 28.5523 14 28 14C27.4477 14 27 13.5523 27 13V12H26C25.4477 12 25 11.5523 25 11C25 10.4477 25.4477 10 26 10H27V9C27 8.44772 27.4477 8 28 8Z"
          fill="currentcolor"
        />
      )
    case IconType.Home:
      return (
        <path
          d="M19 26V20C19 19.7348 18.8946 19.4805 18.7071 19.2929C18.5196 19.1054 18.2652 19 18 19H14C13.7348 19 13.4804 19.1054 13.2929 19.2929C13.1054 19.4805 13 19.7348 13 20V26C13 26.2652 12.8946 26.5196 12.7071 26.7071C12.5196 26.8947 12.2652 27 12 27H6C5.73478 27 5.48043 26.8947 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V14.4375C5.00224 14.2991 5.03215 14.1626 5.08796 14.0359C5.14378 13.9093 5.22437 13.7951 5.325 13.7L15.325 4.61252C15.5093 4.44387 15.7501 4.35034 16 4.35034C16.2499 4.35034 16.4907 4.44387 16.675 4.61252L26.675 13.7C26.7756 13.7951 26.8562 13.9093 26.912 14.0359C26.9679 14.1626 26.9978 14.2991 27 14.4375V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8947 26.2652 27 26 27H20C19.7348 27 19.4804 26.8947 19.2929 26.7071C19.1054 26.5196 19 26.2652 19 26Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )
    case IconType.Link:
      return (
        <>
          <path
            d="M 11.762 20.238 L 20.237 11.75"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 18.125 22.363 L 14.588 25.9 C 14.03 26.457 13.369 26.899 12.641 27.201 C 11.912 27.503 11.132 27.658 10.344 27.658 C 8.752 27.658 7.226 27.026 6.1 25.9 C 4.975 24.775 4.342 23.248 4.342 21.656 C 4.342 20.065 4.975 18.538 6.1 17.413 L 9.638 13.875"
            stroke="currentcolor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 22.362 18.125 L 25.9 14.588 C 27.025 13.462 27.658 11.936 27.658 10.344 C 27.658 8.752 27.025 7.226 25.9 6.1 C 24.774 4.974 23.248 4.342 21.656 4.342 C 20.064 4.342 18.538 4.974 17.412 6.1 L 13.875 9.638"
            stroke="currentcolor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Lock:
      return (
        <>
          <path
            d="M24.1818 11H7.81818C7.36631 11 7 11.4477 7 12V26C7 26.5523 7.36631 27 7.81818 27H24.1818C24.6337 27 25 26.5523 25 26V12C25 11.4477 24.6337 11 24.1818 11Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5 11V6.5C11.5 5.30653 11.9741 4.16193 12.818 3.31802C13.6619 2.47411 14.8065 2 16 2C17.1935 2 18.3381 2.47411 19.182 3.31802C20.0259 4.16193 20.5 5.30653 20.5 6.5V11"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Logout:
      return (
        <>
          <path
            d="M 16 6 L 16 15.5"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M 22 6.775 C 23.988 8.068 25.504 9.969 26.323 12.195 C 27.142 14.42 27.22 16.851 26.546 19.124 C 25.871 21.397 24.48 23.392 22.579 24.81 C 20.679 26.228 18.371 26.995 16 26.995 C 13.629 26.995 11.321 26.228 9.421 24.81 C 7.52 23.392 6.129 21.397 5.455 19.124 C 4.78 16.851 4.858 14.42 5.677 12.195 C 6.496 9.969 8.013 8.068 10 6.775"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Menu:
      return (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8C4 7.44772 4.44772 7 5 7H27C27.5523 7 28 7.44772 28 8C28 8.55228 27.5523 9 27 9H5C4.44772 9 4 8.55228 4 8Z"
            fill="currentcolor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 24C4 23.4477 4.44772 23 5 23H27C27.5523 23 28 23.4477 28 24C28 24.5523 27.5523 25 27 25H5C4.44772 25 4 24.5523 4 24Z"
            fill="currentcolor"
          />
        </>
      )
    case IconType.Move:
      return (
        <>
          <path
            d="M16.88 26H5C4.4475 26 4 25.5525 4 25V8C4 7.4475 4.4475 7 5 7H11.6625C11.8788 7 12.0887 7.07125 12.2625 7.2L15.7375 9.8C15.9113 9.92875 16.1212 9.99875 16.3375 10H27C27.5525 10 28 10.4475 28 11V15"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 19H28V25"
            stroke="currentcolor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 26L28 19"
            stroke="currentcolor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Notification:
      return (
        <>
          <path
            d="M7.0249 13C7.02323 11.8145 7.25613 10.6403 7.71018 9.54522C8.16423 8.45009 8.83046 7.45561 9.67049 6.61906C10.5105 5.78251 11.5078 5.12042 12.6048 4.67093C13.7018 4.22144 14.8769 3.99342 16.0624 4.00002C21.0124 4.03752 24.9749 8.15002 24.9749 13.1125V14C24.9749 18.475 25.9124 21.075 26.7374 22.5C26.825 22.6518 26.8712 22.8239 26.8714 22.9991C26.8715 23.1744 26.8256 23.3466 26.7383 23.4985C26.6509 23.6504 26.5252 23.7767 26.3737 23.8647C26.2221 23.9527 26.0501 23.9994 25.8749 24H6.1249C5.94966 23.9994 5.77766 23.9527 5.62613 23.8647C5.4746 23.7767 5.34886 23.6504 5.26151 23.4985C5.17416 23.3466 5.12826 23.1744 5.12842 22.9991C5.12857 22.8239 5.17478 22.6518 5.2624 22.5C6.0874 21.075 7.0249 18.475 7.0249 14V13Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Overflow:
      return (
        <>
          <path
            d="M16 17.5C16.8284 17.5 17.5 16.8284 17.5 16C17.5 15.1716 16.8284 14.5 16 14.5C15.1716 14.5 14.5 15.1716 14.5 16C14.5 16.8284 15.1716 17.5 16 17.5Z"
            fill="currentcolor"
          />
          <path
            d="M16 11.5C16.8284 11.5 17.5 10.8284 17.5 10C17.5 9.17157 16.8284 8.5 16 8.5C15.1716 8.5 14.5 9.17157 14.5 10C14.5 10.8284 15.1716 11.5 16 11.5Z"
            fill="currentcolor"
          />
          <path
            d="M16 23.5C16.8284 23.5 17.5 22.8284 17.5 22C17.5 21.1716 16.8284 20.5 16 20.5C15.1716 20.5 14.5 21.1716 14.5 22C14.5 22.8284 15.1716 23.5 16 23.5Z"
            fill="currentcolor"
          />
        </>
      )
    case IconType.Play:
      return (
        <>
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M20 16L14 12V20L20 16Z"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Plus:
      return (
        <>
          <path
            d="M4 16H28"
            stroke="currentcolor"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 4V28"
            stroke="currentcolor"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Props:
      return (
        <>
          <path
            d="M8.86957 15.6369C8.74521 15.4214 8.57962 15.2324 8.38224 15.0809C8.18487 14.9293 7.95958 14.8181 7.71923 14.7536C7.47888 14.6892 7.22819 14.6727 6.98146 14.7051C6.73473 14.7375 6.4968 14.8181 6.28125 14.9425C6.0657 15.0668 5.87676 15.2324 5.72521 15.4298C5.57365 15.6272 5.46246 15.8525 5.39798 16.0928C5.33349 16.3332 5.31698 16.5839 5.34938 16.8306C5.38179 17.0773 5.46247 17.3153 5.58682 17.5308L9.47982 24.2752C10.4947 25.9946 12.1473 27.2438 14.0783 27.7512C16.0093 28.2587 18.0626 27.9833 19.7917 26.985C21.5207 25.9866 22.7857 24.3461 23.3117 22.42C23.8376 20.494 23.582 18.4382 22.6003 16.6996L20.8116 13.5957C20.545 13.1937 20.1351 12.9086 19.6655 12.7984C19.1959 12.6883 18.7019 12.7616 18.2845 13.0032C17.867 13.2448 17.5574 13.6366 17.419 14.0987C17.2805 14.5607 17.3236 15.0582 17.5394 15.4896"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6776 18.1201L10.6794 11.1969C10.555 10.9813 10.3894 10.7924 10.1921 10.6408C9.99468 10.4893 9.76939 10.3781 9.52904 10.3136C9.2887 10.2491 9.038 10.2326 8.79127 10.265C8.54454 10.2974 8.30661 10.3781 8.09106 10.5024C7.87552 10.6268 7.68657 10.7924 7.53502 10.9897C7.38347 11.1871 7.27228 11.4124 7.20779 11.6528C7.14331 11.8931 7.1268 12.1438 7.1592 12.3905C7.1916 12.6373 7.27228 12.8752 7.39664 13.0907L11.3949 20.014"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.223 18.4146L14.8039 10.7549C14.5528 10.3196 14.139 10.0018 13.6536 9.87162C13.1682 9.74139 12.6509 9.80931 12.2156 10.0605C11.7803 10.3116 11.4625 10.7254 11.3323 11.2108C11.2021 11.6962 11.27 12.2135 11.5212 12.6488"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.8359 9.93429L12.7521 8.05092C12.501 7.6156 12.0872 7.29788 11.6018 7.16765C11.1164 7.03742 10.5991 7.10535 10.1638 7.3565C9.7285 7.60764 9.41078 8.02143 9.28055 8.50683C9.15032 8.99224 9.21825 9.50949 9.4694 9.94481L9.72191 10.3762"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.1998 22.96C24.484 21.7972 25.3315 20.2299 25.6014 18.5186C25.8713 16.8074 25.5473 15.0553 24.6834 13.5537L22.8947 10.4603C22.7704 10.2448 22.6048 10.0558 22.4074 9.9043C22.21 9.75275 21.9847 9.64155 21.7444 9.57707C21.504 9.51259 21.2533 9.49607 21.0066 9.52848C20.7599 9.56088 20.5219 9.64156 20.3064 9.76592C20.0908 9.89027 19.9019 10.0559 19.7504 10.2532C19.5988 10.4506 19.4876 10.6759 19.4231 10.9163C19.3586 11.1566 19.3421 11.4073 19.3745 11.654C19.4069 11.9008 19.4876 12.1387 19.612 12.3542"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.854 12.7751L16.8764 7.61951C16.6253 7.18419 16.2115 6.86647 15.7261 6.73624C15.2407 6.60601 14.7234 6.67394 14.2881 6.92508C13.8528 7.17623 13.535 7.59002 13.4048 8.07542C13.2746 8.56083 13.3425 9.07808 13.5937 9.5134"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0217 4L21.5903 5.62033"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.2097 5.48358L24.2417 6.86191"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.6823 7.95616L26.304 8.92415"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Record:
      return (
        <>
          <path
            d="M3 7.5H19C20.0609 7.5 21.0783 7.92143 21.8284 8.67157C22.5786 9.42172 23 10.4391 23 11.5V23.5C23 23.7652 22.8946 24.0196 22.7071 24.2071C22.5196 24.3946 22.2652 24.5 22 24.5H6C4.93913 24.5 3.92172 24.0786 3.17157 23.3284C2.42143 22.5783 2 21.5609 2 20.5V8.5C2 8.23478 2.10536 7.98043 2.29289 7.79289C2.48043 7.60536 2.73478 7.5 3 7.5Z"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 14L30 10V22L23 18"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Recurring:
      return (
        <>
          <path
            d="M9.9751 12.4625H3.9751V6.46249"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.7751 8.22499C22.7546 7.20301 21.5426 6.39224 20.2085 5.83907C18.8744 5.28589 17.4443 5.00116 16.0001 5.00116C14.5558 5.00116 13.1258 5.28589 11.7917 5.83907C10.4576 6.39224 9.24559 7.20301 8.2251 8.22499L3.9751 12.4625"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0249 19.5375H28.0249V25.5375"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.2251 23.775C9.24559 24.797 10.4576 25.6078 11.7917 26.1609C13.1258 26.7141 14.5558 26.9988 16.0001 26.9988C17.4443 26.9988 18.8744 26.7141 20.2085 26.1609C21.5426 25.6078 22.7546 24.797 23.7751 23.775L28.0251 19.5375"
            stroke="currentcolor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Search:
      return (
        <>
          <path
            d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.925 21.925L28 28"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Settings:
      return (
        <>
          <path
            d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
            stroke="currentcolor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.675 10.0875C24.976 10.5183 25.2394 10.9742 25.4625 11.45L28.7 13.25C29.1043 15.0606 29.1086 16.9376 28.7125 18.75L25.4625 20.55C25.2394 21.0258 24.976 21.4816 24.675 21.9125L24.7375 25.625C23.3655 26.8753 21.7416 27.8172 19.975 28.3875L16.7875 26.475C16.2632 26.5125 15.7369 26.5125 15.2125 26.475L12.0375 28.375C10.2654 27.8151 8.63615 26.8768 7.26254 25.625L7.32504 21.925C7.02662 21.4882 6.7633 21.0284 6.53754 20.55L3.30004 18.75C2.89578 16.9394 2.89152 15.0624 3.28754 13.25L6.53754 11.45C6.76064 10.9742 7.0241 10.5183 7.32504 10.0875L7.26254 6.37499C8.6346 5.12469 10.2585 4.18274 12.025 3.61249L15.2125 5.52499C15.7369 5.48748 16.2632 5.48748 16.7875 5.52499L19.9625 3.62499C21.7347 4.18486 23.3639 5.12318 24.7375 6.37499L24.675 10.0875Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Theme:
      return (
        <>
          <path
            d="M9 4H26C26.2652 4 26.5196 4.10536 26.7071 4.29289C26.8946 4.48043 27 4.73478 27 5V18C27 18.2652 26.8946 18.5196 26.7071 18.7071C26.5196 18.8946 26.2652 19 26 19H6C5.73478 19 5.48043 18.8946 5.29289 18.7071C5.10536 18.5196 5 18.2652 5 18V8C5 6.93913 5.42143 5.92172 6.17157 5.17157C6.92172 4.42143 7.93913 4 9 4V4Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 19L13 26C13 26.7956 13.3161 27.5587 13.8787 28.1213C14.4413 28.6839 15.2044 29 16 29C16.7956 29 17.5587 28.6839 18.1213 28.1213C18.6839 27.5587 19 26.7956 19 26L18 19"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 14H27"
            stroke="currentcolor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21 4V9"
            stroke="currentcolor"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Time:
      return (
        <>
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M16 16H23"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.95 20.95L16 16"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.Upload:
      return (
        <>
          <path
            d="M10.75 10.25L16 5L21.25 10.25"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 19V5"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )
    case IconType.User:
      return (
        <>
          <path
            d="M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20Z"
            stroke="currentcolor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M7.97485 24.9218C8.72812 23.4408 9.8765 22.1971 11.2929 21.3284C12.7093 20.4598 14.3384 20 16 20C17.6615 20 19.2906 20.4598 20.707 21.3284C22.1234 22.1971 23.2718 23.4407 24.0251 24.9217"
            stroke="currentcolor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="currentcolor"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </>
      )
    case IconType.Workplace:
      return (
        <svg viewBox="-6 -6 32 32">
          <path
            d="M10.6058 0C15.4534 0 19.3902 3.92951 19.3902 8.78446C19.3902 10.7896 18.7144 12.6405 17.5834 14.1168C17.3189 14.4327 16.8415 14.9174 16.085 14.9174C15.0199 14.9174 14.6748 14.1536 14.3516 13.3383L12.7504 9.40143L11.1565 13.3383C10.8848 14.0067 10.591 14.9174 9.42315 14.9174C8.25542 14.9174 7.95416 14.0067 7.68253 13.3383L4.99423 6.71321H7.26381L9.42315 12.1631L11.0317 8.18952C11.2815 7.56521 11.59 6.61038 12.7504 6.61038C13.9109 6.61038 14.2193 7.55786 14.4765 8.18952L16.2245 12.4936C16.9296 11.4286 17.341 10.1506 17.341 8.78446C17.341 5.06795 14.3296 2.05657 10.6131 2.05657C8.49035 2.05657 6.49991 2.88654 4.99423 4.38488C3.49591 5.88324 2.66599 7.88105 2.66599 10.0037C2.66599 12.1264 3.49591 14.1242 4.99423 15.6225C6.49254 17.1208 8.49035 17.9509 10.6131 17.9509C11.6487 17.9509 12.6329 17.7525 13.5364 17.3926L14.2929 19.3023C13.1543 19.7503 11.9131 20 10.6131 20C9.26161 20 7.94694 19.7356 6.72025 19.2141C5.53041 18.7074 4.45804 17.9876 3.53998 17.0694C2.62192 16.1513 1.90209 15.0863 1.39523 13.8965C0.873783 12.6626 0.609375 11.3552 0.609375 10.0037C0.609375 8.65225 0.873783 7.34486 1.39523 6.11092C1.90209 4.92105 2.62192 3.84871 3.53998 2.93794C4.45804 2.01984 5.52304 1.30004 6.72025 0.793245C7.94694 0.264415 9.26161 0 10.6058 0Z"
            fill="#666666"
          />
        </svg>
      )
    default:
      // Guarantee we cover all types.
      assertNever(type)
      return <path />
  }
}
