import { gql, useMutation, useQuery } from "@apollo/client"
import { useContext } from "react"
import toast from "react-hot-toast"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import EditOrganizationForm, {
  ValueType,
} from "../features/edit-organization-form"
import OrganizationUpdatedToast from "../features/edit-organization/organization-updated-toast"
import {
  EditOrganizationGetOrganizationQuery,
  EditOrganizationGetOrganizationQueryVariables,
  EditOrganizationUpdateOrganizationMutation,
  EditOrganizationUpdateOrganizationMutationVariables,
  EditOrganizationUpdateOrganizationPermissionsMutation,
  EditOrganizationUpdateOrganizationPermissionsMutationVariables,
  Permission,
} from "../generated/graphql"
import { UserContext } from "../user-provider"
import ErrorScreen from "./error-screen"

const ORGANIZATION_QUERY = gql`
  query EditOrganizationGetOrganization($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      ... on Organization {
        name
        logoImageUri
        defaultPlaybackRate
        accessControl {
          id
          includeEmailSuffixes
          includeEmails
          excludeEmails
          defaultPermissions
        }
      }
    }
  }
`

const ORGANIZATION_SUMMARY_QUERY = gql`
  query EditOrganizationGetOrganizationSummary($organizationSlug: String!) {
    organizationSummary(organizationSlug: $organizationSlug) {
      name
      logoImageUri
    }
  }
`

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation EditOrganizationUpdateOrganization(
    $organizationId: String!
    $name: String!
    $logoImage: Upload
    $defaultPlaybackRate: Int!
  ) {
    updateOrganization(
      input: {
        organizationId: $organizationId
        name: $name
        logoImage: $logoImage
        defaultPlaybackRate: $defaultPlaybackRate
      }
    ) {
      id
      name
      logoImageUri
      defaultPlaybackRate
    }
  }
`

const UPDATE_PERMISSIONS_MUTATION = gql`
  mutation EditOrganizationUpdateOrganizationPermissions(
    $organizationId: String!
    $includeEmailSuffixes: [String!]
    $includeEmails: [String!]
    $excludeEmails: [String!]
  ) {
    updateOrganizationPermissions(
      input: {
        organizationId: $organizationId
        includeEmailSuffixes: $includeEmailSuffixes
        includeEmails: $includeEmails
        excludeEmails: $excludeEmails
      }
    ) {
      id
      accessControl {
        id
        includeEmailSuffixes
        includeEmails
        excludeEmails
      }
    }
  }
`

export default function EditOrganizationScreen({
  onCancelClick,
}: {
  onCancelClick: () => void
}) {
  const intl = useIntl()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    EditOrganizationGetOrganizationQuery,
    EditOrganizationGetOrganizationQueryVariables
  >(ORGANIZATION_QUERY, { variables: { organizationSlug: organizationSlug! } })
  const [
    runUpdateNameLogoDefaultPlaybackRateMutation,
    { loading: updateOrganizationLoading },
  ] = useMutation<
    EditOrganizationUpdateOrganizationMutation,
    EditOrganizationUpdateOrganizationMutationVariables
  >(UPDATE_ORGANIZATION_MUTATION)
  const [
    runUpdatePermissionsMutation,
    { loading: updateOrganizationPermissionsLoading },
  ] = useMutation<
    EditOrganizationUpdateOrganizationPermissionsMutation,
    EditOrganizationUpdateOrganizationPermissionsMutationVariables
  >(UPDATE_PERMISSIONS_MUTATION)
  const { hasPermission } = useContext(UserContext)

  const handleSubmitClick = async (newValue: ValueType) => {
    await Promise.all([
      runUpdateNameLogoDefaultPlaybackRateMutation({
        variables: {
          organizationId: data!.organization.id,
          name: newValue.name,
          logoImage: newValue.logoImage,
          defaultPlaybackRate: newValue.defaultPlaybackRate,
        },
        update: cache => {
          // Delete all cached data for organization summary.
          cache.modify({
            id: cache.identify({
              __typename: "OrganizationSummary",
              id: data?.organization.id,
            }),
            fields: (_, { DELETE }) => DELETE,
          })
        },

        refetchQueries: [
          {
            query: ORGANIZATION_SUMMARY_QUERY,
            variables: { organizationSlug },
          },
        ],
      }),
      runUpdatePermissionsMutation({
        variables: {
          organizationId: data!.organization.id,
          includeEmailSuffixes: newValue.includeEmailSuffixes,
          includeEmails: newValue.includeEmails,
          excludeEmails: newValue.excludeEmails,
        },
      }),
    ])

    toast(
      t => (
        <OrganizationUpdatedToast onDismissClick={() => toast.dismiss(t.id)} />
      ),
      {
        id: "organization-updated",
      }
    )
  }

  if (!hasPermission(Permission.ReadWritePermissions)) {
    return (
      <ErrorScreen
        message={intl.formatMessage({
          defaultMessage: "You don't have enough permissions.",
        })}
      />
    )
  }

  if (!data?.organization) {
    return null
  }

  return (
    <EditOrganizationForm
      disabled={
        updateOrganizationLoading || updateOrganizationPermissionsLoading
      }
      onCancelClick={onCancelClick}
      onSubmitClick={handleSubmitClick}
      value={{
        name: data.organization.name,
        defaultPlaybackRate: data.organization.defaultPlaybackRate,
        logoImage: null,
        logoImageUri: data.organization.logoImageUri ?? null,
        includeEmailSuffixes:
          data.organization.accessControl?.includeEmailSuffixes ?? [],
        includeEmails: data.organization.accessControl?.includeEmails ?? [],
        excludeEmails: data.organization.accessControl?.excludeEmails ?? [],
      }}
    />
  )
}
