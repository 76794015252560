// cSpell:ignore autocapture, pageview
import axios from "axios"
import posthog from "posthog-js"
import {
  getUserToken,
  getUserTokenClaims,
  subscribeToUserTokenChanges,
} from "./auth"
import env from "./env"
import { getRollbarInstance } from "./get-rollbar-instance"
import { getOrganizationSlug } from "./utils/get-organization-slug"

const rollbar = getRollbarInstance()

let loggedIn = false

const fetchRoleNames = async (personId: string, userToken: string) => {
  try {
    const response = await axios.post<{
      data: {
        node: {
          roles: {
            items: Array<{ name: string }>
          }
        }
      }
    }>(
      `${env.apiBaseUri()}graphql`,
      {
        query:
          "query PostHogGetPersonRoles($personId: String!) { node (id: $personId) { ... on Person { roles { items { name } } } } }",
        variables: { personId },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )

    return response.data.data.node.roles.items.map(role => role.name)
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(error)
    rollbar.error(error.message, error)
    return []
  }
}

const updateIdentity = async (newUserToken: string | null | undefined) => {
  const newLoggedIn = !!newUserToken
  if (newLoggedIn === loggedIn) {
    return
  }

  loggedIn = newLoggedIn

  if (loggedIn) {
    const { email, organizationId, personId } = getUserTokenClaims()
    posthog.identify(personId!, {
      email: email!,
      organizationId: organizationId!,
      organizationSlug: getOrganizationSlug(),
      roles: await fetchRoleNames(personId!, newUserToken!),
    })
  } else {
    posthog.reset()
  }
}

export const initPostHog = () => {
  posthog.init(env.postHogApiKey(), {
    api_host: "https://t.venue.video",
    capture_pageview: false,
    autocapture: false, // It's hard to take advantage of auto-captured events - https://posthog.com/blog/is-autocapture-still-bad
  })

  subscribeToUserTokenChanges(updateIdentity)

  updateIdentity(getUserToken())
}
