const isProduction = process.env.NODE_ENV === "production"

/**
 * The screen displayed when no organization is selected. Shows a message in development, and redirects in production.
 */
export default function HomeScreen() {
  if (isProduction) {
    window.location.href = "https://www.venue.video"
    return null
  }

  return <h1>Please select an organization</h1>
}
