import { ErrorBoundary } from "@rollbar/react"
import { ReactNode, useContext } from "react"
import { Helmet } from "react-helmet-async"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import SideNav from "../features/side-nav"
import VideoUploadDropTarget from "../features/video-upload/video-upload-drop-target"
import { Permission } from "../generated/graphql"
import { OrganizationContext } from "../organization-provider"
import { UserContext } from "../user-provider"
import ErrorScreen from "./error-screen"

const CHANNEL_PATTERN = /^\/[^/]+\/channels\/([^/]+)(?:\/.*)?$/i

export default function OrganizationLayout() {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { name: organizationName } = useContext(OrganizationContext)
  const { hasPermission } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()

  const isVideoUploadPath = /^\/[^/]+\/upload-video\/?$/i.test(
    location.pathname
  )

  const handleVideoUploadDropAccepted = (file: File) => {
    const channelMatches = CHANNEL_PATTERN.exec(location.pathname)
    const channelSearchParam =
      channelMatches !== null ? `?channel=${channelMatches[1]}` : ""
    navigate(`/${organizationSlug}/upload-video${channelSearchParam}`, {
      state: { file },
    })
  }

  return (
    <VideoUploadDropTarget
      enabled={!isVideoUploadPath && hasPermission(Permission.WriteVideos)}
      onDropAccepted={handleVideoUploadDropAccepted}
    >
      <Helmet title={`Venue • ${organizationName}`}>
        <link rel="icon" href="/favicon.png" />
      </Helmet>
      <div className="h-full flex flex-row overflow-hidden">
        <SideNav />
        <div className="flex-1 overflow-y-auto">
          <ErrorBoundary fallbackUI={ErrorScreen as unknown as ReactNode}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </VideoUploadDropTarget>
  )
}
