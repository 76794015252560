export enum ValidationFormat {
  Email = "email",
  Slug = "slug",
  URL = "URL",
}

export const FORMAT_PATTERNS: Record<ValidationFormat, RegExp> = {
  [ValidationFormat.Email]: /^[^@]+@[^@]+\.[^@]+$/i,
  [ValidationFormat.Slug]: /^[a-z0-9][-a-z0-9]*$/i,
  [ValidationFormat.URL]: /^https?:\/\/.+/i,
}
