/* eslint-disable react/button-has-type */
import classNames from "classnames"
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import { FormattedMessage } from "react-intl"
import ButtonLabel from "../button-label"
import Icon from "../icon"
import { ButtonProps } from "./types"

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps &
    Omit<
      DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "children"
    >
>(
  (
    {
      beta,
      className,
      compact = false,
      disabled = false,
      icon,
      iconColor,
      iconSize = "small",
      label,
      theme = "primary",
      type = "button",
      ...props
    },
    ref
  ) => (
    <button
      className={classNames(
        "appearance-none inline-block rounded-lg border",
        "flex flex-row items-center justify-center gap-2",
        "outline-none outline-offset-0 focus:outline focus:outline-[#f6f6f6]",
        compact && "h-[32px] min-w-[32px]",
        !compact && "h-[40px] min-w-[40px]",
        // Add horizontal padding if there is a label
        !!label && compact && "px-[8px]",
        !!label && !compact && "px-[12px]",
        disabled &&
          "bg-tv-coal border-[#313131] text-tv-gunmetal hover:bg-tv-coal hover:border-[#313131] cursor-not-allowed",
        !disabled &&
          theme === "primary" &&
          "bg-tv-dandelion border-tv-dandelion text-tv-gunmetal hover:bg-[#f8b259] hover:border-[#f8b259]",
        !disabled &&
          theme === "secondary" &&
          "bg-[#f6f6f6] border-[#c8c8c8] text-tv-gunmetal hover:bg-[#f8eed1] hover:border-[#c8c8c8]",
        !disabled &&
          theme === "tertiary" &&
          "bg-[#181818] border-tv-raven text-tv-milk hover:bg-[#262626] hover:border-tv-raven",
        !disabled &&
          theme === "danger" &&
          "bg-[#181818] border-tv-raven text-tv-poppy hover:bg-[#262626] hover:border-tv-raven",
        className
      )}
      disabled={disabled}
      ref={ref}
      type={type}
      {...props}
    >
      {icon ? (
        <Icon color={iconColor || "#666666"} size={iconSize} type={icon} />
      ) : null}
      {label ? (
        <ButtonLabel
          compact={compact}
          disabled={disabled}
          theme={theme}
          value={label}
        />
      ) : null}
      {beta ? (
        <div className="inline-block px-[4px] py-[2px] bg-tv-dandelion rounded-full text-[0.5625rem] text-tv-coal">
          <FormattedMessage
            defaultMessage="BETA"
            description="Button beta tag"
          />
        </div>
      ) : null}
    </button>
  )
)

Button.displayName = "Button"
export default Button
