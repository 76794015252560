import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react"
import { getSquirclePolygon } from "../../utils/get-squircle-polygon"

const Squircle = forwardRef<
  HTMLDivElement,
  { size: number } & DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
>(({ children, size, style, ...props }, ref) => (
  <div
    style={{
      width: size,
      height: size,
      clipPath: `path("${getSquirclePolygon(size)}")`,
      ...style,
    }}
    {...props}
    ref={ref}
  >
    {children}
  </div>
))

Squircle.displayName = "Squircle"
export default Squircle
