import classNames from "classnames"
import { Toaster, ToasterProps, resolveValue } from "react-hot-toast"

export default function CustomToaster({
  containerStyle,
  ...allProps
}: ToasterProps) {
  return (
    <Toaster containerStyle={{ ...containerStyle, zIndex: 40 }} {...allProps}>
      {t => (
        <div
          className={classNames(
            "bg-tv-midnight rounded-xl text-tv-milk drop-shadow-xl transition-all p-4",
            t.visible
              ? "opacity-100 animate-toastEnter"
              : "opacity-0 animate-toastLeave"
          )}
        >
          {resolveValue(t.message, t)}
        </div>
      )}
    </Toaster>
  )
}
