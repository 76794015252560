import { FormattedMessage, FormattedNumber } from "react-intl"

export default function UploadingToast({
  percentComplete,
  videoTitle,
}: {
  percentComplete: number
  videoTitle: string
}) {
  return (
    <div className="w-[300px] flex flex-col gap-2">
      <div className="flex flex-row justify-between items-end gap-2">
        <div className="flex-1 truncate text-title4 text-tv-beast">
          <FormattedMessage
            defaultMessage="Uploading video <span>{videoTitle}</span>"
            description="Uploading video toast message"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              span: chunks => <span className="text-tv-milk">{chunks}</span>,
              videoTitle,
            }}
          />
        </div>
        <div className="text-tv-dust text-subhead3">
          <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="percent"
            value={percentComplete / 100}
            minimumFractionDigits={0}
          />
        </div>
      </div>
      <div className="w-full bg-tv-dust rounded-full h-[4px] overflow-hidden">
        <div
          className="h-full bg-tv-blue rounded-full"
          style={{ width: `${percentComplete}%` }}
        />
      </div>
    </div>
  )
}
