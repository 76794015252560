import { CaptionType } from "./caption"
import TranscriptionsNotReadyPanel from "./transcriptions-not-ready-panel"
import TranscriptionsReadyPanel from "./transcriptions-ready-panel"

export default function Transcriptions({
  loadCaptions,
  onChangeTime,
  onStartTranscribingClick,
  status,
  timeMs,
}: {
  loadCaptions: (args: {
    afterTimeMs?: number
    beforeTimeMs?: number
  }) => Promise<{
    captions: Array<CaptionType>
    hasNextPage: boolean
    hasPreviousPage: boolean
  }>
  onChangeTime: (timeMs: number) => void
  onStartTranscribingClick: () => void
  status: "missing" | "preparing_video" | "transcribing" | "ready"
  timeMs: number
}) {
  switch (status) {
    case "ready":
      return (
        <TranscriptionsReadyPanel
          loadCaptions={loadCaptions}
          onChangeTime={onChangeTime}
          timeMs={timeMs}
        />
      )
    default:
      return (
        <TranscriptionsNotReadyPanel
          onStartTranscribingClick={onStartTranscribingClick}
          status={status}
        />
      )
  }
}
