import { useCallback, useState } from "react"

export default function VideoTitleInlineEdit({
  title: originalTitle,
  onTitleChange,
}: {
  title: string
  onTitleChange: (title: string) => void
}) {
  const [title, setTitle] = useState(originalTitle)
  const [isEditing, setIsEditing] = useState(false)

  const inputRef = useCallback((node: HTMLInputElement | null) => {
    if (node !== null) {
      node?.focus()
    }
  }, [])

  const handleTitleClick = () => {
    setIsEditing(true)
  }

  const handleInputBlur = async () => {
    setIsEditing(false)
    if (title !== originalTitle) {
      onTitleChange(title)
    } else {
      setTitle(originalTitle)
    }
  }

  if (isEditing) {
    return (
      <input
        type="text"
        value={title}
        onChange={e => setTitle(e.target.value)}
        onBlur={handleInputBlur}
        ref={inputRef}
        className="bg-[#2d2d2d] font-bold outline-tv-dandelion ml-2 mb-1 -translate-x-2 text-4xl flex flex-1"
      />
    )
  }

  return (
    <button
      type="button"
      onClick={handleTitleClick}
      className="text-4xl flex flex-1"
    >
      <h1 className="text-title1 text-left">{title}</h1>
    </button>
  )
}
