import { useContext, useRef } from "react"
import { useIntl } from "react-intl"
import {
  Location,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom"
import ModalContainer from "./atoms/modal-container"
import SettingsLayout from "./features/settings/settings-layout"
import { Permission } from "./generated/graphql"
import OrganizationProvider from "./organization-provider"
import ChannelPermissionsScreen from "./screens/channel-permissions-screen"
import ChannelRoleAssignmentScreen from "./screens/channel-role-assignment-screen"
import ChannelRoleEditScreen from "./screens/channel-role-edit-screen"
import ChannelScreen from "./screens/channel-screen"
import CreateChannelFolderScreen from "./screens/create-channel-folder-screen"
import CreateChannelRoleScreen from "./screens/create-channel-permission-screen"
import CreateChannelScreen from "./screens/create-channel-screen"
import CreateOrganizationScreen from "./screens/create-organization-screen"
import EditChannelFolderScreen from "./screens/edit-channel-folder-screen"
import EditChannelScreen from "./screens/edit-channel-screen"
import EditOrganizationScreen from "./screens/edit-organization-screen"
import ErrorScreen from "./screens/error-screen"
import HomeScreen from "./screens/home-screen"
import MicrosoftCallbackScreen from "./screens/microsoft-callback-screen"
import OAuthCallbackScreen from "./screens/oauth-callback-screen"
import OktaCallbackScreen from "./screens/okta-callback-screen"
import OrganizationLayout from "./screens/organization-layout"
import OrganizationScreen from "./screens/organization-screen"
import SearchScreen from "./screens/search-screen"
import TeamScreen from "./screens/team-screen"
import UploadVideoScreen from "./screens/upload-video-screen"
import VideoScreen from "./screens/video-screen"
import { UserContext } from "./user-provider"

const MODAL_PATHS: Array<{
  pattern: RegExp
  anchor: "middle" | "top"
  height: "auto" | "stretch"
}> = [
  {
    pattern: /^\/[^/]+\/channels\/[^/]+\/edit\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/channels\/[^/]+\/permissions\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/channels\/[^/]+\/permissions\/create\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/channels\/[^/]+\/permissions\/[^/]+\/assign\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/channels\/[^/]+\/permissions\/[^/]+\/edit\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/channel-folders\/[^/]+\/edit\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/create-channel\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/create-channel-folder\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/create-organization\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/edit\/?$/i,
    anchor: "middle",
    height: "stretch",
  },
  {
    pattern: /^\/[^/]+\/upload-video\/?$/i,
    anchor: "middle",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/search\/?$/i,
    anchor: "top",
    height: "auto",
  },
  {
    pattern: /^\/[^/]+\/team\/?$/i,
    anchor: "middle",
    height: "stretch",
  },
]

export default function LoggedInRouter() {
  const intl = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const lastNonModalLocation = useRef<Location | null>(null)
  const { hasPermission } = useContext(UserContext)

  const matchingModalPath = MODAL_PATHS.find(path =>
    path.pattern.test(location.pathname)
  )
  if (!matchingModalPath) {
    lastNonModalLocation.current = location
  }

  const navigateToLastNonModalLocation = () => {
    if (lastNonModalLocation.current) {
      navigate(lastNonModalLocation.current)
    } else {
      // Navigate to organization screen.
      const [organizationSlug] = location.pathname
        .split("/")
        .filter(part => !!part)
      navigate(`/${organizationSlug}`)
    }
  }

  // The location to display, if not displaying a modal route; or the location to display below the modal route.
  const nonModalLocation =
    matchingModalPath && lastNonModalLocation.current
      ? lastNonModalLocation.current
      : location

  return (
    <>
      <Routes location={nonModalLocation}>
        <Route path="oauth-callback" element={<OAuthCallbackScreen />} />
        <Route
          path="microsoft-callback"
          element={<MicrosoftCallbackScreen />}
        />
        <Route path="okta-callback" element={<OktaCallbackScreen />} />
        <Route
          path=":organizationSlug"
          element={
            <OrganizationProvider>
              <OrganizationLayout />
            </OrganizationProvider>
          }
        >
          <Route path="channels/:channelSlug" element={<ChannelScreen />} />
          <Route path="videos/:videoId" element={<VideoScreen />} />

          {/* Usually we show the last non-modal route below a modal route. */}
          {/* The routes below only apply when the site is loaded with an initial modal route (so there is no last non-modal route). */}
          {/* In that case, show the organization screen below modal. */}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path="channels/:channelSlug/edit"
              element={<OrganizationScreen />}
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route path="create-channel" element={<OrganizationScreen />} />
          )}
          {hasPermission(Permission.CreateOrganizations) && (
            <Route
              path="create-organization"
              element={<OrganizationScreen />}
            />
          )}
          {hasPermission(Permission.UpdateOrganization) && (
            <Route path="edit" element={<OrganizationScreen />} />
          )}
          <Route path="search" element={<OrganizationScreen />} />
          {hasPermission(Permission.ReadWritePermissions) && (
            <Route path="team" element={<OrganizationScreen />} />
          )}
          <Route path="upload-video" element={<OrganizationScreen />} />

          <Route index element={<OrganizationScreen />} />
        </Route>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="*"
          element={
            <ErrorScreen
              message={intl.formatMessage({ defaultMessage: "Not found" })}
            />
          }
        />
      </Routes>

      <ModalContainer
        isOpen={!!matchingModalPath}
        anchor={matchingModalPath?.anchor}
        height={matchingModalPath?.height}
        onDismiss={navigateToLastNonModalLocation}
      >
        {/* Modal routes */}
        <Routes>
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/channels/:channelSlug/edit"
              element={
                <EditChannelScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/channels/:channelSlug/permissions"
              element={
                <ChannelPermissionsScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/channels/:channelSlug/permissions/create"
              element={
                <CreateChannelRoleScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/channels/:channelSlug/permissions/:roleId/assign"
              element={<ChannelRoleAssignmentScreen />}
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/channels/:channelSlug/permissions/:roleId/edit"
              element={<ChannelRoleEditScreen />}
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/channel-folders/:channelFolderId/edit"
              element={
                <EditChannelFolderScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/create-channel"
              element={
                <CreateChannelScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.WriteChannels) && (
            <Route
              path=":organizationSlug/create-channel-folder"
              element={
                <CreateChannelFolderScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.CreateOrganizations) && (
            <Route
              path=":organizationSlug/create-organization"
              element={
                <CreateOrganizationScreen
                  onDiscardClick={navigateToLastNonModalLocation}
                />
              }
            />
          )}
          {hasPermission(Permission.UpdateOrganization) && (
            <Route
              path=":organizationSlug/edit"
              element={
                <SettingsLayout selectedNavItem="general">
                  <EditOrganizationScreen
                    onCancelClick={navigateToLastNonModalLocation}
                  />
                </SettingsLayout>
              }
            />
          )}
          <Route path=":organizationSlug/search" element={<SearchScreen />} />
          {hasPermission(Permission.ReadWritePermissions) && (
            <Route
              path=":organizationSlug/team"
              element={
                <SettingsLayout selectedNavItem="team">
                  <TeamScreen onCancelClick={navigateToLastNonModalLocation} />
                </SettingsLayout>
              }
            />
          )}
          <Route
            path=":organizationSlug/upload-video"
            element={
              <UploadVideoScreen
                onDiscardClick={navigateToLastNonModalLocation}
                onVideoUploaded={navigateToLastNonModalLocation}
              />
            }
          />
          {/* Render nothing if current route is not a modal route */}
          <Route path="*" element={null} />
        </Routes>
      </ModalContainer>
    </>
  )
}
