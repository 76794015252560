import { useIntl } from "react-intl"
import Button from "../../atoms/button"
import { IconType } from "../../atoms/icon/types"
import Select, { SelectItem } from "../../atoms/select"

export default function TeamPersonItem({
  email,
  initials,
  name,
  profileImageUri,
  onChangeRole,
  roleId = "",
  roleItems,
}: {
  email: string
  initials?: string
  name?: string
  onChangeRole: (roleId: string | null) => void
  profileImageUri?: string
  roleId?: string
  roleItems: Array<SelectItem>
}) {
  const intl = useIntl()
  return (
    <div className="flex flex-row items-center justify-between gap-4">
      <div className="flex flex-row items-center gap-4">
        <div className="rounded-3xl w-[40px] h-[40px] bg-gray-500 flex flex-col items-center justify-center">
          {profileImageUri ? (
            <img
              className="rounded-3xl w-full h-full"
              src={profileImageUri}
              alt="profile"
            />
          ) : null}
          {!profileImageUri && initials && <p>{initials}</p>}
        </div>
        <div>
          {name ? <p className="font-semibold">{name}</p> : null}
          <p className="text-tv-dust">{email}</p>
        </div>
      </div>
      <div className="flex flex-row items-center gap-2">
        {roleItems.length > 1 ? (
          // Add person to one of many roles - used in settings
          <Select
            items={roleItems}
            placement="bottom-end"
            placeholder={intl.formatMessage({
              defaultMessage: "Select a role",
              description: "Team person role placeholder",
            })}
            value={roleId}
            onChangeValue={newValue => onChangeRole(newValue)}
          />
        ) : null}
        {!roleId && roleItems.length === 1 ? (
          // Add person to a single role - used in channel permissions
          <Button
            icon={IconType.Plus}
            iconSize="small"
            theme="tertiary"
            label="Add to role"
            onClick={() => onChangeRole(roleItems[0].value)}
          />
        ) : null}
        {!!roleId && (
          <Button
            icon={IconType.Delete}
            iconSize="small"
            iconColor="#bf444e"
            onClick={() => onChangeRole(null)}
            theme="danger"
          />
        )}
      </div>
    </div>
  )
}
