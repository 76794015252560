import classNames from "classnames"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"

export enum SettingsNavItem {
  General = "General",
  Team = "Team",
  MoreSettings = "MoreSettings",
}

export default function NavItem({
  icon,
  imageUri,
  label,
  selected = false,
}: {
  icon?: IconType
  imageUri?: string
  label: string
  selected?: boolean
}) {
  return (
    <div
      className={classNames(
        "p-1 rounded-md flex flex-row items-center gap-2",
        selected && "bg-tv-dandelion"
      )}
    >
      {icon && <Icon type={icon} size="medium" />}
      {!icon && (
        <div
          className="w-[24px] h-[24px] bg-center bg-cover bg-tv-beast rounded-full"
          style={{ backgroundImage: imageUri ? `url(${imageUri})` : undefined }}
        />
      )}
      <span
        className={classNames(
          "font-semibold",
          selected ? "text-tv-coal" : "text-tv-milk"
        )}
      >
        {label}
      </span>
    </div>
  )
}
