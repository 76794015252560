import classNames from "classnames"

export default function ChannelLogo({
  logoImageUri,
  name,
}: {
  logoImageUri?: string
  name: string
}) {
  return (
    <div
      className={classNames(
        "w-[24px] h-[24px] rounded-full",
        "flex items-center justify-center",
        "bg-tv-beast bg-center bg-cover",
        "text-body2 font-semibold"
      )}
      style={{
        backgroundImage: logoImageUri ? `url(${logoImageUri})` : undefined,
      }}
    >
      {!logoImageUri && <>{name.slice(0, 1)}</>}
    </div>
  )
}
