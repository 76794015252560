import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import ChannelPicker, { ChannelPickerItem } from "../../atoms/channel-picker"
import Label from "../../atoms/label"
import TextField from "../../atoms/text-field"
import ValidationError from "../../atoms/validation-error"
import { FORMAT_PATTERNS, ValidationFormat } from "../../utils/validate"
import DropTarget from "./drop-target"

export type ValueType = {
  channelId: string
  file: File | null
  title: string
  url: string
}

export default function UploadVideoForm({
  channels,
  disabled = false,
  file,
  onUploadVideoClick,
  onDiscardClick,
  selectedChannelId,
}: {
  channels: Array<ChannelPickerItem>
  disabled?: boolean
  file?: File
  onUploadVideoClick: (newValue: ValueType) => void
  onDiscardClick: () => void
  selectedChannelId?: string
}) {
  const intl = useIntl()
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ValueType>({
    defaultValues: {
      channelId: selectedChannelId || "",
      file: file ?? null,
      title: "",
      url: "",
    },
    mode: "all",
  })

  return (
    <form
      onSubmit={handleSubmit(onUploadVideoClick)}
      className="max-h-[inherit]"
    >
      <div className="flex flex-col max-h-[inherit]">
        {/* Form */}
        <div className="flex-1 overflow-auto px-6 py-4 flex flex-col gap-4 border-b border-tv-coal">
          <h3 className="text-title3 text-center">
            <FormattedMessage
              defaultMessage="Upload Video"
              description="Upload video form title"
            />
          </h3>
          <div className="flex flex-row gap-4">
            {/* Name */}
            <div className="flex-1">
              <div>
                <Label htmlFor="title">
                  <FormattedMessage
                    defaultMessage="Title"
                    description="Upload video form title label"
                  />
                </Label>
              </div>
              <TextField
                id="name"
                className="w-full"
                disabled={disabled}
                invalid={!!errors.title}
                maxLength={255}
                {...register("title", {
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      defaultMessage: "Title is required",
                      description: "Upload video form title required message",
                    }),
                  },
                })}
              />
              {errors.title && (
                <ValidationError>{errors.title.message!}</ValidationError>
              )}
            </div>
            {/* Channel */}
            <div className="flex-1">
              <div>
                <Label htmlFor="slug">
                  <FormattedMessage
                    defaultMessage="Channel"
                    description="Upload video form channel label"
                  />
                </Label>
              </div>
              <Controller
                name="channelId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      defaultMessage: "Channel is required",
                      description: "Upload video form channel required message",
                    }),
                  },
                }}
                render={({ field: { onBlur, onChange, value } }) => (
                  <ChannelPicker
                    channels={channels}
                    className="w-full"
                    invalid={!!errors.channelId}
                    onBlur={onBlur}
                    onChangeSelectedChannelId={newValue => {
                      onChange(newValue)
                    }}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select a channel",
                      description: "Upload video form channel placeholder",
                    })}
                    selectedChannelId={value}
                  />
                )}
              />
              {errors.channelId && (
                <ValidationError>{errors.channelId.message!}</ValidationError>
              )}
            </div>
          </div>
          <div>
            <Controller
              name="file"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DropTarget
                  file={value ?? undefined}
                  onChangeFile={newFile => {
                    onChange(newFile)
                  }}
                />
              )}
            />
          </div>
          <div className="pt-[0.5rem]">
            <div className="w-full h-[1px] bg-tv-coal" />
            <div className="-mt-[0.5rem] text-center">
              <div className="inline-block text-helper text-tv-dust bg-tv-obsidian px-4 h-[1rem]">
                <FormattedMessage
                  defaultMessage="OR"
                  description="Upload video form or label"
                />
              </div>
            </div>
          </div>
          {/* Upload from URL */}
          <div className="flex-1">
            <div>
              <Label htmlFor="url">
                <FormattedMessage
                  defaultMessage="Upload from URL"
                  description="Upload video form URL label"
                />
              </Label>
            </div>
            <TextField
              id="url"
              className="w-full"
              disabled={disabled}
              invalid={!!errors.url}
              placeholder={intl.formatMessage({
                defaultMessage: "https://www.venue.live/",
                description: "Upload video form URL placeholder",
              })}
              {...register("url", {
                required: {
                  value: !watch("file"),
                  message: intl.formatMessage({
                    defaultMessage: "URL is required",
                    description: "Upload video form URL required message",
                  }),
                },
                pattern: {
                  value: FORMAT_PATTERNS[ValidationFormat.URL],
                  message: intl.formatMessage({
                    defaultMessage: "URL must be a valid URL",
                    description: "Upload video form URL format message",
                  }),
                },
              })}
            />
            {errors.url && (
              <ValidationError>{errors.url.message!}</ValidationError>
            )}
          </div>
        </div>
        {/* Buttons */}
        <div className="px-6 py-4 flex flex-row justify-end gap-3">
          <Button
            disabled={disabled}
            label={intl.formatMessage({
              defaultMessage: "Discard",
              description: "Upload video form discard button label",
            })}
            onClick={onDiscardClick}
            theme="tertiary"
          />
          <Button
            disabled={disabled || !!Object.keys(errors).length}
            label={intl.formatMessage({
              defaultMessage: "Upload Video",
              description: "Upload video form submit button label",
            })}
            type="submit"
          />
        </div>
      </div>
    </form>
  )
}
