import { gql, useMutation, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import CreateChannelRoleForm from "../features/channel-permissions/create-channel-role-form"
import {
  CreateChannelRoleMutation,
  CreateChannelRoleMutationVariables,
  GetChannelQuery,
  GetChannelQueryVariables,
  Permission,
} from "../generated/graphql"

const QUERY = gql`
  query GetChannelPermission(
    $organizationSlug: String!
    $channelSlug: String!
  ) {
    channel(organizationSlug: $organizationSlug, channelSlug: $channelSlug) {
      id
    }
  }
`

const CREATE_CHANNEL_ROLE = gql`
  mutation CreateChannelRole(
    $channelId: String!
    $name: String!
    $description: String
    $permissions: [Permission!]!
  ) {
    createChannelRole(
      input: {
        channelId: $channelId
        name: $name
        description: $description
        permissions: $permissions
      }
    ) {
      id
    }
  }
`

export default function CreateChannelRoleScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const { organizationSlug, channelSlug } = useParams<{
    organizationSlug: string
    channelSlug: string
  }>()

  const { data: channelData } = useQuery<
    GetChannelQuery,
    GetChannelQueryVariables
  >(QUERY, {
    variables: {
      organizationSlug: organizationSlug!,
      channelSlug: channelSlug!,
    },
  })

  const [createChannelRole, { loading }] = useMutation<
    CreateChannelRoleMutation,
    CreateChannelRoleMutationVariables
  >(CREATE_CHANNEL_ROLE)

  if (!channelData) {
    return null
  }

  const {
    channel: { id: channelId },
  } = channelData

  return (
    <div className="w-screen max-w-[512px]">
      <div className="px-6 pt-6 flex flex-col gap-4 border-b border-tv-coal">
        <CreateChannelRoleForm
          disabled={loading}
          onCreateChannelRole={async newValue => {
            await createChannelRole({
              variables: {
                channelId,
                name: newValue.name,
                description: newValue.description ?? "description",
                permissions: (newValue.permissions ?? [
                  Permission.ReadChannels,
                ]) as Array<Permission>,
              },
            })
            onDiscardClick()
          }}
          onBackClickUri={`/${organizationSlug}/channels/${channelSlug}/permissions`}
        />
        {/* TODO create a multi selection component that allows picking multiple items */}
      </div>
    </div>
  )
}
