import classNames from "classnames"
import { FormattedDate, FormattedRelativeTime } from "react-intl"
import { useDurationDescription } from "../../hooks/use-duration-description"

export default function VideoResult({
  title,
  thumbnailUri,
  date,
  durationSeconds,
}: {
  title: string
  thumbnailUri?: string
  date: Date
  durationSeconds: number
}) {
  const getDurationDescription = useDurationDescription()
  const dateOffsetSeconds = Math.floor((date.valueOf() - Date.now()) / 1000)

  return (
    <div className="group flex flex-row gap-4">
      <div
        className={classNames(
          "h-[100px] relative aspect-video rounded-xl transition-transform duration-100",
          "bg-tv-coal bg-center bg-contain bg-no-repeat",
          "group-focus:outline-milk group-focus:outline-4 group-focus:outline group-hover:scale-105"
        )}
        style={{
          backgroundImage: thumbnailUri ? `url(${thumbnailUri})` : undefined,
        }}
      >
        <div className="absolute bottom-2 right-2 rounded bg-tv-midnight text-body2 px-2">
          {getDurationDescription(durationSeconds, "short")}
        </div>
        &nbsp;
      </div>
      <div className="flex flex-col">
        <div className="text-title3 font-bold mt-4 group-hover:text-tv-dandelion group-focus:text-tv-dandelion">
          {title}
        </div>
        <div className="flex flex-row items-center gap-6 mt-2">
          <div className="text-tv-dust">
            {dateOffsetSeconds < -7 * 24 * 60 * 60 ? (
              <FormattedDate
                value={date}
                year="numeric"
                month="long"
                day="numeric"
              />
            ) : (
              <FormattedRelativeTime
                value={dateOffsetSeconds}
                updateIntervalInSeconds={30}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
