import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import classNames from "classnames"
import { useParams } from "react-router-dom"
import { IdType, entityId } from "../../utils/entity-id"
import NavItemWithIcon from "./nav-item-with-icon"

export default function ChannelNavItem({
  id,
  droppable,
  logoImageUri,
  name,
  nested = false,
  slug,
  draggable,
}: {
  droppable: boolean
  id: string
  logoImageUri?: string
  name: string
  nested?: boolean
  slug: string
  draggable: boolean
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const {
    attributes,
    isDragging,
    isOver,
    active,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    disabled: { draggable: !draggable, droppable: !droppable },
  })

  const isDraggingVideo = entityId.is(String(active?.id ?? ""), IdType.Video)

  return (
    <NavItemWithIcon
      ref={setNodeRef}
      className={classNames(
        isOver && isDraggingVideo
          ? "border-tv-dandelion"
          : "border-transparent",
        nested && "ml-4"
      )}
      href={isDragging ? undefined : `/${organizationSlug}/channels/${slug}`}
      imageUri={logoImageUri}
      name={name}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      {...attributes}
      {...listeners}
    />
  )
}
