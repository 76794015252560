import { gql, useQuery } from "@apollo/client"
import _ from "lodash"
import { useRef, useState } from "react"
import { useIntl } from "react-intl"
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import SearchTextField from "../atoms/search-text-field"
import VideoResultList from "../features/search/video-result-list"
import {
  SearchVideosQuery,
  SearchVideosQueryVariables,
} from "../generated/graphql"

const QUERY = gql`
  query SearchVideos($organizationSlug: String!, $query: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      videos(
        args: { limit: 10 }
        filter: {
          includeDeleted: false
          includePreparing: false
          includeErrored: false
          includeUploading: false
        }
        query: $query
      ) {
        items {
          id
          title
          thumbnailUri
          durationSeconds
          eventStartedAt
          createdAt
        }
      }
    }
  }
`

export default function SearchScreen() {
  const intl = useIntl()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const [searchParams] = useSearchParams()
  const queryParam = searchParams.get("q") || ""
  const { data, loading } = useQuery<
    SearchVideosQuery,
    SearchVideosQueryVariables
  >(QUERY, {
    variables: { organizationSlug: organizationSlug!, query: queryParam },
  })
  const navigate = useNavigate()
  const [query, setQuery] = useState(queryParam)
  const debouncedSearch = useRef(
    _.debounce((newQuery: string) => {
      if (newQuery.length >= 3) {
        navigate(
          {
            pathname: `/${organizationSlug}/search`,
            search: `?${createSearchParams({ q: newQuery })}`,
          },
          { replace: true }
        )
      }
    }, 250)
  ).current

  const handleChangeSearchQuery = (newValue: string) => {
    debouncedSearch(newValue)
    setQuery(newValue)
  }

  return (
    <div className="w-screen max-w-[600px] flex flex-col">
      <div className="p-4">
        <SearchTextField
          onChangeText={handleChangeSearchQuery}
          placeholder={intl.formatMessage({
            defaultMessage: "Search videos by title",
            description: "Search bar placeholder",
          })}
          value={query}
          divClassName="w-full"
        />
      </div>
      <div className="flex-1 overflow-y-scroll">
        {loading && <div>Loading...</div>}
        {!loading && (
          <div className="overflow-y-scroll px-4 pb-4">
            <VideoResultList
              results={(data?.organization.videos.items ?? []).map(video => ({
                id: video.id,
                title: video.title,
                thumbnailUri: video.thumbnailUri ?? undefined,
                date: video.eventStartedAt ?? video.createdAt,
                durationSeconds: video.durationSeconds,
              }))}
            />
          </div>
        )}
      </div>
    </div>
  )
}
