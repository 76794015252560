/* eslint-disable no-continue */
import { gql, useMutation, useQuery } from "@apollo/client"
import classNames from "classnames"
import { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import {
  MoveNavItemIntoFolderMutation,
  MoveNavItemIntoFolderMutationVariables,
  NavItem,
  ReorderNavItemMutation,
  ReorderNavItemMutationVariables,
  SideNavGetNavItemsQuery,
  SideNavGetNavItemsQueryVariables,
} from "../../generated/graphql"
import CollapsedSideNav from "./collapsed-side-nav"
import ExpandedSideNav from "./expanded-side-nav"
import { SideNavContext } from "./side-nav-provider"

export const NAV_ITEMS_FRAGMENT = gql`
  fragment NavItems on Organization {
    navItems {
      ... on ChannelNavItem {
        id
        name
        slugs
        logoImageUri
      }
      ... on ChannelFolderNavItem {
        id
        name
        children {
          ... on ChannelNavItem {
            id
            name
            slugs
            logoImageUri
          }
          ... on ChannelFolderNavItem {
            id
          }
        }
      }
    }
  }
`

const GET_NAV_ITEMS = gql`
  ${NAV_ITEMS_FRAGMENT}
  query SideNavGetNavItems($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      logoImageUri
      ...NavItems
    }
  }
`

const MOVE_NAV_ITEM_INTO_FOLDER = gql`
  ${NAV_ITEMS_FRAGMENT}
  mutation MoveNavItemIntoFolder($navItemId: String!, $parentFolderId: String) {
    moveNavItemIntoFolder(
      input: { navItemId: $navItemId, parentFolderId: $parentFolderId }
    ) {
      id
      ...NavItems
    }
  }
`

const REORDER_NAV_ITEM = gql`
  ${NAV_ITEMS_FRAGMENT}
  mutation ReorderNavItem($navItemId: String!, $beforeNavItemId: String) {
    reorderNavItem(
      input: { navItemId: $navItemId, beforeNavItemId: $beforeNavItemId }
    ) {
      id
      ...NavItems
    }
  }
`

export default function SideNav() {
  const { isExpanded: isExpandedByContext, isInitiallyExpanded } =
    useContext(SideNavContext)
  const [isExpandedByUser, setExpandedByUser] = useState(false)
  const { organizationSlug } = useParams<{ organizationSlug: string }>()

  const { data: organizationData } = useQuery<
    SideNavGetNavItemsQuery,
    SideNavGetNavItemsQueryVariables
  >(GET_NAV_ITEMS, {
    variables: { organizationSlug: organizationSlug! },
  })

  const [runReorderNavItemMutation] = useMutation<
    ReorderNavItemMutation,
    ReorderNavItemMutationVariables
  >(REORDER_NAV_ITEM)
  const [runMoveNavItemIntoFolderMutation] = useMutation<
    MoveNavItemIntoFolderMutation,
    MoveNavItemIntoFolderMutationVariables
  >(MOVE_NAV_ITEM_INTO_FOLDER)

  const getNavItemAndParentId = (navItemId: string) =>
    organizationData!.organization.navItems.reduce<
      [
        SideNavGetNavItemsQuery["organization"]["navItems"][0] | null,
        string | null
      ]
    >(
      (current, navItem) => {
        if (current[0] !== null) {
          return current
        }
        if (navItem.id === navItemId) {
          return [navItem, null]
        }
        if (navItem.__typename === "ChannelFolderNavItem") {
          for (const child of navItem.children) {
            if (
              child.__typename === "ChannelNavItem" &&
              child.id === navItemId
            ) {
              return [child, navItem.id]
            }
          }
        }
        return [null, null]
      },
      [null, null]
    )

  const handleMoveNavItemIntoFolder = async (
    navItemId: string,
    parentFolderId: string | null
  ) => {
    const [movingNavItem, oldParentId] = getNavItemAndParentId(navItemId)

    if (!movingNavItem) {
      return
    }

    let newNavItems: SideNavGetNavItemsQuery["organization"]["navItems"]
    if (oldParentId === parentFolderId) {
      newNavItems = [...organizationData!.organization.navItems]
    } else {
      newNavItems = []
      for (const navItem of organizationData!.organization.navItems) {
        // If nav item is the moving nav item, skip it
        if (navItem.id === movingNavItem.id) {
          continue
        }

        if (
          navItem.__typename === "ChannelFolderNavItem" &&
          navItem.id === parentFolderId
        ) {
          // If nav item is the new parent folder, add moving nav item to children
          const { children, ...otherFields } = navItem
          const folderNavItem = {
            ...otherFields,
            children: [...children, movingNavItem] as typeof children,
          }
          newNavItems.push(folderNavItem)
        } else {
          // If nav item is not the new parent folder, add it
          newNavItems.push(navItem)
        }
      }

      // If moving nav item to the root folder, add it
      if (!parentFolderId) {
        newNavItems.push(movingNavItem)
      }
    }

    await runMoveNavItemIntoFolderMutation({
      variables: { navItemId, parentFolderId },
      optimisticResponse: {
        moveNavItemIntoFolder: {
          __typename: "Organization",
          id: organizationData!.organization.id,
          navItems: newNavItems,
        },
      },
    })
  }

  const handleReorderNavItem = async (
    navItemId: string,
    beforeNavItemId: string | null
  ) => {
    const [movingNavItem] = getNavItemAndParentId(navItemId)

    if (!movingNavItem) {
      return
    }

    const newNavItems: SideNavGetNavItemsQuery["organization"]["navItems"] = []
    for (const navItem of organizationData!.organization.navItems) {
      // If nav item is the moving nav item, skip it
      if (navItem.id === movingNavItem.id) {
        continue
      }

      // If nav item ID is beforeNavItemId, add moving nav item before nav item
      if (navItem.id === beforeNavItemId) {
        newNavItems.push(movingNavItem)
        continue
      }

      if (navItem.__typename === "ChannelFolderNavItem") {
        // If nav item is a folder, add it with empty children
        const { children, ...otherFields } = navItem
        const folderNavItem = {
          ...otherFields,
          children: [] as typeof children,
        }
        newNavItems.push(folderNavItem)

        for (const childNavItem of children) {
          // If child nav item ID is the one we're removing, skip it
          if (childNavItem.id === movingNavItem.id) {
            continue
          }

          // If child ID is beforeNavItemId, insert moving nav item before child
          if (childNavItem.id === beforeNavItemId) {
            folderNavItem.children.push(movingNavItem)
            continue
          }

          // Add child
          folderNavItem.children.push(childNavItem)
        }
      } else {
        // If nav item is not a folder, add it
        newNavItems.push(navItem)
      }
    }

    // If moving nav item to end of list, add it
    if (!beforeNavItemId) {
      newNavItems.push(movingNavItem)
    }

    await runReorderNavItemMutation({
      variables: { navItemId, beforeNavItemId },
      optimisticResponse: {
        reorderNavItem: {
          __typename: "Organization",
          id: organizationData!.organization.id,
          navItems: newNavItems,
        },
      },
    })
  }

  const isExpanded = isExpandedByContext || isExpandedByUser
  const navItems = (organizationData?.organization.navItems ||
    []) as Array<NavItem>
  const organizationLogoImageUri =
    organizationData?.organization.logoImageUri ?? undefined

  return isInitiallyExpanded ? (
    // When initially expanded, show expanded side nav beside content.
    <ExpandedSideNav
      navItems={navItems}
      onCollapseClick={() => setExpandedByUser(false)}
      onMoveNavItemIntoFolder={handleMoveNavItemIntoFolder}
      onReorderNavItem={handleReorderNavItem}
      organizationLogoImageUri={organizationLogoImageUri}
    />
  ) : (
    // When not initially expanded, show collapsed side nav beside content.
    // When expanded by context or pointer, show expanded side nav over top of content.
    <div
      className="h-full"
      onMouseEnter={() => setExpandedByUser(true)}
      onMouseLeave={() => setExpandedByUser(false)}
    >
      <CollapsedSideNav
        navItems={navItems}
        onExpandClick={() => setExpandedByUser(true)}
        organizationLogoImageUri={organizationLogoImageUri}
      />
      <div
        className={classNames(
          "absolute overflow-hidden left-0 top-0 bottom-0 transition-all duration-150 z-30",
          isExpanded ? "w-[290px]" : "w-0" // width is wider than expanded side nav to allow for drop-shadow
        )}
      >
        <div className="relative h-full drop-shadow-[2px_0px_3px_rgba(0,0,0,0.3)]">
          <ExpandedSideNav
            navItems={navItems}
            onCollapseClick={() => setExpandedByUser(false)}
            onMoveNavItemIntoFolder={handleMoveNavItemIntoFolder}
            onReorderNavItem={handleReorderNavItem}
            organizationLogoImageUri={organizationLogoImageUri}
          />
        </div>
      </div>
    </div>
  )
}
