import toast from "react-hot-toast"
import { useIntl } from "react-intl"
import Button from "../../atoms/button"
import { IconType } from "../../atoms/icon/types"
import { TrackEventName, trackEvent } from "../../track-event"
import { copyToClipboard } from "../../utils/copy-to-clipboard"
import ShareChannelToast from "./share-channel-toast"

export const handleCopyChannel = async () => {
  const url = window.location.href.split("?")[0]
  await copyToClipboard(url)

  trackEvent({
    name: TrackEventName.LinkShared,
    attributes: { type: "channel" },
  })

  toast(t => <ShareChannelToast onDismissClick={() => toast.dismiss(t.id)} />, {
    id: "share-channel",
  })
}

export default function CopyChannelLinkButton() {
  const intl = useIntl()

  return (
    <Button
      theme="primary"
      icon={IconType.Link}
      label={intl.formatMessage({
        defaultMessage: "Share",
        description: "Share channel button label",
      })}
      onClick={handleCopyChannel}
    />
  )
}
