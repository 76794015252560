import { FormattedMessage } from "react-intl"
import Button from "../../atoms/button"
import { IconType } from "../../atoms/icon/types"

export default function ShareChannelToast({
  onDismissClick,
}: {
  onDismissClick: () => void
}) {
  return (
    <div className="flex items-center gap-4">
      <div className="font-semibold">
        <FormattedMessage
          defaultMessage="Link copied"
          description="Share channel toast message"
        />
      </div>
      <Button
        compact
        icon={IconType.Close}
        onClick={onDismissClick}
        theme="tertiary"
      />
    </div>
  )
}
