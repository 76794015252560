import { gql, useQuery } from "@apollo/client"
import { ReactNode, useContext } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { IconType } from "../../atoms/icon/types"
import Link from "../../atoms/link"
import {
  Permission,
  SettingsLayoutGetOrganizationQuery,
  SettingsLayoutGetOrganizationQueryVariables,
} from "../../generated/graphql"
import { UserContext } from "../../user-provider"
import { getWorkspaceSettingsDashboardUrl } from "../../utils/dashboard-urls"
import NavItem from "./nav-item"

const GET_CHANNELS = gql`
  query SettingsLayoutGetOrganization($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      logoImageUri
    }
  }
`

export default function SettingsLayout({
  children,
  selectedNavItem = "general",
}: {
  children?: ReactNode
  selectedNavItem?: "general" | "team"
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const intl = useIntl()
  const { hasPermission } = useContext(UserContext)
  const { data } = useQuery<
    SettingsLayoutGetOrganizationQuery,
    SettingsLayoutGetOrganizationQueryVariables
  >(GET_CHANNELS, {
    variables: { organizationSlug: organizationSlug! },
  })

  if (!data?.organization) {
    return null
  }

  return (
    <div className="flex flex-row w-screen max-w-[1000px] h-full">
      <div className="basis-[250px] bg-tv-coal">
        <div className="p-6">
          <div className="text-subhead4 text-tv-beast mb-2">Workspace</div>
          <div className="flex flex-col gap-1">
            {hasPermission(Permission.UpdateOrganization) && (
              <Link to={`/${organizationSlug}/edit`}>
                <NavItem
                  label={intl.formatMessage({
                    defaultMessage: "General",
                    description: "Settings menu general item label",
                  })}
                  imageUri={data.organization.logoImageUri ?? undefined}
                  selected={selectedNavItem === "general"}
                />
              </Link>
            )}
            {hasPermission(Permission.ReadWritePermissions) && (
              <Link to={`/${organizationSlug}/team`}>
                <NavItem
                  icon={IconType.User}
                  label={intl.formatMessage({
                    defaultMessage: "Team",
                    description: "Settings menu team item label",
                  })}
                  selected={selectedNavItem === "team"}
                />
              </Link>
            )}
            {hasPermission(Permission.WriteLiveEvents) && (
              <Link
                to={getWorkspaceSettingsDashboardUrl(organizationSlug!)}
                target="_blank"
              >
                <NavItem
                  icon={IconType.Settings}
                  label={intl.formatMessage({
                    defaultMessage: "More settings",
                    description: "Settings menu more settings item label",
                  })}
                />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="flex-1">{children}</div>
    </div>
  )
}
