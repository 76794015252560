import { gql, useMutation, useQuery } from "@apollo/client"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Button from "../atoms/button"
import Label from "../atoms/label"
import TextField from "../atoms/text-field"
import ValidationError from "../atoms/validation-error"
import {
  CreateChannelFolderGetOrganizationQuery,
  CreateChannelFolderGetOrganizationQueryVariables,
  CreateChannelFolderMutation,
  CreateChannelFolderMutationVariables,
} from "../generated/graphql"

const QUERY = gql`
  query CreateChannelFolderGetOrganization($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
    }
  }
`

const CREATE_FOLDER = gql`
  mutation CreateChannelFolder($organizationId: String!, $name: String!) {
    createChannelFolder(
      input: { organizationId: $organizationId, name: $name }
    ) {
      id
    }
  }
`

export default function CreateChannelFolderScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    CreateChannelFolderGetOrganizationQuery,
    CreateChannelFolderGetOrganizationQueryVariables
  >(QUERY, {
    variables: {
      organizationSlug: organizationSlug!,
    },
  })
  const [runCreateFolderMutation] = useMutation<
    CreateChannelFolderMutation,
    CreateChannelFolderMutationVariables
  >(CREATE_FOLDER)

  const intl = useIntl()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    values: {
      name: "",
    },
    mode: "all",
  })

  const handleFormValid = async (formData: { name: string }) => {
    await runCreateFolderMutation({
      variables: {
        organizationId: data!.organization.id,
        name: formData.name,
      },
      update: cache => {
        // Delete all cached channels on organization (force side nav to reload)
        cache.modify({
          id: cache.identify(data!.organization),
          fields: {
            navItems(_, { DELETE }) {
              return DELETE
            },
            channels(_, { DELETE }) {
              return DELETE
            },
          },
        })
      },
    })

    onDiscardClick()
  }

  return (
    <div className="w-screen max-w-[512px]">
      <form onSubmit={handleSubmit(handleFormValid)}>
        <div className="px-6 py-4 flex flex-col gap-4 border-b border-tv-coal">
          <h3 className="text-title3 text-center">
            <FormattedMessage
              defaultMessage="Create Channel Folder"
              description="Create channel folder form title"
            />
          </h3>
          {/* Name */}
          <div>
            <div>
              <Label htmlFor="name">
                <FormattedMessage
                  defaultMessage="Name"
                  description="Create channel form name label"
                />
              </Label>
            </div>
            <TextField
              id="name"
              className="w-full"
              disabled={isSubmitting}
              invalid={!!errors.name}
              maxLength={255}
              placeholder={intl.formatMessage({
                defaultMessage: "My Channels",
                description: "Create channel form name placeholder",
              })}
              {...register("name", {
                required: {
                  value: true,
                  message: intl.formatMessage({
                    defaultMessage: "Name is required",
                    description: "Create channel form name required message",
                  }),
                },
              })}
            />
            {errors.name && (
              <ValidationError>{errors.name.message!}</ValidationError>
            )}
          </div>
        </div>
        {/* Buttons */}
        <div className="px-6 py-4 flex flex-row justify-end gap-3">
          <Button
            disabled={isSubmitting}
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              description: "Create channel folder cancel button label",
            })}
            onClick={onDiscardClick}
            theme="tertiary"
          />
          <Button
            disabled={isSubmitting || !!Object.keys(errors).length}
            label={intl.formatMessage({
              defaultMessage: "Create Folder",
              description: "Create channel folder submit button label",
            })}
            type="submit"
          />
        </div>
      </form>
    </div>
  )
}
