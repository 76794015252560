import { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { useLoginWithOAuth } from "../features/oauth-login/hooks/use-login-with-oauth"
import { UserContext } from "../user-provider"
import ErrorScreen from "./error-screen"

export default function OAuthCallbackScreen() {
  const { isLoggedIn } = useContext(UserContext)
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code") || ""
  const { redirect = "", provider = null } = JSON.parse(
    searchParams.get("state") || "{}"
  ) as {
    redirect?: string
    provider?: string
  }

  const { error } = useLoginWithOAuth({
    provider: provider as any,
    code,
    redirect,
  })

  if (isLoggedIn === true) {
    window.location.replace(redirect!)
    return null
  }

  if (error) {
    return <ErrorScreen message={error.message} />
  }

  return null
}
