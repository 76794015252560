import { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import ButtonDiv from "../../atoms/button-div"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Link from "../../atoms/link"
import SelectWithIcon, { SelectItem } from "../../atoms/select-with-icon"
import { Permission } from "../../generated/graphql"
import { OrganizationContext } from "../../organization-provider"
import { UserContext } from "../../user-provider"

function VisibilityStatus({
  public: isPublic,
  organizationName,
}: {
  public: boolean
  organizationName: string
}) {
  return isPublic ? (
    <div className="text-tv-dust">
      <Icon
        className="inline-block mr-1 mb-[2px]"
        size="small"
        type={IconType.Globe}
      />
      <FormattedMessage defaultMessage="Anyone with the link" />
    </div>
  ) : (
    <div className="text-tv-dust">
      <Icon
        className="inline-block mr-1 mb-[2px]"
        size="small"
        type={IconType.Lock}
      />
      <FormattedMessage
        defaultMessage="Only watchable by {organizationName}"
        values={{ organizationName }}
      />
    </div>
  )
}

const createDownloadUrl = (uri: string, title: string) => {
  if (uri.includes(".mp4?")) {
    return uri.replace(".mp4?", `.mp4?download=${title}&`)
  }

  return `${uri}?download=${title}`
}

export default function ShareVideoCard({
  channelId,
  isVideoPublic,
  videoTitle,
  videoMp4Uri,
  onCopyLinkClick,
  onVisibilityChange,
}: {
  channelId: string
  isVideoPublic: boolean
  videoTitle: string
  videoMp4Uri: string | null | undefined
  onCopyLinkClick: (baseUrl: string) => void
  onVisibilityChange: (value: string) => void
}) {
  const intl = useIntl()
  const { hasPermission, getFeatureFlag } = useContext(UserContext)
  const { name: organizationName } = useContext(OrganizationContext)

  const baseUrl = window.location.href.split("?")[0]

  const items: Array<SelectItem> = [
    {
      value: "private",
      icon: IconType.Lock,
      label: intl.formatMessage({ defaultMessage: "Private" }),
      description: intl.formatMessage(
        { defaultMessage: "Only {organizationName} members" },
        { organizationName }
      ),
      selectedLabel: intl.formatMessage(
        { defaultMessage: "Only watchable by {organizationName}" },
        { organizationName }
      ),
    },
    {
      value: "public",
      icon: IconType.Globe,
      label: "Public",
      description: intl.formatMessage({
        defaultMessage: "Anyone with the link",
      }),
      selectedLabel: intl.formatMessage({
        defaultMessage: "Anyone with the link",
      }),
    },
  ]

  const downloadUrl = videoMp4Uri && createDownloadUrl(videoMp4Uri, videoTitle)
  const isShareInWorkplaceEnabled =
    getFeatureFlag("show-workplace-share") === "true"

  return (
    <div className="w-full rounded-2xl bg-tv-coal p-4 flex flex-col items-center gap-2">
      <div className="font-semibold">
        <FormattedMessage
          defaultMessage="Share video"
          description="Share video title"
        />
      </div>
      <div className="flex flex-row items-center gap-2">
        <Button icon={IconType.Link} onClick={() => onCopyLinkClick(baseUrl)} />
        {isShareInWorkplaceEnabled ? (
          <a
            href={`https://work.workplace.com/sharer.php?display=page&u=${baseUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <ButtonDiv icon={IconType.Workplace} iconSize="large" />
          </a>
        ) : null}

        {downloadUrl && (
          <Link
            to={downloadUrl}
            className="font-sans font-semibold text-[14px] appearance-none rounded-lg border outline-none outline-offset-0 focus:outline focus:outline-[#f6f6f6] flex flex-row items-center justify-center gap-1 h-[40px] px-3 bg-[#f6f6f6] border-[#c8c8c8] text-tv-gunmetal hover:bg-[#f8eed1] hover:border-[#c8c8c8]"
          >
            <FormattedMessage defaultMessage="Download" />
          </Link>
        )}
      </div>
      {hasPermission(Permission.WriteVideos, channelId) ? (
        <div className="flex flex-col">
          <SelectWithIcon
            items={items}
            value={isVideoPublic ? "public" : "private"}
            onChangeValue={onVisibilityChange}
          />
        </div>
      ) : (
        <VisibilityStatus
          public={isVideoPublic}
          organizationName={organizationName!}
        />
      )}
    </div>
  )
}
