// Code verifier: Random URL-safe string with a minimum length of 43 characters.
// Code challenge: Base64 URL-encoded SHA-256 hash of the code verifier.

export const STORAGE_KEY = "pkce_code_verifier"
const VERIFIER_LENGTH = 48

const generateAndSetVerifier = () => {
  const verifier = Array.from(
    crypto.getRandomValues(new Uint8Array(VERIFIER_LENGTH / 2))
  )
    .map(number => number.toString(16).padStart(2, "0"))
    .join("")
  localStorage.setItem(STORAGE_KEY, verifier)
  return verifier
}

const base64Sha256 = async (str: string) => {
  const stringBytes = new TextEncoder().encode(str)
  const digest = await crypto.subtle.digest("SHA-256", stringBytes)
  const digestBytes = Array.from(new Uint8Array(digest))
  const chars = String.fromCharCode.apply(null, digestBytes)
  return window
    .btoa(chars)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "")
}

/**
 * Returns the current PKCE verifier.
 *
 * @returns The current PKCE verifier or an empty string.
 */
export const getPkceVerifier = () => localStorage.getItem(STORAGE_KEY) || ""

/**
 * Generates and stores a PKCE verifier, and computes and returns a challenge for that verifier.
 *
 * @returns The computed challenge.
 */
export const generatePkceVerifierAndComputeChallenge = async () =>
  base64Sha256(generateAndSetVerifier())
