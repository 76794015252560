import { gql, useQuery } from "@apollo/client"
import axios from "axios"
import jwt_decode from "jwt-decode"
import { ReactNode, createContext, useEffect, useMemo, useState } from "react"
import {
  setUserToken as authSetUserToken,
  getUserToken,
  getUserTokenClaims,
  subscribeToUserTokenChanges,
  unsubscribeFromUserTokenChanges,
} from "./auth"
import env from "./env"
import {
  Permission,
  ProviderGetPersonQuery,
  ProviderGetPersonQueryVariables,
} from "./generated/graphql"
import { getOrganizationSlug } from "./utils/get-organization-slug"

const QUERY = gql`
  query ProviderGetPerson($personId: String!) {
    node(id: $personId) {
      id
      ... on Person {
        featureFlags {
          key
          value
        }
        initials
        permissions {
          channels {
            channelId
            permissions
          }
          organizationPermissions
        }
        profileImageUri
      }
    }
  }
`

type UserContextType = {
  email: string | null
  initials: string | null
  isLoggedIn: boolean | null
  organizationId: string | null
  personId: string | null
  profileImageUri: string | null
  superAdmin: boolean
  userToken: string | null
  getFeatureFlag: (flagName: string) => string | null
  hasPermission: (permission: Permission, channelId?: string) => boolean
  logOut: () => Promise<void>
}

export const UserContext = createContext<UserContextType>({
  email: null,
  initials: null,
  isLoggedIn: null,
  organizationId: null,
  personId: null,
  profileImageUri: null,
  superAdmin: false,
  userToken: null,
  getFeatureFlag: () => {
    throw new Error("UserContext was used without provider being a parent.")
  },
  hasPermission: () => {
    throw new Error("UserContext was used without provider being a parent.")
  },
  logOut: () => {
    throw new Error("UserContext was used without provider being a parent.")
  },
})

export default function UserProvider({ children }: { children?: ReactNode }) {
  const [userToken, setUserToken] = useState(getUserToken())
  const { data } = useQuery<
    ProviderGetPersonQuery,
    ProviderGetPersonQueryVariables
  >(QUERY, {
    variables: { personId: getUserTokenClaims().personId! },
    skip: !userToken,
  })

  useEffect(() => {
    const listener = async (newUserToken: string | null | undefined) => {
      setUserToken(newUserToken)
    }

    subscribeToUserTokenChanges(listener)

    listener(getUserToken())

    return () => {
      unsubscribeFromUserTokenChanges(listener)
    }
  }, [])

  const value = useMemo(() => {
    const {
      aud: organizationId = null,
      email = null,
      sub: personId = null,
      superAdmin = false,
    } = userToken
      ? jwt_decode<{
          aud?: string
          email?: string
          sub?: string
          superAdmin?: boolean
        }>(userToken)
      : {}
    const person = data?.node.__typename === "Person" ? data.node : null

    return {
      email,
      initials: person?.initials ?? null,
      isLoggedIn: userToken === undefined ? null : !!userToken,
      organizationId,
      personId,
      profileImageUri: person?.profileImageUri ?? null,
      superAdmin,
      userToken: userToken ?? null,
      getFeatureFlag: (flagName: string) =>
        person?.featureFlags.find(flag => flag.key === flagName)?.value ?? null,
      hasPermission: (permission: Permission, channelId?: string) => {
        if (superAdmin) {
          return true
        }

        if (!person) {
          return false
        }

        if (channelId) {
          const channel = person.permissions?.channels.find(
            it => it.channelId === channelId
          )
          if (channel) {
            return channel.permissions.includes(permission)
          }
        }

        return (
          person.permissions?.organizationPermissions.includes(permission) ??
          false
        )
      },
      logOut: async () => {
        const response = await axios.post<{ success: boolean }>(
          `${env.apiBaseUri()}auth/logout`,
          { organizationSlug: getOrganizationSlug() },
          { withCredentials: true }
        )

        if (response.data.success) {
          authSetUserToken(null)
        }
      },
    }
  }, [userToken, data])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
