import { gql, useMutation, useQuery } from "@apollo/client"
import type MuxPlayerElement from "@mux/mux-player"
import MuxPlayer from "@mux/mux-player-react"
import _ from "lodash"
import { useContext, useRef, useState } from "react"
import toast from "react-hot-toast"
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from "react-intl"
import { useParams, useSearchParams } from "react-router-dom"
import Container from "../atoms/container"
import ChannelSummary from "../features/channel-summary"
import HeaderBar from "../features/header-bar"
import ShareVideoCard from "../features/share-video-card"
import ShareVideoToast from "../features/share-video-card/share-video-toast"
import SuggestedVideos from "../features/suggested-videos"
import TranscriptionsWrapper from "../features/transcriptions/transcriptions-wrapper"
import VideoOptionsDropdown from "../features/video-options-dropdown"
import VideoTitleInlineEdit from "../features/video-title-inline-edit"
import VideoViewCount from "../features/video-view-count"
import VideoViewProps from "../features/video-view-props"
import {
  GetVideoQuery,
  GetVideoQueryVariables,
  Permission,
  UpdateVideoMutation,
  UpdateVideoMutationVariables,
  VideoDeleteVideoMutation,
  VideoDeleteVideoMutationVariables,
  VideoStatus,
  VideoToggleVideoPublicMutation,
  VideoToggleVideoPublicMutationVariables,
  VideoUndeleteVideoMutation,
  VideoUndeleteVideoMutationVariables,
} from "../generated/graphql"
import { useMeasure } from "../hooks/use-measure"
import { useNavigationExpanded } from "../hooks/use-navigation-expanded"
import { useScreenSize } from "../hooks/use-screen-size"
import { TrackEventName, trackEvent } from "../track-event"
import { UserContext } from "../user-provider"
import { copyToClipboard } from "../utils/copy-to-clipboard"
import { isNotFoundError } from "../utils/is-not-found-error"
import ErrorScreen from "./error-screen"
import LoginScreen from "./login-screen"

const PLAYBACK_RATES = new Map<number, number>([
  [100, 1],
  [125, 1.25],
  [150, 1.5],
  [175, 1.75],
  [200, 2],
])

const QUERY = gql`
  query GetVideo($videoId: String!) {
    node(id: $videoId) {
      id
      ... on Video {
        organization {
          id
          defaultPlaybackRate
        }
        channelSummary {
          id
          name
          slugs
          logoImageUri
        }
        channel {
          id
          public
          videos(args: { limit: 4 }) {
            items {
              createdAt
              durationSeconds
              eventStartedAt
              id
              public
              status
              thumbnailUri
              title
              totalViews
            }
            pageInfo {
              totalCount
            }
          }
        }
        title
        deleted
        public
        maxMp4Quality
        mp4Uri
        muxPlaybackId
        muxTokens {
          playback
          storyboard
          thumbnail
        }
        viewers(args: { limit: 20 }) {
          items {
            person {
              id
              name
              profileImageUri
              initials
            }
          }
          pageInfo {
            totalCount
          }
        }
        totalViews
        props {
          person {
            id
            name
            profileImageUri
            initials
          }
          props
        }
        eventStartedAt
        eventAttendeeCount
        status
        transcriptionStatus
        createdAt
      }
    }
  }
`

const DELETE_VIDEO = gql`
  mutation VideoDeleteVideo($videoId: String!) {
    updateVideo(input: { videoId: $videoId, deleted: true }) {
      id
      deleted
    }
  }
`

const UNDELETE_VIDEO = gql`
  mutation VideoUndeleteVideo($videoId: String!) {
    updateVideo(input: { videoId: $videoId, deleted: false }) {
      id
      deleted
    }
  }
`

const TOGGLE_VIDEO_PUBLIC = gql`
  mutation VideoToggleVideoPublic($videoId: String!, $public: Boolean!) {
    updateVideo(input: { videoId: $videoId, public: $public }) {
      id
      public
    }
  }
`

const UPDATE_VIDEO_TITLE = gql`
  mutation UpdateVideo($input: UpdateVideoInput!) {
    updateVideo(input: $input) {
      id
      title
    }
  }
`

const getPlaybackRate = (orgDefaultRate?: number, rateFromParams?: number) =>
  PLAYBACK_RATES.get(rateFromParams ?? orgDefaultRate ?? 100) ?? 1

export default function VideoScreen() {
  const intl = useIntl()
  const { isLoggedIn } = useContext(UserContext)
  const { personId, hasPermission } = useContext(UserContext)
  useNavigationExpanded(false)
  const [measureRef, { width: videoPlayerWidth }] = useMeasure()
  const playerRef = useRef<MuxPlayerElement>(null)
  const { videoId } = useParams<{ videoId: string }>()
  const [searchParams] = useSearchParams()
  const startTime = parseInt(searchParams.get("start-time") ?? "0", 10)
  const playbackRateFromParams = /^\d+$/.test(searchParams.get("rate") ?? "")
    ? parseInt(searchParams.get("rate")!, 10)
    : undefined
  const screenSize = useScreenSize()
  const [currentTimeMs, setCurrentTimeMs] = useState(0)

  const { data, error } = useQuery<GetVideoQuery, GetVideoQueryVariables>(
    QUERY,
    {
      skip: !videoId,
      variables: {
        videoId: `vid-${videoId!}`,
      },
    }
  )

  const [runDeleteMutation] = useMutation<
    VideoDeleteVideoMutation,
    VideoDeleteVideoMutationVariables
  >(DELETE_VIDEO)

  const [runUndeleteMutation] = useMutation<
    VideoUndeleteVideoMutation,
    VideoUndeleteVideoMutationVariables
  >(UNDELETE_VIDEO)

  const [runToggleVideoPublicMutation] = useMutation<
    VideoToggleVideoPublicMutation,
    VideoToggleVideoPublicMutationVariables
  >(TOGGLE_VIDEO_PUBLIC)

  const [runUpdateVideoTitleMutation] = useMutation<
    UpdateVideoMutation,
    UpdateVideoMutationVariables
  >(UPDATE_VIDEO_TITLE)

  if (error) {
    if (isNotFoundError(error)) {
      return isLoggedIn ? (
        <ErrorScreen
          message={intl.formatMessage({ defaultMessage: "Video not found" })}
        />
      ) : (
        <LoginScreen />
      )
    }

    return <ErrorScreen />
  }

  const video = data?.node.__typename === "Video" ? data?.node : null

  if (!video) {
    return null
  }

  const canWriteVideos = hasPermission(
    Permission.WriteVideos,
    video.channelSummary.id
  )

  const currentTimeSeconds = Math.floor(playerRef?.current?.currentTime || 0)

  const handleGenerateUrl = (baseUrl: string) =>
    currentTimeSeconds > 0
      ? `${baseUrl}?start-time=${currentTimeSeconds}`
      : baseUrl

  const handleCopyLinkClick = async (baseUrl: string) => {
    const url = handleGenerateUrl(baseUrl)
    await copyToClipboard(url)

    trackEvent({
      name: TrackEventName.LinkShared,
      attributes: { timestamped: currentTimeSeconds > 0, type: "video" },
    })

    toast(
      t => (
        <ShareVideoToast
          currentTimeSeconds={currentTimeSeconds}
          onCopyWithoutTimestampClick={async () => {
            await copyToClipboard(baseUrl)
          }}
          onDismissClick={() => toast.dismiss(t.id)}
        />
      ),
      {
        id: "share-video",
      }
    )
  }

  const handleDeleteVideoClick = async () => {
    await runDeleteMutation({ variables: { videoId: video.id } })
  }

  const handleUndeleteVideoClick = async () => {
    await runUndeleteMutation({ variables: { videoId: video.id } })
  }

  const handleVisibilityChange = async (value: string) => {
    const isPublic = value === "public"
    await runToggleVideoPublicMutation({
      variables: { videoId: video.id, public: isPublic },
    })
    trackEvent({
      name: TrackEventName.VideoVisibilityChanged,
      attributes: { public: isPublic },
    })
  }

  const handleTitleChange = async (title: string) => {
    await runUpdateVideoTitleMutation({
      variables: { input: { videoId: video.id, title } },
    })
  }

  const handlePlay = () => {
    trackEvent({
      name: TrackEventName.VideoPlayerPlayed,
      attributes: { videoId: videoId! },
    })
  }

  const handlePause = () => {
    trackEvent({
      name: TrackEventName.VideoPlayerPaused,
      attributes: { videoId: videoId! },
    })
  }

  const handleTimeUpdate = _.throttle((event: any) => {
    setCurrentTimeMs(Math.floor(event.target!.currentTime * 1000))
  }, 1000)

  const handleChangeTime = (timeMs: number) => {
    if (playerRef.current) {
      playerRef.current.currentTime = Math.floor(timeMs / 1000)
    }
  }

  const videoDate = video.eventStartedAt ?? video.createdAt
  let videoPlayerHeight: number
  const isSmallOrMediumScreen = screenSize === "sm" || screenSize === "md"
  if (isSmallOrMediumScreen) {
    videoPlayerHeight = Math.max(0, Math.ceil((videoPlayerWidth / 16) * 9))
  } else {
    videoPlayerHeight = Math.max(
      0,
      Math.ceil(((videoPlayerWidth - 316) / 16) * 9)
    )
  }

  const showPlayer = !!video.muxPlaybackId && video.status === VideoStatus.Ready
  const videoElement = showPlayer ? (
    <div className="overflow-hidden rounded-xl">
      <MuxPlayer
        defaultHiddenCaptions
        streamType="on-demand"
        playbackId={video.muxPlaybackId!}
        metadata={{
          video_id: video.id,
          video_title: video.title,
          viewer_user_id: personId,
        }}
        onPlay={handlePlay}
        onPause={handlePause}
        onTimeUpdate={handleTimeUpdate}
        playbackRate={getPlaybackRate(
          video.organization?.defaultPlaybackRate,
          playbackRateFromParams
        )}
        ref={playerRef}
        startTime={startTime}
        style={{ height: videoPlayerHeight }}
        tokens={{
          ...(video.muxTokens ?? {}),
        }}
      />
    </div>
  ) : (
    <div className="bg-tv-coal aspect-video rounded-xl flex items-center justify-center">
      {video.status === VideoStatus.Preparing && (
        <FormattedMessage defaultMessage="Preparing..." />
      )}
      {video.status === VideoStatus.Uploading && (
        <FormattedMessage defaultMessage="Uploading..." />
      )}
      {video.status === VideoStatus.Errored && (
        <FormattedMessage defaultMessage="Errored" />
      )}
    </div>
  )

  return (
    <Container>
      <HeaderBar channelLinkSlug={video.channelSummary.slugs[0]} />
      <div ref={measureRef} className="flex flex-row gap-4">
        <div className="flex-1">{videoElement}</div>
        {!isSmallOrMediumScreen && (
          <div className="w-[300px]" style={{ height: videoPlayerHeight }}>
            <TranscriptionsWrapper
              maxMp4Quality={video!.maxMp4Quality ?? undefined}
              onChangeTime={handleChangeTime}
              timeMs={currentTimeMs}
              transcriptionStatus={video!.transcriptionStatus ?? undefined}
              videoId={video.id}
            />
          </div>
        )}
      </div>
      <div className="mt-6 flex flex-col lg:flex-row gap-6 justify-between">
        <div className="flex-1">
          <div className="flex flex-row justify-between gap-6">
            {canWriteVideos ? (
              <VideoTitleInlineEdit
                title={video.title}
                onTitleChange={handleTitleChange}
              />
            ) : (
              <h1 className="text-title1">{video.title}</h1>
            )}
            {canWriteVideos && (
              <VideoOptionsDropdown
                isDeleted={video.deleted}
                onDeleteVideoClick={handleDeleteVideoClick}
                onUndeleteVideoClick={handleUndeleteVideoClick}
              />
            )}
          </div>
          <div className="mt-2 flex flex-row justify-start gap-6 text-tv-dust">
            <div>
              <FormattedMessage
                defaultMessage="{date} at {time}"
                values={{
                  date: (
                    <FormattedDate
                      value={videoDate}
                      month="long"
                      day="numeric"
                    />
                  ),
                  time: <FormattedTime value={videoDate} />,
                }}
              />
            </div>
            {video.eventAttendeeCount !== null && (
              <div>
                <FormattedMessage
                  defaultMessage="{attendeeCount, plural, one {# attendee} other {# attendees}}"
                  description="Attendee count on video screen"
                  values={{
                    attendeeCount: video.eventAttendeeCount,
                  }}
                />
              </div>
            )}
          </div>
          {isSmallOrMediumScreen && (
            <div style={{ height: videoPlayerHeight }}>
              <TranscriptionsWrapper
                maxMp4Quality={video!.maxMp4Quality ?? undefined}
                onChangeTime={handleChangeTime}
                timeMs={currentTimeMs}
                transcriptionStatus={video!.transcriptionStatus ?? undefined}
                videoId={video.id}
              />
            </div>
          )}
          <div className="mt-2 flex flex-row justify-between items-center">
            <ChannelSummary
              episodeCount={video.channel?.videos.pageInfo.totalCount}
              name={video.channelSummary.name}
              slug={video.channelSummary.slugs[0]}
              logoImageUri={video.channelSummary.logoImageUri ?? undefined}
            />
            <div className="flex flex-row gap-2">
              <VideoViewCount
                totalViews={video.totalViews}
                viewers={video.viewers}
              />
              <VideoViewProps
                items={video.props.map(prop => ({
                  initials: prop.person.initials || "",
                  name: prop.person.name || "",
                  profileImageUri: prop.person.profileImageUri ?? undefined,
                  props: prop.props,
                }))}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-max">
          <ShareVideoCard
            channelId={video.channelSummary.id}
            isVideoPublic={video.public}
            videoTitle={video.title}
            videoMp4Uri={video.mp4Uri}
            onCopyLinkClick={handleCopyLinkClick}
            onVisibilityChange={handleVisibilityChange}
          />
        </div>
      </div>
      {!!video.channel?.videos.items.length &&
        video.channel?.videos.items.length > 1 && (
          <div className="mt-6">
            <SuggestedVideos
              channelName={video.channelSummary.name}
              videos={video.channel.videos.items
                .filter(vid => vid.id !== video.id)
                .map(vid => ({
                  date: vid.eventStartedAt ?? vid.createdAt,
                  durationSeconds: vid.durationSeconds,
                  id: vid.id,
                  isChannelPublic: video.channel!.public,
                  isVideoPublic: vid.public,
                  status: vid.status,
                  thumbnailUri: vid.thumbnailUri ?? undefined,
                  title: vid.title,
                  viewCount: vid.totalViews,
                }))}
            />
          </div>
        )}
    </Container>
  )
}
