import { flip, offset, preventOverflow } from "@popperjs/core"
import classNames from "classnames"
import { useSelect } from "downshift"
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  useState,
} from "react"
import { usePopper } from "react-popper"
import { ChevronSvgBg } from "../../types"
import { mergeRefs } from "../../utils/merge-refs"
import Icon from "../icon"
import { IconType } from "../icon/types"

export type ItemType = {
  initials?: string
  name?: string
  profileImageUri?: string
  actionCounter?: string
}

function PersonTile({
  imageUri,
  initials,
  overlapping = false,
}: {
  imageUri?: string
  initials: string
  overlapping?: boolean
}) {
  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-center",
        "w-[24px] h-[24px] rounded-full",
        "border-2 border-[#292929]",
        "bg-gray-500 bg-center bg-cover",
        "text-xs",
        overlapping && "-ml-[8px]"
      )}
      style={{
        backgroundImage: imageUri ? `url(${imageUri})` : undefined,
      }}
    >
      {!imageUri && initials}
    </div>
  )
}

const PeopleDropdown = forwardRef<
  HTMLButtonElement,
  {
    anonymousName: string
    items: Array<ItemType>
    unlistedViewersLabel?: string
    dropdownIcon?: IconType
    dropdownLabel: string
  } & Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "children"
  >
>(
  (
    {
      anonymousName = "Anonymous",
      className,
      disabled,
      items,
      style,
      unlistedViewersLabel,
      dropdownIcon,
      dropdownLabel,
      ...props
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] =
      useState<HTMLButtonElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

    // Sort viewers so that anonymous views are last.
    const sortedItems = [...items].sort((a, b) => {
      const aIsAnonymous = !a.name && !a.initials
      const bIsAnonymous = !b.name && !b.initials
      if (aIsAnonymous !== bIsAnonymous) return bIsAnonymous ? -1 : 1
      return items.indexOf(a) - items.indexOf(b)
    })

    const {
      styles,
      attributes,
      update: updatePopper,
    } = usePopper(referenceElement, popperElement, {
      placement: "top",
      modifiers: [
        preventOverflow,
        flip,
        {
          ...offset,
          options: {
            offset: [0, 8],
          },
        },
      ],
    })

    const { isOpen, getToggleButtonProps, getMenuProps } = useSelect<ItemType>({
      items,
      itemToString: it => it?.name ?? "",
      onIsOpenChange: evt => {
        if (evt.isOpen) {
          updatePopper?.()
        }
      },
    })

    return (
      <>
        <button
          className={classNames(
            "appearance-none m-0 pl-[10px] h-[40px]",
            "flex flex-row items-center gap-[8px]",
            "bg-tv-coal bg-no-repeat bg-right",
            "border border-tv-coal",
            "outline-none outline-offset-0 focus:outline focus:outline-tv-milk",
            "text-[14px] leading-none",
            "rounded-lg",
            disabled ? "text-[#5e626a]" : "text-tv-milk",
            items.length ? "pr-[28px]" : "pr-[10px]",
            className
          )}
          disabled={disabled}
          style={{
            ...(items.length && isOpen
              ? {
                  backgroundImage: `url("data:image/svg+xml;utf8,${ChevronSvgBg.Up}")`,
                }
              : {}),
            ...(items.length && !isOpen
              ? {
                  backgroundImage: `url("data:image/svg+xml;utf8,${ChevronSvgBg.Down}")`,
                }
              : {}),
            ...style,
          }}
          type="button"
          {...getToggleButtonProps(
            { ref: mergeRefs([ref, setReferenceElement]) },
            { suppressRefError: true }
          )}
          {...props}
        >
          {dropdownIcon ? (
            <Icon size="medium" type={dropdownIcon} />
          ) : (
            !!sortedItems.length && (
              <div className="flex flex-row pl-[8px]">
                {sortedItems.slice(0, 3).map((item, index) => (
                  <PersonTile
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    imageUri={item.profileImageUri ?? undefined}
                    initials={item.initials!}
                    overlapping
                  />
                ))}
              </div>
            )
          )}
          <span className="text-inherit">{dropdownLabel}</span>
        </button>
        <ul
          className={classNames(
            "bg-tv-coal p-2 rounded-lg z-10 max-w-[15rem]",
            (!isOpen || !items.length) && "hidden"
          )}
          style={styles.popper}
          {...getMenuProps(
            { ref: setPopperElement },
            { suppressRefError: true }
          )}
          {...attributes.popper}
        >
          {isOpen && (
            <>
              {sortedItems.map((item, index) => (
                <li
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={classNames(
                    "px-2 py-1",
                    "flex flex-row items-center gap-[8px]",
                    "text-[14px] leading-none text-tv-dust"
                  )}
                >
                  <PersonTile
                    imageUri={item.profileImageUri ?? undefined}
                    initials={item.initials! || "AN"}
                  />
                  <span className="truncate">{item.name || anonymousName}</span>
                  {!!item.actionCounter && (
                    <span className="whitespace-nowrap">
                      {item.actionCounter}
                    </span>
                  )}
                </li>
              ))}
              {!!unlistedViewersLabel && (
                <li className="px-2 pt-[8px] text-[14px] text-tv-dust">
                  {unlistedViewersLabel}
                </li>
              )}
            </>
          )}
        </ul>
      </>
    )
  }
)

PeopleDropdown.displayName = "VideoViewers"
export default PeopleDropdown
