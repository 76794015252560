import { useEffect, useState } from "react"
import { MessageFormatElement } from "react-intl"
import { getLocale } from "../utils/get-locale"

const locale = getLocale()
const TRANSLATION_LOADERS = {
  en: () => import("../generated/lang/en.json"),
  fr: () => import("../generated/lang/fr.json"),
}

export const useIntlMessages = () => {
  const [messageState, setMessageState] = useState<{
    isLoading: boolean
    messages:
      | Record<string, string>
      | Record<string, Array<MessageFormatElement>>
  }>({ isLoading: true, messages: {} })

  useEffect(() => {
    const loadMessages = async () => {
      const { default: messages } = await TRANSLATION_LOADERS[locale]()
      setMessageState({ isLoading: false, messages })
    }

    loadMessages()
  }, [])

  return messageState
}
