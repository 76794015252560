import env from "../env"

const getBaseUri = (organizationSlug: string) =>
  `${env.dashboardBaseUri()}organizations/${organizationSlug}`

/**
 * Returns the dashboard URL for creating a event for a given organization.
 */
export const getCreateEventDashboardUrl = (organizationSlug: string) =>
  `${getBaseUri(organizationSlug)}/builder/new/details?type=single`

/**
 * Returns the dashboard URL for listing upcoming events for a given organization.
 */
export const getUpcomingEventsDashboardUrl = (organizationSlug: string) =>
  `${getBaseUri(organizationSlug)}/manage/events`

/**
 * Returns the dashboard URL for workspace settings for a given organization.
 */
export const getWorkspaceSettingsDashboardUrl = (organizationSlug: string) =>
  `${getBaseUri(organizationSlug)}/manage/workspace-settings/settings`
