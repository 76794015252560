import {
  DndContext,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToWindowEdges, snapCenterToCursor } from "@dnd-kit/modifiers"
import { useContext, useEffect } from "react"
import { IntlProvider } from "react-intl"
import { useLocation } from "react-router-dom"
import DragOverlay from "./features/drag-and-drop/drag-overlay"
import SideNavProvider from "./features/side-nav/side-nav-provider"
import CustomToaster from "./features/toast/custom-toaster"
import VideoUploadProvider from "./features/video-upload/video-upload-provider"
import { useIntlMessages } from "./hooks/use-intl-messages"
import LoggedInRouter from "./logged-in-router"
import LoggedOutRouter from "./logged-out-router"
import { TrackEventName, trackEvent } from "./track-event"
import { UserContext } from "./user-provider"
import { getLocale } from "./utils/get-locale"

const locale = getLocale()

export default function App() {
  const { isLoggedIn } = useContext(UserContext)
  const { isLoading: areMessagesLoading, messages } = useIntlMessages()
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 24 } })
  )
  const location = useLocation()

  useEffect(() => {
    trackEvent({ name: TrackEventName.PageViewed })
  }, [location])

  if (areMessagesLoading || isLoggedIn === null) {
    return null
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      <SideNavProvider>
        <VideoUploadProvider>
          <DndContext
            collisionDetection={pointerWithin}
            modifiers={[snapCenterToCursor, restrictToWindowEdges]}
            sensors={sensors}
          >
            {isLoggedIn ? <LoggedInRouter /> : <LoggedOutRouter />}
            <DragOverlay />
          </DndContext>
        </VideoUploadProvider>
        <CustomToaster
          position="bottom-center"
          reverseOrder={false}
          toastOptions={{ duration: Number.POSITIVE_INFINITY }}
        />
      </SideNavProvider>
    </IntlProvider>
  )
}
