import _ from "lodash"
import { useEffect, useState } from "react"

export type ScreenSize = "sm" | "md" | "lg" | "xl" | "2xl"

const SCREEN_QUERIES: Array<{ size: ScreenSize; matcher: MediaQueryList }> = [
  { size: "2xl", matcher: window.matchMedia("(min-width: 1536px)") },
  { size: "xl", matcher: window.matchMedia("(min-width: 1280px)") },
  { size: "lg", matcher: window.matchMedia("(min-width: 1024px)") },
  { size: "md", matcher: window.matchMedia("(min-width: 768px)") },
]

/**
 * Returns the named width of the current screen. One of: `sm`, `md`, `lg`, `xl`, `2xl`.
 * See https://tailwindcss.com/docs/screens for how Tailwind is configured for media query breakpoints.
 *
 * @param minDelayMs - The minimum interval between updates in milliseconds. Defaults to 100.
 * @returns The current screen size.
 */
export const useScreenSize = (minDelayMs = 100) => {
  const [size, setSize] = useState<ScreenSize>("sm")

  useEffect(() => {
    const listener = _.debounce(() => {
      const matchingSize =
        SCREEN_QUERIES.find(it => it.matcher.matches)?.size || "sm"
      setSize(prevSize => (prevSize === matchingSize ? prevSize : matchingSize))
    }, Math.max(100, minDelayMs))
    window.addEventListener("resize", listener)
    listener()
    return () => window.removeEventListener("resize", listener)
  }, [minDelayMs])

  return size
}
