import { FormattedMessage } from "react-intl"
import { SelectItem } from "../../atoms/select"
import { Person } from "../../generated/graphql"
import TeamPersonItem from "./team-person-item"

export default function TeamRoleView({
  onChangeValue,
  people,
  roleDescription,
  roleItems,
  roleName,
}: {
  onChangeValue: (personId: string, roleId: string | null) => void
  people: Array<Partial<Person & { id: string; roleId: string }>>
  roleDescription?: string
  roleItems: Array<SelectItem>
  roleName: string
}) {
  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-2xl">{roleName}</h2>
      {roleDescription ? (
        <p className="text-tv-dust max-w-[550px] text-[16px] leading-[140%] mb-2">
          {roleDescription}
        </p>
      ) : null}
      {!people.length && (
        <p className="text-tv-dust">
          <FormattedMessage
            defaultMessage="No people found"
            description="Empty role placeholder"
          />
        </p>
      )}
      {people.map(person => (
        <TeamPersonItem
          key={person.id}
          email={person.email!}
          initials={person.initials ?? undefined}
          name={person.name ?? undefined}
          profileImageUri={person.profileImageUri ?? undefined}
          onChangeRole={newRoleId => onChangeValue(person.id!, newRoleId)}
          roleId={person.roleId ?? undefined}
          roleItems={roleItems}
        />
      ))}
    </div>
  )
}
