import classNames from "classnames"
import _ from "lodash"
import { useContext, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { createSearchParams, useNavigate, useParams } from "react-router-dom"
import DropdownMenu, { DropdownMenuItemType } from "../../atoms/dropdown-menu"
import { IconType } from "../../atoms/icon/types"
import SearchTextField from "../../atoms/search-text-field"
import { Permission } from "../../generated/graphql"
import { OrganizationContext } from "../../organization-provider"
import { TrackEventName, trackEvent } from "../../track-event"
import { UserContext } from "../../user-provider"
import { getCreateEventDashboardUrl } from "../../utils/dashboard-urls"
import Avatar from "./avatar"
import ChannelLink from "./channel-link"
import OrganizationLink from "./organization-link"

export default function HeaderBar({
  channelLinkSlug,
}: {
  channelLinkSlug?: string
}) {
  const { organizationSlug, channelSlug } = useParams<{
    organizationSlug: string
    channelSlug?: string
  }>()
  const { logoImageUri } = useContext(OrganizationContext)
  const { getFeatureFlag, hasPermission, initials, profileImageUri } =
    useContext(UserContext)
  const intl = useIntl()
  const navigate = useNavigate()
  const [query, setQuery] = useState("")
  const debouncedSearch = useRef(
    _.debounce((newQuery: string) => {
      if (newQuery.length >= 3) {
        navigate({
          pathname: `/${organizationSlug}/search`,
          search: `?${createSearchParams({ q: newQuery })}`,
        })
      }
    }, 250)
  ).current

  const dropdownMenuItems: Array<DropdownMenuItemType> = []
  if (hasPermission(Permission.WriteVideos)) {
    dropdownMenuItems.push({
      href: channelSlug
        ? `/${organizationSlug}/upload-video?channel=${channelSlug}`
        : `/${organizationSlug}/upload-video`,
      icon: IconType.Upload,
      label: intl.formatMessage({
        description: "Upload video button label",
        defaultMessage: "Upload Video",
      }),
    })
  }

  if (hasPermission(Permission.WriteLiveEvents)) {
    const createEventUri =
      getFeatureFlag("show-create-meeting-screen") === "true"
        ? `/${organizationSlug}/create-meeting`
        : getCreateEventDashboardUrl(organizationSlug || "")

    dropdownMenuItems.push({
      href: createEventUri,
      target:
        getFeatureFlag("show-create-meeting-screen") === "true"
          ? undefined
          : "_blank",
      icon: IconType.AddEvent,
      label: intl.formatMessage({
        description: "Create event button label",
        defaultMessage: "Create Event",
      }),
      onClick: () => {
        trackEvent({
          name: TrackEventName.EventCreatedIntent,
        })
        trackEvent({
          // TODO: Once we can create events in TV, we should move this event on event creation
          name: TrackEventName.VideoUploaded,
          attributes: {
            status: "complete",
          },
        })
      },
    })
  }

  const handleChangeSearchQuery = (newValue: string) => {
    debouncedSearch(newValue)
    setQuery(newValue)
  }

  return (
    <div className={classNames("sticky top-0 py-2 z-20")}>
      <div
        className={classNames(
          "px-2 rounded-xl",
          "bg-tv-obsidian/[48%] backdrop-blur-sm",
          "flex flex-row justify-between items-center gap-2",
          "h-[72px]"
        )}
      >
        <div>
          {channelLinkSlug ? (
            <ChannelLink channelSlug={channelLinkSlug} />
          ) : (
            <OrganizationLink logoImageUri={logoImageUri ?? undefined} />
          )}
        </div>
        <div className="flex-1 flex flex-row justify-center items-center">
          <SearchTextField
            onChangeText={handleChangeSearchQuery}
            placeholder={intl.formatMessage({
              defaultMessage: "Search videos by title",
              description: "Search bar placeholder",
            })}
            value={query}
          />
        </div>
        <div className="flex flex-row items-center gap-2">
          {dropdownMenuItems?.length > 0 ? (
            <DropdownMenu
              className="ml-4"
              icon={IconType.Plus}
              items={dropdownMenuItems}
              label="Create"
              theme="primary"
            />
          ) : null}
          <Avatar
            initials={initials || ""}
            profileImageUri={profileImageUri ?? undefined}
          />
        </div>
      </div>
    </div>
  )
}
