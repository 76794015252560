import { ApolloProvider } from "@apollo/client"
import { Provider as RollbarProvider } from "@rollbar/react"
import React from "react"
import ReactDOM from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import ReactModal from "react-modal"
import { BrowserRouter } from "react-router-dom"
import { apolloClient } from "./apollo-client"
import App from "./app"
import { getRollbarInstance } from "./get-rollbar-instance"
import "./index.css"
import { initPostHog } from "./init-post-hog"
import UserProvider from "./user-provider"

const rollbarInstance = getRollbarInstance()
initPostHog()

ReactModal.setAppElement("#root")

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RollbarProvider instance={rollbarInstance}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <HelmetProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ApolloProvider>
    </RollbarProvider>
  </React.StrictMode>
)
