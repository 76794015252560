import { useIntl } from "react-intl"
import PeopleDropdown from "../../atoms/people-dropdown"

export default function VideoViewCount({
  totalViews,
  viewers,
}: {
  totalViews: number
  viewers: {
    items: Array<{
      person?: {
        id: string
        name?: string | null
        profileImageUri?: string | null
        initials?: string | null
      } | null
    }>
    pageInfo: {
      totalCount: number
    }
  }
}) {
  const intl = useIntl()

  const viewsLabel = intl.formatMessage(
    {
      description: "Video viewers view count label",
      defaultMessage: "{views, plural, one {# view} other {# views}}",
    },
    {
      views: totalViews,
    }
  )

  const unlistedViewersCount =
    viewers.pageInfo.totalCount - viewers.items.length
  const unlistedViewersLabel = unlistedViewersCount
    ? intl.formatMessage(
        {
          description: "Video viewers unlisted viewers label",
          defaultMessage: "{views, plural, one {+ # other} other {+ # others}}",
        },
        {
          views: totalViews,
        }
      )
    : undefined

  return (
    <PeopleDropdown
      anonymousName={intl.formatMessage({
        description: "Video viewers anonymous name",
        defaultMessage: "Anonymous",
      })}
      disabled={!viewers.items.length}
      items={viewers.items.map(viewer => ({
        initials: viewer.person?.initials ?? undefined,
        name: viewer.person?.name ?? undefined,
        profileImageUri: viewer.person?.profileImageUri ?? undefined,
      }))}
      unlistedViewersLabel={unlistedViewersLabel}
      dropdownLabel={viewsLabel}
    />
  )
}
