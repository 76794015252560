import { gql, useQuery } from "@apollo/client"
import { ReactNode, createContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import {
  ProviderGetOrganizationSummaryQuery,
  ProviderGetOrganizationSummaryQueryVariables,
} from "./generated/graphql"

type OrganizationContextType = {
  id: string | null
  name: string | null
  logoImageUri: string | null
}

const QUERY = gql`
  query ProviderGetOrganizationSummary($organizationSlug: String!) {
    organizationSummary(organizationSlug: $organizationSlug) {
      id
      name
      logoImageUri
    }
  }
`

export const OrganizationContext = createContext<OrganizationContextType>({
  id: null,
  name: null,
  logoImageUri: null,
})

export default function OrganizationProvider({
  children,
}: {
  children?: ReactNode
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    ProviderGetOrganizationSummaryQuery,
    ProviderGetOrganizationSummaryQueryVariables
  >(QUERY, {
    variables: { organizationSlug: organizationSlug! },
  })

  const value = useMemo(
    () => ({
      id: data?.organizationSummary.id ?? null,
      name: data?.organizationSummary.name ?? null,
      logoImageUri: data?.organizationSummary.logoImageUri ?? null,
    }),
    [data]
  )

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}
