import { useRollbar } from "@rollbar/react"
import axios, { HttpStatusCode, isAxiosError } from "axios"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { setUserToken } from "../../../auth"
import env from "../../../env"
import { UserContext } from "../../../user-provider"
import { getOrganizationSlug } from "../../../utils/get-organization-slug"
import { getPkceVerifier } from "../pkce"

export const useLoginWithOAuth = ({
  provider,
  code,
  redirect,
}: {
  provider: "microsoft" | "google" | "okta" | null
  code: string
  redirect: string
}) => {
  const rollbar = useRollbar()
  const navigate = useNavigate()
  const { isLoggedIn } = useContext(UserContext)
  const [result, setResult] = useState<{
    isLoading: boolean
    error: Error | null
  }>({ isLoading: true, error: null })

  useEffect(() => {
    if (isLoggedIn === null) {
      return
    }

    const organizationSlug = getOrganizationSlug(redirect)

    if (!organizationSlug) {
      setResult({
        isLoading: false,
        error: new Error("No organization slug found in redirect URL"),
      })
      return
    }

    if (isLoggedIn) {
      setResult({ isLoading: false, error: null })
      return
    }

    const login = async () => {
      const currentUrl = new URL(window.location.href)
      try {
        const pkceVerifier = getPkceVerifier()

        const response = await axios.post<{ userToken: string }>(
          `${env.apiBaseUri()}auth/${provider}`,
          {
            organizationSlug,
            accessCode: code,
            codeVerifier: pkceVerifier,
            redirectUri: `${currentUrl.protocol}//${currentUrl.host}${currentUrl.pathname}`,
          },
          {
            withCredentials: true,
          }
        )

        setUserToken(response.data.userToken)
        setResult({ isLoading: false, error: null })
      } catch (error: any) {
        if (
          isAxiosError(error) &&
          error.response?.status === HttpStatusCode.Forbidden
        ) {
          setResult({
            isLoading: false,
            error: new Error("Forbidden"),
          })
          return
        }

        rollbar.error(error.message, error)
        setResult({
          isLoading: false,
          error,
        })
      }
    }

    login()
  }, [provider, code, isLoggedIn, navigate, redirect, rollbar])

  return result
}
