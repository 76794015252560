import classNames from "classnames"
import { useContext } from "react"
import { useDropzone } from "react-dropzone"
import { useIntl } from "react-intl"
import ButtonDiv from "../../atoms/button-div"
import Container from "../../atoms/container"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Squircle from "../../atoms/squircle"
import { Permission } from "../../generated/graphql"
import { UserContext } from "../../user-provider"

export default function ChannelCover({
  channelId,
  coverImageUri,
  logoImageUri,
  onChangeCoverImage,
  onChangeLogoImage,
}: {
  channelId: string
  coverImageUri?: string
  logoImageUri?: string
  onChangeCoverImage: (newFile: File) => void
  onChangeLogoImage: (newFile: File) => void
}) {
  const intl = useIntl()
  const { hasPermission } = useContext(UserContext)
  const hasWriteChannelsPermission = hasPermission(
    Permission.WriteChannels,
    channelId
  )
  const { getRootProps: getCoverRootProps, getInputProps: getCoverInputProps } =
    useDropzone({
      multiple: false,
      noDrag: true,
      noClick: !hasWriteChannelsPermission,
      accept: { "image/png": [], "image/jpeg": [], "image/gif": [] },
      onDropAccepted: ([newFile]) => onChangeCoverImage(newFile),
    })

  const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } =
    useDropzone({
      multiple: false,
      noDrag: true,
      noClick: !hasWriteChannelsPermission,
      accept: { "image/png": [], "image/jpeg": [], "image/gif": [] },
      onDropAccepted: ([newFile]) => onChangeLogoImage(newFile),
    })

  return (
    <div
      className={classNames(
        "h-[200px]",
        "sm:h-[360px] mb-[70px]",
        "flex flex-col justify-end items-stretch",
        "bg-gradient-to-br from-tv-dandelion to-tv-milk bg-center bg-cover"
      )}
      style={{
        backgroundImage: coverImageUri ? `url(${coverImageUri})` : undefined,
      }}
    >
      <div className="flex flex-col sm:block">
        <Container className="sm:flex sm:flex-row sm:justify-between sm:items-end">
          <div className="mb-[-120px] sm:mb-[-70px]">
            <Squircle
              size={140}
              className="bg-tv-obsidian flex flex-col items-center justify-center"
            >
              <div {...getLogoRootProps()}>
                <Squircle
                  size={128}
                  className={classNames(
                    "flex flex-col justify-center items-center bg-tv-beast bg-cover bg-center",
                    hasWriteChannelsPermission
                      ? "cursor-pointer"
                      : "cursor-auto"
                  )}
                  style={{
                    backgroundImage: logoImageUri
                      ? `url(${logoImageUri})`
                      : undefined,
                  }}
                >
                  {hasWriteChannelsPermission && !logoImageUri && (
                    <Icon color="#fed363" size="large" type={IconType.Upload} />
                  )}
                </Squircle>
                <input {...getLogoInputProps()} />
              </div>
            </Squircle>
          </div>

          {hasWriteChannelsPermission ? (
            <div className="pb-4 invisible sm:visible">
              <div {...getCoverRootProps()}>
                <ButtonDiv
                  compact
                  label={intl.formatMessage({
                    defaultMessage: "Upload Cover",
                    description: "Channel cover upload cover button label",
                  })}
                  theme="secondary"
                />
                <input {...getCoverInputProps()} />
              </div>
            </div>
          ) : (
            //  Dummy element that takes up space
            <div className="pb-12" />
          )}
        </Container>
      </div>
    </div>
  )
}
