import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { useDndMonitor } from "@dnd-kit/core"
import { useContext, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Container from "../atoms/container"
import { IconType } from "../atoms/icon/types"
import SelectWithIcon, { SelectItem } from "../atoms/select-with-icon"
import ChannelCover from "../features/channel-cover"
import ChannelOverflowMenu from "../features/channel-overflow-menu"
import EmptyChannelState from "../features/empty-channel-state"
import HeaderBar from "../features/header-bar"
import CopyChannelLinkButton, {
  handleCopyChannel,
} from "../features/share-channel-button"
import { SideNavContext } from "../features/side-nav/side-nav-provider"
import VideoGrid from "../features/video-grid"
import {
  ChannelTogglePublicChannelMutation,
  ChannelTogglePublicChannelMutationVariables,
  GetChannelQuery,
  GetChannelQueryVariables,
  GetMoreChannelVideosQuery,
  GetMoreChannelVideosQueryVariables,
  MoveVideoToChannelMutation,
  MoveVideoToChannelMutationVariables,
  Permission,
  ToggleChannelDeletedMutation,
  ToggleChannelDeletedMutationVariables,
  UploadChannelCoverImageMutation,
  UploadChannelCoverImageMutationVariables,
  UploadChannelLogoImageMutation,
  UploadChannelLogoImageMutationVariables,
} from "../generated/graphql"
import { useNavigationExpanded } from "../hooks/use-navigation-expanded"
import { OrganizationContext } from "../organization-provider"
import { TrackEventName, trackEvent } from "../track-event"
import { UserContext } from "../user-provider"
import { IdType, entityId } from "../utils/entity-id"
import { isNotFoundError } from "../utils/is-not-found-error"
import ErrorScreen from "./error-screen"
import LoginScreen from "./login-screen"

const QUERY = gql`
  query GetChannel($organizationSlug: String!, $channelSlug: String!) {
    channel(organizationSlug: $organizationSlug, channelSlug: $channelSlug) {
      id
      organizationSummary {
        id
      }
      name
      public
      coverImageUri
      logoImageUri
      deleted
      videos(args: { limit: 10 }) {
        items {
          createdAt
          durationSeconds
          eventStartedAt
          id
          public
          status
          thumbnailUri
          title
          totalViews
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`

const MORE_VIDEOS_QUERY = gql`
  query GetMoreChannelVideos(
    $organizationSlug: String!
    $channelSlug: String!
    $after: String
  ) {
    channel(organizationSlug: $organizationSlug, channelSlug: $channelSlug) {
      id
      videos(args: { after: $after, limit: 10 }) {
        items {
          createdAt
          durationSeconds
          eventStartedAt
          id
          public
          status
          thumbnailUri
          title
          totalViews
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const UPLOAD_LOGO_IMAGE_MUTATION = gql`
  mutation UploadChannelLogoImage($channelId: String!, $logoImage: Upload!) {
    updateChannel(input: { channelId: $channelId, logoImage: $logoImage }) {
      id
      logoImageUri
    }
  }
`

const UPLOAD_COVER_IMAGE_MUTATION = gql`
  mutation UploadChannelCoverImage($channelId: String!, $coverImage: Upload!) {
    updateChannel(input: { channelId: $channelId, coverImage: $coverImage }) {
      id
      coverImageUri
    }
  }
`

const MOVE_VIDEO_MUTATION = gql`
  mutation MoveVideoToChannel($videoId: String!, $channelId: String!) {
    updateVideo(input: { videoId: $videoId, channelId: $channelId }) {
      id
      channel {
        id
      }
    }
  }
`

const TOGGLE_CHANNEL_DELETED_MUTATION = gql`
  mutation ToggleChannelDeleted($channelId: String!, $deleted: Boolean!) {
    updateChannel(input: { channelId: $channelId, deleted: $deleted }) {
      id
      deleted
    }
  }
`

const TOGGLE_PUBLIC_CHANNEL = gql`
  mutation ChannelTogglePublicChannel($channelId: String!, $public: Boolean!) {
    updateChannel(input: { channelId: $channelId, public: $public }) {
      id
      public
    }
  }
`

export default function ChannelScreen() {
  const intl = useIntl()
  const { isLoggedIn, hasPermission } = useContext(UserContext)
  const { name: organizationName } = useContext(OrganizationContext)
  const { setDraggingType, setExpanded } = useContext(SideNavContext)
  const [movingVideoId, setMovingVideoId] = useState<string | null>(null)
  const [allVideos, setAllVideos] = useState<
    Array<GetChannelQuery["channel"]["videos"]["items"][0]>
  >([])
  const hasNextPage = useRef(false)
  useNavigationExpanded(false)
  const { organizationSlug, channelSlug } = useParams<{
    organizationSlug: string
    channelSlug: string
  }>()
  const { data, error } = useQuery<GetChannelQuery, GetChannelQueryVariables>(
    QUERY,
    {
      variables: {
        organizationSlug: organizationSlug!,
        channelSlug: channelSlug!,
      },
      onCompleted: newData => {
        setAllVideos(newData.channel.videos.items)
        hasNextPage.current = newData.channel.videos.pageInfo.hasNextPage
      },
      notifyOnNetworkStatusChange: true,
    }
  )
  const [runGetMoreVideosQuery] = useLazyQuery<
    GetMoreChannelVideosQuery,
    GetMoreChannelVideosQueryVariables
  >(MORE_VIDEOS_QUERY)
  const [runUploadLogoImageMutation] = useMutation<
    UploadChannelLogoImageMutation,
    UploadChannelLogoImageMutationVariables
  >(UPLOAD_LOGO_IMAGE_MUTATION)
  const [runUploadCoverImageMutation] = useMutation<
    UploadChannelCoverImageMutation,
    UploadChannelCoverImageMutationVariables
  >(UPLOAD_COVER_IMAGE_MUTATION)
  const [runMoveVideoMutation] = useMutation<
    MoveVideoToChannelMutation,
    MoveVideoToChannelMutationVariables
  >(MOVE_VIDEO_MUTATION)
  const [runToggleChannelDeletedMutation] = useMutation<
    ToggleChannelDeletedMutation,
    ToggleChannelDeletedMutationVariables
  >(TOGGLE_CHANNEL_DELETED_MUTATION)
  const [runTogglePublicChannelMutation] = useMutation<
    ChannelTogglePublicChannelMutation,
    ChannelTogglePublicChannelMutationVariables
  >(TOGGLE_PUBLIC_CHANNEL)

  useDndMonitor({
    onDragStart: () => {
      setDraggingType("video")
      setExpanded(true)
    },
    onDragEnd: async event => {
      setDraggingType(null)
      setExpanded(false)

      const draggingId = String(event.active.id)
      const dropTargetId = String(event.over?.id ?? "")
      const currentChannelId = data!.channel.id
      const isDraggingVideo = entityId.is(draggingId, IdType.Video)
      const isDropTargetChannel = entityId.is(dropTargetId, IdType.Channel)

      const isNoOp =
        !isDraggingVideo ||
        !isDropTargetChannel ||
        dropTargetId === currentChannelId

      if (isNoOp) {
        return
      }

      setMovingVideoId(draggingId)

      await runMoveVideoMutation({
        variables: { videoId: draggingId, channelId: dropTargetId },
        update: (cache, newData) => {
          // Delete all cached video data for channel moved from.
          cache.modify({
            id: cache.identify(data!.channel),
            fields: {
              videos(_, { DELETE }) {
                return DELETE
              },
            },
          })
          // Delete all cached video data for channel moved to.
          cache.modify({
            id: cache.identify(newData.data!.updateVideo.channel!),
            fields: {
              videos(_, { DELETE }) {
                return DELETE
              },
            },
          })
        },
      })

      setMovingVideoId(null)
    },
    onDragCancel: () => {
      setDraggingType(null)
      setExpanded(false)
    },
  })

  if (error) {
    if (isNotFoundError(error)) {
      return isLoggedIn ? (
        <ErrorScreen
          message={intl.formatMessage({ defaultMessage: "Channel not found" })}
        />
      ) : (
        <LoginScreen />
      )
    }

    return <ErrorScreen />
  }

  const channel = data?.channel

  if (!channel) {
    return null
  }

  const handleChangeCoverImage = async (file: File) => {
    await runUploadCoverImageMutation({
      variables: { channelId: data!.channel.id, coverImage: file },
      update: cache => {
        // Delete all cached fields on channel summary
        cache.modify({
          id: cache.identify({
            __typename: "ChannelSummary",
            id: data!.channel.id,
          }),
          fields: (_, { DELETE }) => DELETE,
        })
      },
    })
  }

  const handleChangeLogoImage = async (file: File) => {
    await runUploadLogoImageMutation({
      variables: { channelId: data!.channel.id, logoImage: file },
      update: cache => {
        // Delete all cached fields on channel summary
        cache.modify({
          id: cache.identify({
            __typename: "ChannelSummary",
            id: data!.channel.id,
          }),
          fields: (_, { DELETE }) => DELETE,
        })
      },
    })
  }

  const handleLoadMore = async () => {
    if (!hasNextPage.current) {
      return
    }

    const { data: newData } = await runGetMoreVideosQuery({
      variables: {
        organizationSlug: organizationSlug!,
        channelSlug: channelSlug!,
        after: allVideos[allVideos.length - 1]?.id,
      },
    })

    setAllVideos(prev => [...prev, ...newData!.channel.videos.items])
    hasNextPage.current = newData!.channel.videos.pageInfo.hasNextPage
  }

  const handleToggleChannelDeletedClick = async () => {
    const deleted = !data!.channel.deleted
    await runToggleChannelDeletedMutation({
      variables: {
        channelId: data!.channel.id,
        deleted,
      },
      update: cache => {
        // Delete all cached channels on organization (force side nav to reload)
        cache.modify({
          id: cache.identify({
            __typename: "Organization",
            id: data!.channel.organizationSummary.id,
          }),
          fields: {
            channels(_, { DELETE }) {
              return DELETE
            },
          },
        })
      },
    })

    trackEvent({
      name: TrackEventName.ChannelDeleted,
      attributes: { deleted },
    })
  }

  const handleVisibilityChange = async (value: string) => {
    const isPublic = value === "public"
    await runTogglePublicChannelMutation({
      variables: {
        channelId: channel.id,
        public: isPublic,
      },
    })
    trackEvent({
      name: TrackEventName.ChannelVisibilityChanged,
      attributes: { public: isPublic },
    })
  }

  const canWriteChannel = hasPermission(Permission.WriteChannels, channel.id)

  const items: Array<SelectItem> = [
    {
      value: "private",
      icon: IconType.Lock,
      label: intl.formatMessage({ defaultMessage: "Private" }),
      description: intl.formatMessage(
        { defaultMessage: "Only {organizationName} members" },
        { organizationName }
      ),
      selectedLabel: intl.formatMessage(
        { defaultMessage: "Only watchable by {organizationName}" },
        { organizationName }
      ),
    },
    {
      value: "public",
      icon: IconType.Globe,
      label: "Public",
      description: intl.formatMessage({
        defaultMessage: "Anyone with the link",
      }),
      selectedLabel: intl.formatMessage({
        defaultMessage: "Anyone with the link",
      }),
    },
  ]

  return (
    <>
      {/* Create container for header bar, but make it 0px tall so that it doesn't take up space. */}
      <Container className="sticky top-0 h-0 z-10">
        <HeaderBar />
      </Container>
      <Container fluid className="relative">
        <ChannelCover
          channelId={channel.id}
          coverImageUri={channel.coverImageUri ?? undefined}
          logoImageUri={channel.logoImageUri ?? undefined}
          onChangeCoverImage={handleChangeCoverImage}
          onChangeLogoImage={handleChangeLogoImage}
        />
      </Container>
      <Container>
        <div className="flex">
          <div className="flex flex-col justify-between w-full pt-4 sm:flex-row">
            <div className="flex flex-col items-center sm:flex-row">
              <div className="flex flex-row gap-2">
                {/* Dummy element to center title */}
                <div className="invisible sm:hidden w-10 h-10" />
                <div className="flex flex-col items-center sm:items-start">
                  <h1 className="text-title3 sm:text-title1 text-center sm:text-left text-ellipsis break-all overflow-hidden max-w-[100%]">
                    {channel.name}
                  </h1>
                  {canWriteChannel && (
                    <div className="pt-2 pb-6 z-20">
                      <SelectWithIcon
                        items={items}
                        value={channel.public ? "public" : "private"}
                        onChangeValue={handleVisibilityChange}
                      />
                    </div>
                  )}
                </div>
                <div className="visible sm:invisible z-30">
                  <ChannelOverflowMenu
                    isMobile
                    channelDeleted={channel.deleted}
                    channelId={channel.id}
                    onToggleChannelDeletedClick={
                      handleToggleChannelDeletedClick
                    }
                    onShareChannelClick={handleCopyChannel}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2 invisible sm:visible">
              <CopyChannelLinkButton />
              <ChannelOverflowMenu
                channelDeleted={channel.deleted}
                channelId={channel.id}
                onShareChannelClick={handleCopyChannel}
                onToggleChannelDeletedClick={handleToggleChannelDeletedClick}
              />
            </div>
          </div>
        </div>
        <div className="pt-2 pb-4">
          <span className="text-tv-dust">
            <FormattedMessage
              defaultMessage="{episodeCount, plural, one {# episode} other {# episodes}}"
              description="Episode count on channel screen"
              values={{
                episodeCount: channel.videos.pageInfo.totalCount,
              }}
            />
          </span>
        </div>
        {!channel.videos.items.length ? (
          <EmptyChannelState channelId={channel.id} />
        ) : (
          <VideoGrid
            isDraggable
            onLoadMore={handleLoadMore}
            videos={allVideos
              .filter(video => video.id !== movingVideoId)
              .map(video => ({
                date: video.eventStartedAt ?? video.createdAt,
                durationSeconds: video.durationSeconds,
                id: video.id,
                isChannelPublic: channel.public,
                isVideoPublic: video.public,
                status: video.status,
                thumbnailUri: video.thumbnailUri ?? undefined,
                title: video.title,
                viewCount: video.totalViews,
              }))}
          />
        )}
      </Container>
    </>
  )
}
