import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Squircle from "../../atoms/squircle"

export default function CreateChannelTile() {
  return (
    <div
      className={classNames(
        "appearance-none aspect-[8/7] max-w-[400px] rounded-xl overflow-hidden",
        "bg-tv-obsidian",
        "border border-tv-raven",
        "flex flex-col",
        "group"
      )}
    >
      <div className="flex-[4_4_0%]">&nbsp;</div>
      <div className="flex-[6_6_0%] relative flex flex-col items-center justify-center">
        <Squircle
          size={80}
          className={classNames(
            "absolute -top-[40px] left-[50%] -mx-[40px] p-[1px]",
            "bg-tv-raven group-hover:bg-tv-dandelion",
            "transition ease-in-out duration-300"
          )}
        >
          <Squircle
            size={78}
            className="bg-tv-obsidian flex items-center justify-center"
          >
            <Icon type={IconType.Plus} size="large" color="#fed363" />
          </Squircle>
        </Squircle>
        <p className="text-title4 text-tv-dandelion font-bold">
          <FormattedMessage
            defaultMessage="Create Channel"
            description="Create channel tile title"
          />
        </p>
        <p className="text-subhead4 text-tv-dust">
          <FormattedMessage
            defaultMessage="Organize your events"
            description="Create channel tile subtitle"
          />
        </p>
      </div>
    </div>
  )
}
