export enum IconType {
  AddEmoji = "AddEmoji",
  AddEvent = "AddEvent",
  AddUser = "AddUser",
  AddVideo = "AddVideo",
  Back = "Back",
  Billing = "Billing",
  Calendar = "Calendar",
  Channel = "Channel",
  Check = "Check",
  CheckCircle = "CheckCircle",
  Close = "Close",
  Collapse = "Collapse",
  Delete = "Delete",
  Discover = "Discover",
  Download = "Download",
  Edit = "Edit",
  Email = "Email",
  Emoji = "Emoji",
  Expand = "Expand",
  Feature = "Feature",
  Filter = "Filter",
  Folder = "Folder",
  Forward = "Forward",
  Gift = "Gift",
  Globe = "Globe",
  Hidden = "Hidden",
  Highlights = "Highlights",
  Home = "Home",
  Link = "Link",
  Lock = "Lock",
  Logout = "Logout",
  Menu = "Menu",
  Move = "Move",
  Notification = "Notification",
  Overflow = "Overflow",
  Play = "Play",
  Plus = "Plus",
  Props = "Props",
  Record = "Record",
  Recurring = "Recurring",
  Search = "Search",
  Settings = "Settings",
  Theme = "Theme",
  Time = "Time",
  Upload = "Upload",
  User = "User",
  Workplace = "workplace",
}
