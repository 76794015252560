import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import { IconType } from "../../atoms/icon/types"
import { useDurationDescription } from "../../hooks/use-duration-description"

export default function ShareVideoToast({
  currentTimeSeconds = 0,
  onCopyWithoutTimestampClick,
  onDismissClick,
}: {
  currentTimeSeconds?: number
  onCopyWithoutTimestampClick: () => void
  onDismissClick: () => void
}) {
  const intl = useIntl()
  const getDurationDescription = useDurationDescription()

  return (
    <div className="flex items-center gap-4">
      <div className="font-semibold">
        <FormattedMessage
          defaultMessage="Link copied{currentTimeSeconds, select, 0 {} other { at {currentTimeFormatted}}}"
          description="Share video toast message"
          values={{
            currentTimeFormatted: getDurationDescription(
              currentTimeSeconds,
              "clock"
            ),
            currentTimeSeconds,
          }}
        />
      </div>
      {currentTimeSeconds > 0 && (
        <Button
          theme="tertiary"
          compact
          icon={IconType.Link}
          label={intl.formatMessage({
            defaultMessage: "Copy without timestamp",
            description: "Share video toast copy-without-timestamp label",
          })}
          onClick={onCopyWithoutTimestampClick}
        />
      )}
      <Button
        compact
        icon={IconType.Close}
        onClick={onDismissClick}
        theme="tertiary"
      />
    </div>
  )
}
