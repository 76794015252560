import { useEffect, useMemo, useRef } from "react"
import { useInView } from "react-intersection-observer"
import Caption, { CaptionType } from "./caption"

export default function PageOfCaptions({
  captions,
  hasEndSentry = false,
  hasStartSentry = false,
  isAutoScrollEnabled = false,
  onChangeTime,
  onLoadCaptionsAfter,
  onLoadCaptionsBefore,
  onScrollToCaption,
  timeMs,
}: {
  captions: Array<CaptionType>
  hasEndSentry?: boolean
  hasStartSentry?: boolean
  isAutoScrollEnabled?: boolean
  onChangeTime: (timeMs: number) => void
  onLoadCaptionsAfter: (timeMs: number) => void
  onLoadCaptionsBefore: (timeMs: number) => void
  onScrollToCaption: (element: HTMLDivElement) => void
  timeMs: number
}) {
  const activeIndex = useMemo(
    () =>
      captions.findIndex(
        caption =>
          timeMs >= caption.startTimeMs &&
          timeMs <= caption.startTimeMs + caption.durationMs
      ),
    [captions, timeMs]
  )
  const captionRefs = useRef<Array<HTMLDivElement | null>>(
    Array.from(new Array(captions.length)).map(() => null)
  )
  const bottomInView = useRef(false)
  const topInView = useRef(false)

  useEffect(() => {
    const captionRef = captionRefs.current[activeIndex]
    if (captionRef && isAutoScrollEnabled) {
      onScrollToCaption(captionRef)
    }
  }, [activeIndex, isAutoScrollEnabled, onScrollToCaption])

  const { ref: topRef } = useInView({
    delay: 100,
    onChange: inView => {
      if (inView === topInView.current) {
        return
      }
      topInView.current = inView

      if (!inView) {
        return
      }

      onLoadCaptionsBefore(captions[0].startTimeMs)
    },
    threshold: 0,
  })

  const { ref: bottomRef } = useInView({
    delay: 100,
    onChange: inView => {
      if (inView === bottomInView.current) {
        return
      }
      bottomInView.current = inView

      if (!inView) {
        return
      }

      onLoadCaptionsAfter(captions[captions.length - 1].startTimeMs)
    },
    threshold: 0,
  })

  return (
    <div className="relative flex flex-col gap-6">
      {hasStartSentry && (
        <div
          className="absolute top-0 h-full max-h-[100px] w-full -z-[1] select-none"
          ref={topRef}
        />
      )}
      {hasEndSentry && (
        <div
          className="absolute bottom-0 h-full max-h-[100px] w-full -z-[1] select-none"
          ref={bottomRef}
        />
      )}
      {captions.map((caption, index) => (
        <div
          key={caption.startTimeMs}
          ref={element => {
            captionRefs.current[index] = element
          }}
        >
          <Caption
            isActive={index === activeIndex}
            caption={caption}
            onClickTimestamp={onChangeTime}
          />
        </div>
      ))}
    </div>
  )
}
