import {
  Active,
  DragOverlay as DndKitDragOverlay,
  useDndMonitor,
} from "@dnd-kit/core"
import { useState } from "react"
import { IdType, entityId } from "../../utils/entity-id"
import VideoDragOverlay from "../video-grid/video-drag-overlay"

export default function DragOverlay() {
  const [active, setActive] = useState<Active | null>(null)

  useDndMonitor({
    onDragStart: event => {
      setActive(event.active)
    },
    onDragEnd: () => {
      setActive(null)
    },
    onDragCancel: () => {
      setActive(null)
    },
  })

  const activeId = active?.id as string | undefined

  return (
    <DndKitDragOverlay
      // modifiers={[restrictToWindowEdges]}
      style={{
        width: "min-content",
        height: "min-content",
      }}
    >
      {entityId.is(activeId || "", IdType.Video) ? (
        <VideoDragOverlay title={active!.data.current!.title} />
      ) : null}
    </DndKitDragOverlay>
  )
}
