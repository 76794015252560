import { ChangeEvent, useState } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import HelperText from "../../atoms/helper-text"
import Label from "../../atoms/label"
import TextField from "../../atoms/text-field"
import ValidationError from "../../atoms/validation-error"
import { slugify } from "../../utils/slugify"
import { FORMAT_PATTERNS, ValidationFormat } from "../../utils/validate"

export type ValueType = {
  name: string
  slug: string
  email: string
}

export default function CreateOrganizationForm({
  currentLocation,
  disabled = false,
  onCreateOrganizationClick,
  onDiscardClick,
}: {
  currentLocation: string
  disabled?: boolean
  onCreateOrganizationClick: (newValue: ValueType) => void
  onDiscardClick: () => void
}) {
  const intl = useIntl()
  const [manualSlug, setManualSlug] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<ValueType>({
    defaultValues: {
      name: "",
      slug: "",
      email: "",
    },
    mode: "all",
  })

  const url = new URL(currentLocation)
  const urlPrefix = `${url.protocol}//${url.host}/`

  return (
    <form onSubmit={handleSubmit(onCreateOrganizationClick)}>
      <div className="px-6 py-4 flex flex-col gap-4 border-b border-tv-coal">
        <h3 className="text-title3 text-center">
          <FormattedMessage
            defaultMessage="Create Organization"
            description="Create organization form title"
          />
        </h3>
        {/* Email */}
        <div>
          <div>
            <Label htmlFor="email">
              <FormattedMessage
                defaultMessage="Email"
                description="Create organization form email label"
              />
            </Label>
          </div>
          <TextField
            id="name"
            className="w-full"
            disabled={disabled}
            invalid={!!errors.email}
            maxLength={255}
            placeholder={intl.formatMessage({
              defaultMessage: "william.bell@massivedynamic.com",
              description: "Create organization form email placeholder",
            })}
            {...register("email", {
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: "Email is required",
                  description:
                    "Create organization form email required message",
                }),
              },
              pattern: {
                value: FORMAT_PATTERNS[ValidationFormat.Email],
                message: intl.formatMessage({
                  defaultMessage: "Email must be a valid email address",
                  description: "Create organization form email format message",
                }),
              },
            })}
          />
          {errors.email && (
            <ValidationError>{errors.email.message!}</ValidationError>
          )}
          <HelperText>
            <FormattedMessage
              defaultMessage="Customer company email address, <b>not</b> Venue staff."
              description="Create organization form email helper text"
              // eslint-disable-next-line react/no-unstable-nested-components
              values={{ b: chunks => <b>{chunks}</b> }}
            />
          </HelperText>
        </div>
        {/* Name */}
        <div>
          <div>
            <Label htmlFor="slug">
              <FormattedMessage
                defaultMessage="Organization Name"
                description="Create organization form organization name label"
              />
            </Label>
          </div>
          <TextField
            id="slug"
            autoComplete="off"
            disabled={disabled}
            placeholder={intl.formatMessage({
              defaultMessage: "Massive Dynamic",
              description: "Create organization form name placeholder",
            })}
            invalid={!!errors.name}
            maxLength={255}
            size={40}
            className="w-full"
            {...register("name", {
              onChange: (evt: ChangeEvent<HTMLInputElement>) => {
                setValue(
                  "slug",
                  manualSlug ? watch("slug") : slugify(evt.target.value)
                )
              },
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: "Name is required",
                  description: "Create organization form name message",
                }),
              },
            })}
          />
          {errors.name && (
            <ValidationError>{errors.name.message!}</ValidationError>
          )}
        </div>
        {/* Slug */}
        <div>
          <div>
            <Label htmlFor="slug">
              <FormattedMessage
                defaultMessage="Organization URL"
                description="Create organization form slug label"
              />
            </Label>
          </div>
          <div className="flex flex-row items-center gap-1">
            <span>{urlPrefix}</span>
            <div className="flex-1">
              <TextField
                id="slug"
                autoComplete="off"
                disabled={disabled}
                placeholder={intl.formatMessage({
                  defaultMessage: "massive-dynamic",
                  description: "Create organization form slug placeholder",
                })}
                invalid={!!errors.slug}
                maxLength={255}
                size={40}
                className="w-full"
                {...register("slug", {
                  onChange: (evt: ChangeEvent<HTMLInputElement>) => {
                    setManualSlug(!!evt.target.value)
                  },
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      defaultMessage: "Channel URL is required",
                      description: "Create channel form slug required message",
                    }),
                  },
                  pattern: {
                    value: FORMAT_PATTERNS[ValidationFormat.Slug],
                    message: intl.formatMessage({
                      defaultMessage:
                        "Organization URL suffix must start with letter, and contain only letters, numbers, and dashes",
                      description:
                        "Create organization form slug format message",
                    }),
                  },
                })}
              />
            </div>
          </div>
          {errors.slug && (
            <ValidationError>{errors.slug.message!}</ValidationError>
          )}
        </div>
      </div>
      {/* Buttons */}
      <div className="px-6 py-4 flex flex-row justify-end gap-3">
        <Button
          disabled={disabled}
          label={intl.formatMessage({
            defaultMessage: "Discard",
            description: "Create organization form discard button label",
          })}
          onClick={onDiscardClick}
          theme="tertiary"
        />
        <Button
          disabled={disabled || !!Object.keys(errors).length}
          label={intl.formatMessage({
            defaultMessage: "Create Organization",
            description: "Create organization form submit button label",
          })}
          type="submit"
        />
      </div>
    </form>
  )
}
