function TipsIcon() {
  return (
    <svg
      width="142"
      height="128"
      viewBox="0 0 142 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 0.282 10.799 C -1.003 16.89 2.204 23.204 7.864 25.781 C 11.966 27.654 16.416 27.314 19.995 25.35 C 20.285 25.193 20.633 25.168 20.94 25.301 L 25.166 27.165 C 26.011 27.538 26.898 26.734 26.608 25.856 L 25.075 21.174 C 24.984 20.884 25.017 20.577 25.174 20.312 C 25.837 19.177 26.343 17.917 26.666 16.558 C 28.091 10.517 25.091 4.137 19.515 1.402 C 11.543 -2.501 2.113 2.098 0.282 10.799 Z"
        fill="#F6F6F4"
      />
      <path
        d="M 11.493 48.014 C 10.656 42.478 14.278 37.192 19.747 35.982 L 29.773 33.769 L 31.961 33.289 L 37.77 32.004 L 39.079 31.714 L 95.957 19.152 C 96.222 19.094 96.496 19.044 96.761 19.011 C 101.153 18.456 105.246 21.132 106.489 25.392 L 123.054 82.113 L 137.945 89.587 C 139.37 90.3 140.398 91.625 140.746 93.183 L 141.856 98.23 C 142.486 101.089 140.679 103.923 137.821 104.552 L 65.231 120.578 L 32.591 127.788 C 32.326 127.846 32.052 127.895 31.779 127.928 C 29.392 128.235 26.948 127.547 25.067 126.047 C 23.161 124.531 21.967 122.352 21.727 119.924 L 20.418 106.922 L 11.493 48.014 Z"
        fill="#F6F6F4"
      />
      <path
        d="M 25.705 106.301 L 45.294 101.975 L 40.231 36.893 L 33.113 38.468 L 30.925 38.948 L 20.898 41.161 C 18.139 41.774 16.324 44.417 16.747 47.21 L 25.705 106.301 Z"
        fill="#FF9CB5"
      />
      <path
        d="M 38.971 99.456 C 39.46 99.39 39.775 98.843 39.676 98.238 L 36.634 79.809 C 36.535 79.204 36.054 78.765 35.566 78.831 C 35.077 78.897 34.762 79.444 34.861 80.049 L 37.902 98.478 C 38.002 99.092 38.474 99.522 38.971 99.456 Z"
        fill="#292929"
      />
      <path
        d="M 30.204 101.395 C 30.693 101.329 31.008 100.782 30.909 100.177 L 27.867 81.748 C 27.768 81.143 27.287 80.704 26.798 80.77 C 26.31 80.836 25.995 81.383 26.094 81.988 L 29.135 100.417 C 29.235 101.022 29.715 101.461 30.204 101.395 Z"
        fill="#292929"
      />
      <path
        d="M 34.588 100.426 C 35.077 100.359 35.392 99.812 35.292 99.208 L 32.251 80.778 C 32.152 80.174 31.671 79.734 31.182 79.801 C 30.693 79.867 30.378 80.414 30.478 81.019 L 33.519 99.448 C 33.618 100.053 34.099 100.492 34.588 100.426 Z"
        fill="#292929"
      />
      <path
        d="M 135.577 94.334 L 62.985 110.361 L 64.101 115.411 L 136.691 99.383 L 135.577 94.334 Z"
        fill="#292929"
      />
      <path
        d="M 118.555 85.792 L 135.575 94.335 L 43.67 114.629 L 42.965 102.481 L 118.555 85.792 Z"
        fill="#C5FB7C"
      />
      <path
        d="M 38.922 37.183 L 97.109 24.331 C 98.99 23.917 100.863 25.027 101.41 26.875 L 118.613 85.783 L 45.302 101.975 L 36.096 41.302 C 35.806 39.404 37.041 37.598 38.922 37.183 Z"
        fill="#292929"
      />
      <path
        d="M 59.025 90.938 L 101.518 81.557 C 104.526 80.894 106.431 77.911 105.76 74.903 L 97.2 36.131 C 96.537 33.123 93.554 31.217 90.546 31.888 L 48.053 41.269 C 45.045 41.931 43.139 44.915 43.811 47.923 L 52.371 86.695 C 53.033 89.703 56.008 91.601 59.025 90.938 Z"
        fill="#36BFFE"
      />
      <path
        d="M 45.294 101.967 L 62.985 110.361 L 64.096 115.408 L 31.455 122.617 C 29.309 123.089 27.238 121.589 27.014 119.402 L 25.688 106.293 L 45.294 101.967 Z"
        fill="#F66239"
      />
      <path
        d="M 114.295 92.885 L 121.985 91.186 L 116.085 88.385 L 108.395 90.084 L 114.295 92.885 Z"
        fill="white"
      />
      <path
        d="M 120.187 95.678 L 127.877 93.979 L 121.985 91.186 L 114.295 92.885 L 120.187 95.678 Z"
        fill="#FF9CB5"
      />
      <path
        d="M 106.605 94.575 L 114.295 92.885 L 108.395 90.084 L 100.705 91.783 L 106.605 94.575 Z"
        fill="#292929"
      />
      <path
        d="M 112.497 97.376 L 120.187 95.678 L 114.295 92.885 L 106.605 94.575 L 112.497 97.376 Z"
        fill="#FFD34B"
      />
      <path
        d="M 98.916 96.274 L 106.605 94.575 L 100.705 91.783 L 93.016 93.482 L 98.916 96.274 Z"
        fill="#F66239"
      />
      <path
        d="M 104.816 99.075 L 112.497 97.376 L 106.605 94.575 L 98.916 96.274 L 104.816 99.075 Z"
        fill="#292929"
      />
      <path
        d="M 91.226 97.973 L 98.916 96.274 L 93.016 93.482 L 85.326 95.18 L 91.226 97.973 Z"
        fill="#FFD34B"
      />
      <path
        d="M 97.126 100.774 L 104.816 99.075 L 98.916 96.274 L 91.226 97.973 L 97.126 100.774 Z"
        fill="#FF9CB5"
      />
      <path
        d="M 83.536 99.672 L 91.226 97.973 L 85.326 95.18 L 77.644 96.879 L 83.536 99.672 Z"
        fill="#F66239"
      />
      <path
        d="M 89.436 102.472 L 97.126 100.774 L 91.226 97.973 L 83.536 99.672 L 89.436 102.472 Z"
        fill="white"
      />
      <path
        d="M 75.846 101.37 L 83.536 99.672 L 77.644 96.879 L 69.954 98.57 L 75.846 101.37 Z"
        fill="white"
      />
      <path
        d="M 81.746 104.171 L 89.436 102.472 L 83.536 99.672 L 75.846 101.37 L 81.746 104.171 Z"
        fill="#292929"
      />
      <path
        d="M 68.156 103.069 L 75.846 101.37 L 69.954 98.57 L 62.265 100.268 L 68.156 103.069 Z"
        fill="#FF9CB5"
      />
      <path
        d="M 74.056 105.87 L 81.746 104.171 L 75.846 101.37 L 68.156 103.069 L 74.056 105.87 Z"
        fill="#FFD34B"
      />
      <path
        d="M 60.466 104.768 L 68.156 103.069 L 62.265 100.268 L 54.575 101.967 L 60.466 104.768 Z"
        fill="#292929"
      />
      <path
        d="M 66.366 107.56 L 74.056 105.87 L 68.156 103.069 L 60.466 104.768 L 66.366 107.56 Z"
        fill="white"
      />
      <path
        d="M 70.021 72.028 C 65.911 72.683 62.024 69.873 61.37 65.763 C 60.715 61.653 63.524 57.767 67.634 57.112 C 71.744 56.458 75.631 59.267 76.285 63.377 C 76.94 67.487 74.131 71.373 70.021 72.028 Z"
        fill="#FCFCFC"
      />
      <path
        d="M 67.742 57.792 C 71.487 57.195 75.009 59.747 75.606 63.485 C 76.202 67.23 73.65 70.752 69.913 71.348 C 66.167 71.945 62.646 69.393 62.049 65.656 C 61.452 61.918 63.996 58.388 67.742 57.792 Z M 67.526 56.433 C 63.035 57.154 59.969 61.388 60.69 65.871 C 61.411 70.354 65.645 73.428 70.128 72.707 C 74.62 71.987 77.686 67.752 76.965 63.269 C 76.252 58.778 72.018 55.712 67.526 56.433 Z"
        fill="#292929"
      />
      <path
        d="M 72.324 68.929 C 74.968 68.506 76.774 66.02 76.352 63.369 C 75.929 60.725 73.443 58.919 70.791 59.341 C 68.148 59.764 66.341 62.25 66.764 64.902 C 67.187 67.545 69.673 69.351 72.324 68.929 Z"
        fill="#292929"
      />
      <path
        d="M 85.326 69.583 C 81.216 70.238 77.329 67.429 76.675 63.319 C 76.02 59.209 78.829 55.322 82.939 54.668 C 87.049 54.013 90.936 56.822 91.59 60.932 C 92.245 65.042 89.436 68.929 85.326 69.583 Z"
        fill="#FCFCFC"
      />
      <path
        d="M 83.047 55.347 C 86.792 54.751 90.314 57.303 90.911 61.04 C 91.507 64.786 88.955 68.307 85.218 68.904 C 81.481 69.501 77.951 66.948 77.354 63.211 C 76.749 59.466 79.302 55.944 83.047 55.347 Z M 82.832 53.988 C 78.34 54.709 75.274 58.944 75.995 63.427 C 76.716 67.918 80.951 70.984 85.434 70.263 C 89.916 69.542 92.991 65.308 92.27 60.825 C 91.549 56.342 87.315 53.267 82.832 53.988 Z"
        fill="#292929"
      />
      <path
        d="M 87.629 66.484 C 90.273 66.062 92.079 63.576 91.657 60.924 C 91.234 58.281 88.748 56.474 86.096 56.897 C 83.453 57.319 81.647 59.805 82.069 62.457 C 82.492 65.1 84.978 66.907 87.629 66.484 Z"
        fill="#292929"
      />
      <path
        d="M 75.191 80.72 C 72.755 80.455 70.443 79.411 68.67 77.787 C 68.015 77.182 67.634 76.37 67.601 75.483 C 67.56 74.597 67.874 73.743 68.471 73.097 C 69.175 72.326 70.178 71.937 71.222 72.036 C 71.951 72.103 72.623 72.401 73.161 72.898 C 73.89 73.561 74.885 74.008 75.904 74.124 L 75.979 74.133 C 76.965 74.224 77.984 74 78.779 73.528 C 79.384 73.171 80.072 73.014 80.777 73.072 C 81.837 73.171 82.79 73.776 83.337 74.688 C 83.793 75.45 83.917 76.345 83.702 77.207 C 83.486 78.069 82.939 78.79 82.177 79.245 C 80.172 80.43 77.76 80.969 75.382 80.754 L 75.191 80.72 Z"
        fill="#FCFCFC"
      />
      <path
        d="M 71.156 72.716 C 71.711 72.765 72.25 72.989 72.697 73.395 C 73.526 74.158 74.678 74.671 75.829 74.796 C 75.863 74.796 75.888 74.804 75.921 74.804 C 77.048 74.903 78.216 74.655 79.136 74.108 C 79.633 73.81 80.188 73.702 80.719 73.752 C 81.531 73.826 82.301 74.274 82.749 75.028 C 83.494 76.279 83.08 77.895 81.829 78.641 C 79.948 79.759 77.686 80.256 75.448 80.058 C 75.39 80.049 75.324 80.049 75.266 80.041 C 72.979 79.792 70.8 78.815 69.134 77.282 C 68.065 76.295 67.99 74.63 68.977 73.561 C 69.548 72.931 70.36 72.641 71.156 72.716 Z M 71.28 71.348 C 70.021 71.232 68.811 71.705 67.957 72.633 C 67.236 73.42 66.864 74.448 66.905 75.517 C 66.955 76.586 67.41 77.572 68.198 78.301 C 70.079 80.033 72.531 81.135 75.109 81.408 C 75.175 81.417 75.249 81.425 75.316 81.425 C 77.835 81.657 80.395 81.085 82.517 79.826 C 83.436 79.279 84.091 78.409 84.356 77.364 C 84.621 76.329 84.464 75.251 83.917 74.323 C 83.262 73.221 82.111 72.492 80.826 72.376 C 79.981 72.301 79.144 72.492 78.415 72.923 C 77.744 73.321 76.874 73.511 76.028 73.428 L 75.962 73.42 C 75.1 73.329 74.222 72.931 73.609 72.376 C 72.971 71.779 72.159 71.423 71.28 71.348 Z"
        fill="#292929"
      />
      <path
        d="M 7.226 6.507 L 11.054 4.294 L 16.921 14.454 L 13.093 16.666 L 7.226 6.507 Z M 14.51 20.378 C 13.739 19.053 14.162 17.337 15.537 16.542 C 16.938 15.738 18.653 16.219 19.423 17.544 C 20.194 18.87 19.747 20.602 18.346 21.406 C 16.979 22.201 15.28 21.704 14.51 20.378 Z"
        fill="#292929"
      />
      <path
        d="M 75.44 80.058 C 75.382 80.049 75.316 80.049 75.258 80.041 C 72.971 79.792 70.791 78.815 69.126 77.282 C 68.057 76.295 67.982 74.63 68.968 73.561 C 69.954 72.492 71.62 72.417 72.689 73.404 C 73.518 74.166 74.669 74.68 75.821 74.804 C 76.973 74.928 78.175 74.68 79.128 74.116 C 80.379 73.37 81.995 73.785 82.74 75.036 C 83.486 76.287 83.072 77.903 81.821 78.649 C 79.931 79.759 77.677 80.256 75.44 80.058 Z"
        fill="white"
      />
      <path
        d="M 72.863 73.47 C 72.705 73.354 72.548 73.246 72.407 73.122 C 72.15 72.89 71.86 72.716 71.562 72.591 L 70.974 79.08 C 71.405 79.303 71.844 79.502 72.299 79.676 L 72.863 73.47 Z"
        fill="#292929"
      />
      <path
        d="M 75.838 74.646 C 75.597 74.622 75.365 74.564 75.125 74.514 L 74.595 80.281 C 74.802 80.314 75.009 80.348 75.216 80.372 C 75.283 80.381 75.349 80.381 75.415 80.389 C 75.597 80.406 75.788 80.414 75.97 80.414 L 76.492 74.655 C 76.277 74.671 76.053 74.671 75.838 74.646 Z"
        fill="#292929"
      />
      <path
        d="M 79.451 73.892 C 79.277 73.992 79.086 74.075 78.895 74.158 L 78.34 80.29 C 78.821 80.215 79.285 80.107 79.749 79.975 L 80.337 73.561 C 80.031 73.627 79.732 73.727 79.451 73.892 Z"
        fill="#292929"
      />
    </svg>
  )
}

export default TipsIcon
