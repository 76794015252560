import classNames from "classnames"
import { DetailedHTMLProps, HTMLAttributes } from "react"

export default function SsoButton({
  className,
  disabled,
  label,
  provider,
  tabIndex = 0,
  ...props
}: {
  disabled?: boolean
  label: string
  provider: "google" | "microsoft" | "okta"
} & Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "children"
>) {
  return (
    <div
      className={classNames(
        "appearance-none block cursor-pointer rounded-lg border",
        "flex flex-row items-center justify-center gap-2 py-[15px]",
        "font-sans font-semibold leading-none text-[15px]",
        disabled &&
          "bg-tv-coal border-[#313131] text-tv-gunmetal hover:bg-tv-coal hover:border-[#313131]",
        !disabled &&
          "bg-tv-coal border-tv-raven text-tv-milk hover:bg-[#181818] hover:border-tv-raven",
        "outline-none outline-offset-0 focus:outline focus:outline-[#f6f6f6]",
        className
      )}
      tabIndex={tabIndex}
      {...props}
    >
      {provider === "google" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 20.82 12.204 C 20.82 11.566 20.763 10.953 20.656 10.364 L 12.18 10.364 L 12.18 13.844 L 17.024 13.844 C 16.924 14.395 16.713 14.92 16.404 15.387 C 16.096 15.854 15.696 16.253 15.228 16.561 L 15.228 18.819 L 18.136 18.819 C 19.838 17.253 20.82 14.945 20.82 12.204 Z"
            fill={disabled ? "#666666" : "#4285f4"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 12.18 21 C 14.61 21 16.647 20.194 18.136 18.82 L 15.228 16.561 C 14.422 17.101 13.391 17.421 12.18 17.421 C 9.836 17.421 7.852 15.837 7.144 13.71 L 4.137 13.71 L 4.137 16.042 C 4.886 17.533 6.035 18.786 7.455 19.662 C 8.876 20.538 10.512 21.001 12.18 21 Z"
            fill={disabled ? "#666666" : "#34a853"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 7.144 13.71 C 6.959 13.159 6.864 12.582 6.862 12 C 6.862 11.407 6.964 10.83 7.144 10.29 L 7.144 7.958 L 4.137 7.958 C 3.507 9.212 3.179 10.597 3.18 12 C 3.18 13.452 3.528 14.827 4.137 16.042 L 7.144 13.71 Z"
            fill={disabled ? "#666666" : "#fbbc05"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 12.18 6.58 C 13.501 6.58 14.688 7.034 15.62 7.926 L 18.202 5.344 C 16.643 3.892 14.606 3 12.18 3 C 10.512 2.999 8.876 3.463 7.455 4.338 C 6.035 5.214 4.886 6.467 4.137 7.958 L 7.144 10.29 C 7.852 8.163 9.836 6.58 12.18 6.58 Z"
            fill={disabled ? "#666666" : "#ea4335"}
          />
        </svg>
      )}
      {provider === "microsoft" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="2.5" y="2.5" width="9" height="9" fill="#f25022" />
          <rect x="2.5" y="12.5" width="9" height="9" fill="#00a4ef" />
          <rect x="12.5" y="2.5" width="9" height="9" fill="#7fba00" />
          <rect x="12.5" y="12.5" width="9" height="9" fill="#ffb900" />
        </svg>
      )}
      {provider === "okta" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 12 3 C 7.026 3 3 7.026 3 12 C 3 16.974 7.026 21 12 21 C 16.974 21 21 16.974 21 12 C 21 7.026 16.974 3 12 3 Z M 12 16.509 C 9.513 16.509 7.491 14.487 7.491 12 C 7.491 9.513 9.513 7.491 12 7.491 C 14.487 7.491 16.509 9.513 16.509 12 C 16.509 14.487 14.487 16.509 12 16.509 Z"
            fill={disabled ? "#666666" : "#007dc1"}
          />
        </svg>
      )}
      {label}
    </div>
  )
}
