import classNames from "classnames"
import { DetailedHTMLProps, HTMLAttributes } from "react"

export default function HelperText({
  children,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      className={classNames(
        "inline-block text-tv-beast text-helper py-3",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
