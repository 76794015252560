import { gql, useLazyQuery, useMutation } from "@apollo/client"
import {
  GetVideoCaptionsQuery,
  GetVideoCaptionsQueryVariables,
  Mp4Quality,
  TranscribeVideoMutation,
  TranscribeVideoMutationVariables,
  VideoTranscriptionStatus,
} from "../../generated/graphql"
import Transcriptions from "./transcriptions"

const GET_CAPTIONS = gql`
  query GetVideoCaptions(
    $videoId: String!
    $afterTimeMs: String
    $beforeTimeMs: String
  ) {
    node(id: $videoId) {
      id
      ... on Video {
        captions(
          args: { after: $afterTimeMs, before: $beforeTimeMs, limit: 100 }
        ) {
          items {
            text
            startTimeMs
            durationMs
            speaker {
              index
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`

const TRANSCRIBE_VIDEO = gql`
  mutation TranscribeVideo($videoId: String!) {
    transcribeVideo(videoId: $videoId) {
      id
      transcriptionStatus
    }
  }
`

export default function TranscriptionsWrapper({
  maxMp4Quality,
  onChangeTime,
  timeMs,
  transcriptionStatus,
  videoId,
}: {
  maxMp4Quality?: Mp4Quality
  onChangeTime: (timeMs: number) => void
  timeMs: number
  transcriptionStatus?: VideoTranscriptionStatus
  videoId: string
}) {
  const [runGetCaptionsQuery] = useLazyQuery<
    GetVideoCaptionsQuery,
    GetVideoCaptionsQueryVariables
  >(GET_CAPTIONS)
  const [runTranscribeVideoMutation] = useMutation<
    TranscribeVideoMutation,
    TranscribeVideoMutationVariables
  >(TRANSCRIBE_VIDEO)

  let status: "missing" | "preparing_video" | "transcribing" | "ready"
  if (maxMp4Quality === undefined) {
    status = "preparing_video"
  } else if (transcriptionStatus === VideoTranscriptionStatus.Ready) {
    status = "ready"
  } else if (transcriptionStatus !== undefined) {
    status = "transcribing"
  } else {
    status = "missing"
  }

  return (
    <Transcriptions
      loadCaptions={async ({ afterTimeMs, beforeTimeMs }) => {
        const { data } = await runGetCaptionsQuery({
          variables: {
            videoId,
            afterTimeMs: afterTimeMs?.toString(),
            beforeTimeMs: beforeTimeMs?.toString(),
          },
        })

        const captionItems =
          data?.node.__typename === "Video" ? data.node.captions.items : []
        const pageInfo =
          data?.node.__typename === "Video"
            ? data.node.captions.pageInfo
            : { hasNextPage: false, hasPreviousPage: false }

        return {
          captions: captionItems.map(caption => ({
            durationMs: caption.durationMs,
            speakerIndex: caption.speaker.index,
            startTimeMs: caption.startTimeMs,
            text: caption.text,
          })),
          hasNextPage: pageInfo.hasNextPage,
          hasPreviousPage: pageInfo.hasPreviousPage,
        }
      }}
      onChangeTime={onChangeTime}
      onStartTranscribingClick={async () => {
        await runTranscribeVideoMutation({ variables: { videoId } })
      }}
      status={status}
      timeMs={timeMs}
    />
  )
}
