import { useCallback } from "react"
import { useIntl } from "react-intl"

const getDurationParts = (durationSeconds: number) => {
  const hours = Math.floor(durationSeconds / 3600)
  const minutes = Math.floor((durationSeconds % 3600) / 60)
  const seconds = Math.floor(durationSeconds % 60)

  return { hours, minutes, seconds }
}

export const useDurationDescription = () => {
  const intl = useIntl()
  const fn = useCallback(
    (durationSeconds: number, format: "long" | "short" | "clock") => {
      const { hours, minutes, seconds } = getDurationParts(durationSeconds)

      switch (format) {
        case "long":
          return [
            hours
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      "{hours, plural, one {# hour} other {# hours}}",
                    description: "Duration description long format hours",
                  },
                  { hours }
                )
              : "",
            minutes
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      "{minutes, plural, one {# minute} other {# minutes}}",
                    description: "Duration description long format minutes",
                  },
                  { minutes }
                )
              : "",
            seconds || (!hours && !minutes)
              ? intl.formatMessage(
                  {
                    defaultMessage:
                      "{seconds, plural, one {# second} other {# seconds}}",
                    description: "Duration description long format seconds",
                  },
                  { seconds }
                )
              : "",
          ]
            .filter(part => !!part)
            .join(" ")
        case "short":
          return [
            hours ? `${hours}h` : "",
            minutes ? `${minutes}m` : "",
            seconds || (!hours && !minutes) ? `${seconds}s` : "",
          ]
            .filter(it => !!it)
            .join(" ")
        case "clock":
          return [
            hours ? hours.toString() : null,
            minutes.toString().padStart(2, "0"),
            seconds.toString().padStart(2, "0"),
          ]
            .filter(it => it !== null)
            .join(":")
        default:
          throw new Error(`Unexpected format: ${format}`)
      }
    },
    [intl]
  )

  return fn
}
