import { useDropzone } from "react-dropzone"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import Button from "../../atoms/button"
import ButtonDiv from "../../atoms/button-div"
import HelperText from "../../atoms/helper-text"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Label from "../../atoms/label"
import MultiTextField from "../../atoms/multi-text-field"
import Select from "../../atoms/select"
import Squircle from "../../atoms/squircle"
import TextField from "../../atoms/text-field"
import ValidationError from "../../atoms/validation-error"

export type ValueType = {
  name: string
  defaultPlaybackRate: number
  logoImage: File | null
  logoImageUri: string | null
  includeEmailSuffixes: Array<string>
  includeEmails: Array<string>
  excludeEmails: Array<string>
}

const PLAYBACK_RATE_ITEMS = [
  {
    value: "100",
    label: "1.00x",
  },
  {
    value: "125",
    label: "1.25x",
  },
  {
    value: "150",
    label: "1.50x",
  },
  {
    value: "175",
    label: "1.75x",
  },
  {
    value: "200",
    label: "2.00x",
  },
]

const getDataUri = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => {
      resolve(reader.result as string)
    })
    reader.addEventListener("abort", () => {
      reject()
    })
    reader.addEventListener("error", () => {
      reject()
    })
    reader.readAsDataURL(file)
  })

export default function EditOrganizationForm({
  disabled,
  onCancelClick,
  onSubmitClick,
  value: valueFromProps,
}: {
  disabled?: boolean
  onCancelClick: () => void
  onSubmitClick: (newValue: ValueType) => void
  value: ValueType
}) {
  const intl = useIntl()
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<ValueType>({
    defaultValues: valueFromProps,
    mode: "all",
  })

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    noDrag: true,
    accept: { "image/png": [], "image/jpeg": [], "image/gif": [] },
    onDropAccepted: async ([newFile]) => {
      setValue("logoImageUri", await getDataUri(newFile))
      setValue("logoImage", newFile)
    },
  })

  const logoImageUri = watch("logoImageUri")

  return (
    <form onSubmit={handleSubmit(onSubmitClick)} className="h-full">
      <div className="flex flex-col h-full">
        <div className="flex-1 overflow-auto px-10 border-b border-tv-coal">
          <div className="border-b border-tv-coal py-8">
            <div>
              <Label htmlFor="name">Company Name</Label>
            </div>
            <TextField
              id="name"
              className="w-full"
              disabled={disabled}
              invalid={!!errors.name}
              maxLength={255}
              {...register("name", {
                required: {
                  value: true,
                  message: intl.formatMessage({
                    defaultMessage: "Name is required",
                    description: "Edit organization form name required message",
                  }),
                },
              })}
            />
            {errors.name && (
              <ValidationError>{errors.name.message!}</ValidationError>
            )}
            <HelperText>
              You can use the name of your company or organization
            </HelperText>
          </div>
          <div className="border-b border-tv-coal py-8">
            <div>
              <Label htmlFor="defaultPlaybackRate">
                Default Playback Speed
              </Label>
            </div>
            <Controller
              name="defaultPlaybackRate"
              control={control}
              render={({ field: { onChange, value, ...otherField } }) => (
                <Select
                  placeholder="Default Playback Speed"
                  items={PLAYBACK_RATE_ITEMS}
                  onChangeValue={newValue => {
                    onChange(parseInt(newValue, 10))
                  }}
                  value={value.toString()}
                  {...otherField}
                />
              )}
            />
            <div>
              <HelperText>
                Set default speed for anyone in the organization.
              </HelperText>
            </div>
          </div>
          <div className="border-b border-tv-coal py-8">
            <div>
              <Label htmlFor="organizationLogo">Logo</Label>
            </div>
            <div className="flex flex-row items-center gap-5">
              <div {...getRootProps()}>
                <Squircle
                  className="w-[64px] h-[64px] bg-tv-milk bg-cover flex justify-center items-center cursor-pointer"
                  style={{
                    backgroundImage: logoImageUri
                      ? `url(${logoImageUri})`
                      : undefined,
                  }}
                  size={64}
                >
                  {!logoImageUri ? (
                    <Icon size="small" color="#fed363" type={IconType.Upload} />
                  ) : null}
                </Squircle>
                <input {...getInputProps()} />
              </div>
              <div {...getRootProps()}>
                <ButtonDiv
                  theme="secondary"
                  label={intl.formatMessage({
                    defaultMessage: "Upload",
                    description: "Edit organization upload button label",
                  })}
                />
                <input {...getInputProps()} />
              </div>
            </div>
            <HelperText>Recommended dimensions 192x192px</HelperText>
          </div>
          <div className="border-b border-tv-coal py-8">
            <Controller
              name="includeEmailSuffixes"
              control={control}
              render={({ field: { onChange, value, ...otherField } }) => (
                <MultiTextField
                  disabled={disabled}
                  label="Allowed Domain List"
                  helperText="Anyone with email addresses from these domains can join your meetings"
                  onChangeValue={onChange}
                  prefix="@"
                  value={value}
                  {...otherField}
                />
              )}
            />
          </div>
          <div className="border-b border-tv-coal py-8">
            <Controller
              name="includeEmails"
              control={control}
              render={({ field: { onChange, value, ...otherField } }) => (
                <MultiTextField
                  disabled={disabled}
                  label="Allowed Email List"
                  helperText="Anyone with these email addresses can join your meetings"
                  onChangeValue={onChange}
                  value={value}
                  {...otherField}
                />
              )}
            />
          </div>
          <div className="py-8">
            <Controller
              name="excludeEmails"
              control={control}
              render={({ field: { onChange, value, ...otherField } }) => (
                <MultiTextField
                  disabled={disabled}
                  label="Denied Email List"
                  helperText="Anyone with these email addresses cannot join your meetings"
                  onChangeValue={onChange}
                  value={value}
                  {...otherField}
                />
              )}
            />
          </div>
        </div>
        <div className="px-10 py-4 border-t border-tv-coal flex flex-row gap-4">
          <Button
            disabled={disabled || !!Object.keys(errors).length}
            label={intl.formatMessage({
              defaultMessage: "Update",
              description: "Edit organization update button label",
            })}
            type="submit"
            theme="primary"
          />
          <Button
            label={intl.formatMessage({
              defaultMessage: "Cancel",
              description: "Edit organization cancel button label",
            })}
            onClick={onCancelClick}
            theme="tertiary"
          />
        </div>
      </div>
    </form>
  )
}
