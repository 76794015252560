import { gql, useMutation, useQuery } from "@apollo/client"
import { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import ButtonDiv from "../atoms/button-div"
import Label from "../atoms/label"
import Link from "../atoms/link"
import {
  GetChannelRoleQuery,
  GetChannelRoleQueryVariables,
  Permission,
  UpdateChannelRolePermissionsMutation,
  UpdateChannelRolePermissionsMutationVariables,
} from "../generated/graphql"
import { UserContext } from "../user-provider"

const QUERY = gql`
  query GetChannelRolePermissions($roleId: String!) {
    node(id: $roleId) {
      id
      ... on Role {
        id
        name
        description
        permissions
        people(args: { limit: 100 }) {
          items {
            id
            name
            email
            profileImageUri
            initials
          }
        }
      }
    }
  }
`

const UPDATE_CHANNEL_ROLE_PERMISSIONS = gql`
  mutation UpdateChannelRolePermissions(
    $roleId: String!
    $permissions: [Permission!]
  ) {
    updateRole(input: { roleId: $roleId, permissions: $permissions }) {
      id
      permissions
    }
  }
`

export default function ChannelRoleEditScreen() {
  const intl = useIntl()
  const { channelSlug } = useParams<{ channelSlug: string }>()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { roleId } = useParams<{ roleId: string }>()
  const { hasPermission } = useContext(UserContext)

  const { data: channelRoleData } = useQuery<
    GetChannelRoleQuery,
    GetChannelRoleQueryVariables
  >(QUERY, {
    variables: {
      roleId: roleId!,
    },
    skip: !roleId,
  })
  const [updateChannelRolePermissions] = useMutation<
    UpdateChannelRolePermissionsMutation,
    UpdateChannelRolePermissionsMutationVariables
  >(UPDATE_CHANNEL_ROLE_PERMISSIONS)

  const role =
    channelRoleData?.node.__typename === "Role" ? channelRoleData?.node : null

  if (!role) {
    return null
  }

  const { name, description, permissions: assignedPermissions } = role
  const allPermissions = Object.values(Permission)

  return (
    <div className="w-screen max-w-[512px]">
      <div className="px-6 pt-6 flex flex-col gap-4 border-b border-tv-coal">
        <h3 className="text-title3 text-center">
          <FormattedMessage
            defaultMessage="Edit Channel Role: {name}"
            values={{ name }}
            description="Edit channel role form title"
          />
        </h3>
        {description}
        {allPermissions.map(permission => (
          <div className="flex" key={permission}>
            <input
              className="mr-2 w-4 h-4"
              type="checkbox"
              id={permission}
              name={permission}
              checked={assignedPermissions.includes(permission)}
              onChange={async () => {
                const isChecked = assignedPermissions.includes(permission)
                // if the box is checked remote the permission
                // if the box is unchecked add the permission
                updateChannelRolePermissions({
                  variables: {
                    roleId: roleId!,
                    permissions: [
                      ...(isChecked
                        ? [
                            // remove the permission
                            ...assignedPermissions.filter(
                              p => p !== permission
                            ),
                          ]
                        : [
                            // add the permission
                            ...assignedPermissions,
                            permission,
                          ]),
                    ],
                  },
                })
              }}
            />
            <Label>
              {/* Replace underscore with space and convert into a sentence */}
              <FormattedMessage
                defaultMessage="{permission}"
                values={{
                  permission: permission
                    .replace(/_/g, " ")
                    .toLowerCase()
                    .replace(/\b\w/g, l => l.toUpperCase()),
                }}
                description="Edit channel role form permission label"
              />
            </Label>
          </div>
        ))}

        {/* TODO: Toggle each permission */}
        {hasPermission(Permission.WriteChannels) ? null : null}

        <div className="py-4 flex flex-row justify-end">
          <Link to={`/${organizationSlug}/channels/${channelSlug}/permissions`}>
            <ButtonDiv
              theme="tertiary"
              label={intl.formatMessage({
                defaultMessage: "Back to Channel Roles",
                description: "Channel permissions form submit button label",
              })}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
