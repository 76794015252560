import classNames from "classnames"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"

export default function Overlay({
  isAccept = false,
  isReject = false,
}: {
  isAccept?: boolean
  isReject?: boolean
}) {
  return (
    <div
      className={classNames(
        "fixed inset-0 bg-[#000000c0] text-[#ffffff] outline-offset-[-30px] outline-dashed outline-2 flex justify-center items-center z-50",
        isAccept && "outline-tv-blue",
        isReject && "outline-tv-red"
      )}
    >
      <div
        className={classNames(
          "h-60 w-40 flex flex-col justify-center items-center rounded-full border-2 border-solid",
          isAccept && "border-tv-blue",
          isReject && "border-tv-red"
        )}
      >
        {isAccept && (
          <>
            <Icon size="large" color="#2286f7" type={IconType.Upload} />
            <p className="text-xl">Drop to upload</p>
          </>
        )}
        {isReject && (
          <>
            <Icon size="large" color="#e31111" type={IconType.Close} />
            <p className="text-xl">Invalid file type</p>
          </>
        )}
      </div>
    </div>
  )
}
