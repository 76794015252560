import classNames from "classnames"
import {
  ChangeEventHandler,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
} from "react"
import { TextFieldProps } from "./types"

const TextField = forwardRef<
  HTMLInputElement,
  TextFieldProps &
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(
  (
    {
      className,
      invalid = false,
      disabled = false,
      onChange,
      onChangeText,
      type = "text",
      ...props
    },
    ref
  ) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      if (onChange) {
        onChange(event)
      }
      if (onChangeText) {
        onChangeText(event.target.value)
      }
    }

    return (
      <input
        className={classNames(
          "appearance-none m-0 px-2 py-[9px] bg-tv-coal rounded-lg border outline-none outline-offset-0 text-[14px] leading-[20px] placeholder:text-tv-dust",
          "focus:outline focus:outline-tv-milk",
          !disabled && "border-tv-coal text-tv-milk",
          disabled && "border-tv-raven text-tv-gunmetal",
          invalid && "border-tv-poppy",
          className
        )}
        disabled={disabled}
        onChange={handleChange}
        ref={ref}
        type={type}
        {...props}
      />
    )
  }
)

TextField.displayName = "TextField"
export default TextField
