import { FormattedMessage } from "react-intl"
import Button from "../../atoms/button"
import { IconType } from "../../atoms/icon/types"

export default function UploadFailedToast({
  onDismissClick,
  videoTitle,
}: {
  onDismissClick: () => void
  videoTitle: string
}) {
  return (
    <div className="w-[300px] flex flex-row justify-between items-center gap-2">
      <div className="flex-1 truncate text-title4 text-tv-poppy">
        <FormattedMessage
          defaultMessage="Video <span>{videoTitle}</span> uploaded"
          description="Upload video complete toast message"
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            span: chunks => <span className="text-tv-milk">{chunks}</span>,
            videoTitle,
          }}
        />
      </div>
      <div>
        <Button
          compact
          icon={IconType.Close}
          theme="tertiary"
          onClick={onDismissClick}
        />
      </div>
    </div>
  )
}
