import env from "../../env"

/**
 * Returns the URI to link to for Google SSO login.
 * @param redirect - The URL to redirect to after logging in. If not provided, uses the current URL.
 * @returns The URI to link to for Google SSO login.
 */
export const getGoogleAuthUri = (
  pkceChallenge: string,
  redirect = window.location.href
) => {
  const redirectUrl = new URL(redirect)
  const currentUrl = new URL(window.location.href)

  const authUrl = new URL("https://accounts.google.com/o/oauth2/v2/auth")
  authUrl.searchParams.append("client_id", env.googleClientId())
  authUrl.searchParams.append(
    "redirect_uri",
    `${currentUrl.protocol}//${currentUrl.host}/oauth-callback`
  )
  authUrl.searchParams.append("response_type", "code")
  authUrl.searchParams.append("code_challenge", pkceChallenge)
  authUrl.searchParams.append("code_challenge_method", "S256")
  authUrl.searchParams.append("scope", "email profile")
  authUrl.searchParams.append(
    "state",
    JSON.stringify({
      provider: "google",
      redirect: `${redirectUrl.protocol}//${redirectUrl.host}${redirectUrl.pathname}${redirectUrl.search}`,
    })
  )
  return authUrl.toString()
}
