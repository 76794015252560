import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import ButtonDiv from "../../atoms/button-div"
import Link from "../../atoms/link"
import TipsIcon from "./tips-icon"

export default function StaticUpcomingEvents() {
  const intl = useIntl()
  return (
    <div className="py-[2rem] w-fit mx-auto">
      <div
        className={classNames(
          "rounded-lg bg-[#dbedff] px-[1rem] flex flex-row items-center gap-4"
        )}
      >
        <div className="basis-[142px] h-[1px]">
          <div className="-translate-y-1/2">
            <TipsIcon />
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-2 py-4">
          <div className="text-title5 text-[#2e6dba] font-semibold">
            <FormattedMessage
              defaultMessage="Looking for upcoming events?"
              description="Static upcoming events title"
            />
          </div>
          <div className="text-subhead2 text-[#2e6dba]">
            <FormattedMessage
              defaultMessage="We're working on bringing over your upcoming events."
              description="Static upcoming events subtitle"
            />
          </div>
        </div>
        <Link to="https://www.venue.live" target="_blank">
          <ButtonDiv
            label={intl.formatMessage({
              defaultMessage: "View events",
              description: "Static upcoming events button label",
            })}
            theme="secondary"
          />
        </Link>
      </div>
    </div>
  )
}
