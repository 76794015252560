// TODO: Remove this screen once we have oauth-callback approved as a callback URL
import { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { useLoginWithOAuth } from "../features/oauth-login/hooks/use-login-with-oauth"
import { UserContext } from "../user-provider"
import ErrorScreen from "./error-screen"

export default function MicrosoftCallbackScreen() {
  const { isLoggedIn } = useContext(UserContext)
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code") || ""
  const { redirect = "" } = JSON.parse(searchParams.get("state") || "{}") as {
    redirect?: string
  }

  const { error } = useLoginWithOAuth({
    provider: "microsoft",
    code,
    redirect,
  })

  if (isLoggedIn === true) {
    window.location.replace(redirect!)
    return null
  }

  if (error) {
    return <ErrorScreen message={error.message} />
  }

  return null
}
