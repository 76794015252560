import { FormattedMessage } from "react-intl"
import Button from "../../atoms/button"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"

export default function OrganizationUpdatedToast({
  onDismissClick,
}: {
  onDismissClick: () => void
}) {
  return (
    <div className="flex items-center gap-4">
      <Icon type={IconType.CheckCircle} color="green" />
      <div className="font-semibold">
        <FormattedMessage
          defaultMessage="Successfully updated"
          description="Organization updated toast message"
        />
      </div>
      <Button
        compact
        icon={IconType.Close}
        onClick={onDismissClick}
        theme="tertiary"
      />
    </div>
  )
}
