import { gql, useMutation, useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import ChannelUpdatedToast from "../features/edit-channel/channel-updated-toast"
import EditChannelForm, {
  ValueType,
} from "../features/edit-channel/edit-channel-form"
import {
  EditChannelGetChannelQuery,
  EditChannelGetChannelQueryVariables,
  EditChannelUpdateMutation,
  EditChannelUpdateMutationVariables,
} from "../generated/graphql"

const QUERY = gql`
  query EditChannelGetChannel(
    $organizationSlug: String!
    $channelSlug: String!
  ) {
    channel(organizationSlug: $organizationSlug, channelSlug: $channelSlug) {
      id
      name
      slugs
      description
    }
  }
`

const UPDATE_CHANNEL = gql`
  mutation EditChannelUpdate(
    $channelId: String!
    $name: String!
    $slugs: [String!]!
    $description: String
  ) {
    updateChannel(
      input: {
        channelId: $channelId
        name: $name
        slugs: $slugs
        description: $description
      }
    ) {
      id
      name
      slugs
      description
    }
  }
`

export default function EditChannelScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const { organizationSlug, channelSlug } = useParams<{
    organizationSlug: string
    channelSlug: string
  }>()
  const { data } = useQuery<
    EditChannelGetChannelQuery,
    EditChannelGetChannelQueryVariables
  >(QUERY, {
    variables: {
      organizationSlug: organizationSlug!,
      channelSlug: channelSlug!,
    },
  })
  const [runUpdateChannelMutation] = useMutation<
    EditChannelUpdateMutation,
    EditChannelUpdateMutationVariables
  >(UPDATE_CHANNEL)

  const handleUpdateChannelClick = async (value: ValueType) => {
    await runUpdateChannelMutation({
      variables: {
        channelId: data!.channel.id,
        name: value.name,
        slugs: value.slugs,
        description: value.description,
      },
      update: cache => {
        // Delete all cached data for organization summary.
        cache.modify({
          id: cache.identify({
            __typename: "ChannelSummary",
            id: data?.channel.id,
          }),
          fields: (_, { DELETE }) => DELETE,
        })
      },
    })
    toast(
      t => <ChannelUpdatedToast onDismissClick={() => toast.dismiss(t.id)} />,
      {
        id: "channel-updated",
      }
    )
    onDiscardClick()
  }

  if (!data?.channel) {
    return null
  }

  return (
    <div className="w-screen max-w-[512px]">
      <EditChannelForm
        currentLocation={window.location.href}
        onDiscardClick={onDiscardClick}
        onUpdateChannelClick={handleUpdateChannelClick}
        initialValue={{
          name: data.channel.name,
          slugs: data.channel.slugs,
          description: data.channel.description || "",
        }}
      />
    </div>
  )
}
