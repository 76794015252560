import { useIntl } from "react-intl"
import DropdownMenu, { DropdownMenuItemType } from "../../atoms/dropdown-menu"
import { IconType } from "../../atoms/icon/types"

export default function VideoOptionsDropdown({
  isDeleted,
  onDeleteVideoClick,
  onUndeleteVideoClick,
}: {
  isDeleted: boolean
  onDeleteVideoClick: () => void
  onUndeleteVideoClick: () => void
}) {
  const intl = useIntl()

  const dropdownMenuItems: Array<DropdownMenuItemType> = []

  if (isDeleted) {
    dropdownMenuItems.push({
      label: intl.formatMessage({ defaultMessage: "Un-archive video" }),
      onClick: onUndeleteVideoClick,
    })
  } else {
    dropdownMenuItems.push({
      icon: IconType.Hidden,
      label: intl.formatMessage({ defaultMessage: "Archive video" }),
      onClick: onDeleteVideoClick,
    })
  }

  return (
    <DropdownMenu
      icon={IconType.Overflow}
      iconSize="large"
      items={dropdownMenuItems}
      theme="tertiary"
    />
  )
}
