import classNames from "classnames"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"

export default function NavItemIcon({
  icon,
  imageUri,
  initials,
}: {
  icon?: IconType
  imageUri?: string
  initials: string
}) {
  return (
    <div
      className={classNames(
        "w-[24px] h-[24px]",
        "flex items-center justify-center",
        "bg-center bg-cover",
        !icon && "bg-tv-beast rounded-full",
        "text-body2 font-semibold"
      )}
      style={{
        backgroundImage: imageUri ? `url(${imageUri})` : undefined,
      }}
    >
      {!imageUri && !icon && initials}
      {!imageUri && icon && <Icon size="medium" type={icon!} />}
    </div>
  )
}
