import { gql, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import {
  GetUpcomingEventsQuery,
  GetUpcomingEventsQueryVariables,
} from "../../generated/graphql"
import UpcomingEventsGrid from "./upcoming-events-grid"

const QUERY = gql`
  query GetUpcomingEvents($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      liveEvents(args: { page: 1 }) {
        items {
          creator {
            initials
            name
            email
          }
          duration
          headerImage
          id
          startsAt
          ticketPage
          title
        }
      }
    }
  }
`

export default function DynamicUpcomingEvents() {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    GetUpcomingEventsQuery,
    GetUpcomingEventsQueryVariables
  >(QUERY, { variables: { organizationSlug: organizationSlug! } })

  const now = Date.now()

  return (
    <UpcomingEventsGrid
      events={(data?.organization.liveEvents.items ?? [])
        // Filter to only future events.
        .filter(event => event.startsAt.getTime() + event.duration * 1000 > now)
        // Order by start time.
        .sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime())
        .map(event => ({
          creatorInitials: event.creator.initials,
          creatorName: event.creator.name,
          id: event.id,
          imageUri: event.headerImage ?? undefined,
          startedAt: event.startsAt,
          title: event.title,
          uri: event.ticketPage,
        }))}
      // events={[
      //   {
      //     creatorInitials: "MD",
      //     creatorName: "Marc Duez",
      //     id: "evt1",
      //     imageUri:
      //       "https://firebasestorage.googleapis.com/v0/b/tryvenue.appspot.com/o/organizations%2FZWqFJP%2Fheaders%2F975a0e5d-015b-4f00-b6e8-96c6a8602b56?alt=media&token=a6a57e8b-91ff-4226-b0aa-174b1f1d28ed",
      //     startedAt: new Date(Date.UTC(2023, 3, 12, 22)),
      //     title: "This is the event title",
      //     uri: "https://app.venue.live/events/venue/total-access-live-venue-fluent-edition-mar-28-2023",
      //   },
      // ]}
    />
  )
}
