import { offset, Placement } from "@popperjs/core"
import classNames from "classnames"
import { ReactNode, useState } from "react"
import { usePopper } from "react-popper"

export default function Tooltip({
  label,
  placement,
  children = null,
  enabled = true,
}: {
  label: ReactNode
  placement: Placement
  children?: ReactNode
  enabled?: Boolean
}) {
  const [visible, setVisible] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)

  const {
    styles,
    attributes,
    update: updatePopper,
  } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ ...offset, options: { offset: [0, 12] } }],
  })

  const showTooltip = () => {
    updatePopper?.()
    setVisible(true)
  }
  const hideTooltip = () => setVisible(false)

  if (!enabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <div
        style={styles.popper}
        {...attributes.popper}
        ref={setPopperElement}
        className={classNames(
          "bg-black px-2 rounded-4xl text-sm",
          !visible && "invisible"
        )}
      >
        {label}
      </div>
      <div ref={setReferenceElement}>{children}</div>
    </div>
  )
}
