import { ChangeEvent } from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import ButtonDiv from "../../atoms/button-div"
import HelperText from "../../atoms/helper-text"
import Label from "../../atoms/label"
import Link from "../../atoms/link"
import TextArea from "../../atoms/text-area"
import TextField from "../../atoms/text-field"
import ValidationError from "../../atoms/validation-error"

export type ValueType = {
  name: string
  description: string
  permissions: Array<string>
}

export default function CreateChannelRoleForm({
  disabled = false,
  onCreateChannelRole,
  onBackClickUri,
}: {
  disabled?: boolean
  onCreateChannelRole: (newValue: ValueType) => void
  onBackClickUri?: string
}) {
  const intl = useIntl()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<ValueType>({
    defaultValues: {
      name: "",
      description: "",
      permissions: [],
    },
    mode: "all",
  })

  return (
    <form onSubmit={handleSubmit(onCreateChannelRole)}>
      <div className="flex flex-col gap-4 border-b border-tv-coal">
        <h3 className="text-title3 text-center">
          <FormattedMessage
            defaultMessage="Channel Permissions"
            description="Modify channel permissions form title"
          />
        </h3>
        {/* Name */}
        <div>
          <div>
            <Label htmlFor="name">
              <FormattedMessage
                defaultMessage="Name"
                description="Create channel role form name label"
              />
            </Label>
          </div>
          <TextField
            id="name"
            className="w-full"
            disabled={disabled}
            invalid={!!errors.name}
            maxLength={255}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter a name for this channel role",
              description: "Create channel role form name placeholder",
            })}
            {...register("name", {
              onChange: (evt: ChangeEvent<HTMLInputElement>) => {
                setValue("name", evt.target.value)
              },
              required: {
                value: true,
                message: intl.formatMessage({
                  defaultMessage: "Name is required",
                  description: "Create channel role form name required message",
                }),
              },
            })}
          />
          {errors.name && (
            <ValidationError>{errors.name.message!}</ValidationError>
          )}
        </div>
        {/* Description */}
        <div>
          <div>
            <Label htmlFor="slug">
              <FormattedMessage
                defaultMessage="Description"
                description="Create channel role form description label"
              />
            </Label>
          </div>
          <TextArea
            rows={3}
            id="description"
            disabled={disabled}
            placeholder={intl.formatMessage({
              defaultMessage:
                "Enter a description for this channel role (optional)",
              description: "Create channel role form description placeholder",
            })}
            autoComplete="off"
            maxLength={1000}
            className="w-full"
            {...register("description")}
          />
          <HelperText className="w-full text-right">
            {`${1000 - watch("description").length} characters remaining`}
          </HelperText>
        </div>
      </div>
      {/* Buttons */}
      <div className="px-6 py-4 flex flex-row justify-end gap-3">
        <Link to={onBackClickUri ?? ""}>
          <ButtonDiv
            theme="tertiary"
            label={intl.formatMessage({
              defaultMessage: "Back to Channel Permissions",
              description: "Create channel role form back button label",
            })}
          />
        </Link>
        <Button
          disabled={disabled || !!Object.keys(errors).length}
          label={intl.formatMessage({
            defaultMessage: "Create Role",
            description: "Create channel role form submit button label",
          })}
          type="submit"
        />
      </div>
    </form>
  )
}
