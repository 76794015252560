export default function TranscriptionPlaceholder() {
  return (
    <>
      <div className="flex flex-col gap-3 mb-8">
        <div className="flex flex-row justify-between items-center h-4">
          <div className="basis-[40%] h-full bg-tv-beast rounded-full" />
          <div className="basis-[15%] h-full bg-tv-beast rounded-full" />
        </div>
        <div className="flex flex-row items-center h-4">
          <div className="basis-[70%] h-full bg-tv-beast rounded-full" />
        </div>
        <div className="flex flex-row items-center h-4">
          <div className="basis-[80%] h-full bg-tv-beast rounded-full" />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row justify-between items-center h-4">
          <div className="basis-[30%] h-full bg-tv-beast rounded-full" />
          <div className="basis-[15%] h-full bg-tv-beast rounded-full" />
        </div>
        <div className="flex flex-row items-center h-4">
          <div className="basis-[85%] h-full bg-tv-beast rounded-full" />
        </div>
        <div className="flex flex-row items-center h-4">
          <div className="basis-[80%] h-full bg-tv-beast rounded-full" />
        </div>
      </div>
    </>
  )
}
