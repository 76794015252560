import classNames from "classnames"
import type { ButtonProps } from "../button/types"

/**
 * Label for a <Button /> or <ButtonDiv /> component.
 */
export default function ButtonLabel({
  compact,
  disabled,
  theme,
  value,
}: {
  compact: boolean
  disabled: boolean
  theme: Required<ButtonProps>["theme"]
  value: string
}) {
  return (
    <div
      className={classNames(
        "font-sans font-semibold leading-none",
        compact ? "text-[13px]" : "text-[15px]",
        !disabled && theme === "primary" && "text-tv-gunmetal",
        !disabled && theme === "secondary" && "text-tv-gunmetal",
        !disabled && theme === "tertiary" && "text-tv-milk",
        !disabled && theme === "danger" && "text-tv-poppy",
        disabled && "text-tv-gunmetal"
      )}
    >
      {value}
    </div>
  )
}
