import {
  DataRef,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  DroppableContainer,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers"
import { SortableContext, SortableData } from "@dnd-kit/sortable"
import classNames from "classnames"
import { Fragment, useContext, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import {
  ChannelFolderNavItem as GraphQLChannelFolderNavItem,
  ChannelNavItem as GraphQLChannelNavItem,
  NavItem as GraphQLNavItem,
  Permission,
} from "../../generated/graphql"
import { UserContext } from "../../user-provider"
import { IdType, entityId } from "../../utils/entity-id"
import ChannelHeader from "./channel-header"
import ChannelNavItem from "./channel-nav-item"
import CreateChannelDivider from "./create-channel-divider"
import FolderNavItem from "./folder-nav-item"
import NavItemWithIcon from "./nav-item-with-icon"
import { SideNavContext } from "./side-nav-provider"
import UploadBanner from "./upload-banner"

export default function ExpandedSideNav({
  navItems,
  onCollapseClick,
  onMoveNavItemIntoFolder,
  onReorderNavItem,
  organizationLogoImageUri,
}: {
  navItems: Array<GraphQLNavItem>
  onCollapseClick: () => void
  onMoveNavItemIntoFolder: (
    navItemId: string,
    parentFolderId: string | null
  ) => void
  onReorderNavItem: (navItemId: string, beforeNavItemId: string | null) => void
  organizationLogoImageUri?: string
}) {
  const intl = useIntl()
  const { isLoggedIn, hasPermission, logOut } = useContext(UserContext)
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const {
    draggingType,
    isFolderExpanded,
    isInitiallyExpanded,
    setDraggingType,
    setExpanded,
  } = useContext(SideNavContext)
  const [overFolderId, setOverFolderId] = useState("")
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 24 } })
  )

  const handleDragCancel = () => {
    setExpanded(false)
    setDraggingType(null)
    setOverFolderId("")
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const activeId = String(event.active.id)
    const overId = event.over ? String(event.over.id) : null
    const sortableData = (event.active.data as DataRef<SortableData>).current
    const items = (sortableData?.sortable.items ?? []).map(item => String(item))

    if (!overId) {
      return
    }

    if (overFolderId) {
      onMoveNavItemIntoFolder(activeId, overFolderId)
    } else {
      const activeIndex = items.findIndex(item => item === activeId)
      const overIndex = items.findIndex(item => item === overId)
      const beforeNavItemId =
        items[overIndex > activeIndex ? overIndex + 1 : overIndex] ?? null
      onReorderNavItem(activeId, beforeNavItemId)
    }

    setExpanded(false)
    setDraggingType(null)
    setOverFolderId("")
  }

  const handleDragMove = (evt: DragMoveEvent) => {
    const {
      active: { id: activeId },
      activatorEvent: { clientX, clientY },
      collisions,
      delta: { x: deltaX, y: deltaY },
    } = evt as DragMoveEvent & { activatorEvent: PointerEvent }
    if (!entityId.is(String(activeId), IdType.Channel)) {
      return
    }

    const cursorX = Math.round(clientX + deltaX)
    const cursorY = Math.round(clientY + deltaY)

    const collisionFolderId = (collisions ?? [])
      .filter(collision => {
        const collisionId = String(collision.id)
        const droppableContainer = collision.data?.droppableContainer as
          | DroppableContainer
          | undefined
        return (
          entityId.is(collisionId, IdType.ChannelFolder) &&
          droppableContainer?.disabled !== true
        )
      })
      .find(collision => {
        const droppableContainer = collision.data?.droppableContainer as
          | DroppableContainer
          | undefined
        const rect = droppableContainer?.node.current?.getBoundingClientRect()
        return (
          !!rect &&
          cursorX >= rect.left &&
          cursorX <= rect.right &&
          cursorY >= rect.top &&
          cursorY <= rect.bottom
        )
      })?.id as string | undefined
    setOverFolderId(collisionFolderId ?? "")
  }

  const handleDragStart = (evt: DragStartEvent) => {
    setExpanded(true)
    const idType = entityId.type(String(evt.active.id))
    switch (idType) {
      case IdType.Channel:
        setDraggingType("channel")
        break
      case IdType.ChannelFolder:
        setDraggingType("channel-folder")
        break
      default:
        break
    }
  }

  const canWriteChannels = hasPermission(Permission.WriteChannels)

  const channelList = (
    <>
      {navItems.map(navItem => {
        if (navItem.__typename === "ChannelNavItem") {
          const channelNavItem = navItem as GraphQLChannelNavItem

          return (
            <ChannelNavItem
              key={channelNavItem.id}
              draggable={canWriteChannels && draggingType !== "video"}
              droppable={hasPermission(
                Permission.WriteVideos,
                channelNavItem.id
              )}
              id={channelNavItem.id}
              logoImageUri={channelNavItem.logoImageUri ?? undefined}
              name={channelNavItem.name}
              slug={channelNavItem.slugs[0]}
            />
          )
        }

        if (navItem.__typename === "ChannelFolderNavItem") {
          const channelFolderNavItem = navItem as GraphQLChannelFolderNavItem
          const isExpanded = isFolderExpanded(channelFolderNavItem.id)
          return (
            <Fragment key={channelFolderNavItem.id}>
              <FolderNavItem
                draggable={canWriteChannels && draggingType !== "video"}
                droppable={draggingType !== "video"}
                id={channelFolderNavItem.id}
                isOver={overFolderId === channelFolderNavItem.id}
                name={channelFolderNavItem.name}
              />
              {channelFolderNavItem.children
                .filter(
                  childNavItem =>
                    isExpanded && childNavItem.__typename === "ChannelNavItem"
                )
                .map(childNavItem => {
                  const channelNavItem = childNavItem as GraphQLChannelNavItem
                  return (
                    <ChannelNavItem
                      key={channelNavItem.id}
                      draggable={canWriteChannels && draggingType !== "video"}
                      droppable={hasPermission(
                        Permission.WriteVideos,
                        channelNavItem.id
                      )}
                      id={channelNavItem.id}
                      logoImageUri={channelNavItem.logoImageUri ?? undefined}
                      name={channelNavItem.name}
                      nested
                      slug={channelNavItem.slugs[0]}
                    />
                  )
                })}
            </Fragment>
          )
        }

        return null
      })}
    </>
  )

  const flattenedNavItemIds = navItems.reduce<Array<string>>(
    (arr, item) => [
      ...arr,
      item.id,
      ...(item.__typename === "ChannelFolderNavItem"
        ? item.children.map(child => child.id)
        : []),
    ],
    []
  )

  return (
    <div
      className={classNames(
        "w-[280px] h-full px-5 py-4",
        "bg-tv-coal",
        "flex flex-col justify-start"
      )}
    >
      <div className="h-[32px] flex flex-row justify-end">
        {!isInitiallyExpanded && (
          <button
            className="appearance-none w-[32px] h-[32px] flex items-center justify-center"
            onClick={onCollapseClick}
            type="button"
          >
            <Icon size="small" type={IconType.Collapse} />
          </button>
        )}
      </div>
      <ChannelHeader />
      <div className="overflow-y-auto">
        <div className="flex flex-col gap-1">
          {draggingType !== "video" ? (
            <DndContext
              // collisionDetection={folderAwareCollisionDetection}
              modifiers={[
                restrictToVerticalAxis,
                restrictToFirstScrollableAncestor,
              ]}
              onDragCancel={handleDragCancel}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMove}
              onDragStart={handleDragStart}
              sensors={sensors}
            >
              <SortableContext items={flattenedNavItemIds}>
                {channelList}
              </SortableContext>
            </DndContext>
          ) : (
            channelList
          )}
        </div>
        {canWriteChannels ? <CreateChannelDivider expanded /> : null}
      </div>
      <div className="flex-1 flex flex-col justify-end gap-6">
        {hasPermission(Permission.WriteVideos) && <UploadBanner />}
        <div className="flex flex-col gap-1">
          {hasPermission(Permission.UpdateOrganization) && (
            <NavItemWithIcon
              imageUri={organizationLogoImageUri}
              href={`/${organizationSlug}/edit`}
              name={intl.formatMessage({
                defaultMessage: "Workspace Settings",
                description: "Workspace Settings nav item label",
              })}
            />
          )}
          {isLoggedIn === true && (
            <button type="button" onClick={logOut} className="appearance-none">
              <NavItemWithIcon
                icon={IconType.Logout}
                name={intl.formatMessage({
                  defaultMessage: "Logout",
                  description: "Logout nav item label",
                })}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
