import { SVGProps, forwardRef } from "react"
import SvgFragment from "./svg-fragment"
import { IconType } from "./types"

const Icon = forwardRef<
  SVGSVGElement,
  {
    size?: "small" | "medium" | "large"
    type: IconType
  } & Omit<SVGProps<SVGSVGElement>, "height" | "width" | "viewBox" | "xmlns">
>(
  (
    { color = "#666666", fill = "none", size = "medium", type, ...props },
    ref
  ) => {
    let dimension: number
    switch (size) {
      case "small":
        dimension = 16
        break
      case "medium":
        dimension = 24
        break
      default:
        dimension = 32
        break
    }

    return (
      <svg
        color={color}
        fill={fill}
        height={dimension}
        ref={ref}
        viewBox="0 0 32 32"
        width={dimension}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <SvgFragment type={type} />
      </svg>
    )
  }
)

Icon.displayName = "Icon"
export default Icon
