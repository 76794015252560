import classNames from "classnames"
import { useDropzone } from "react-dropzone"
import { FormattedMessage } from "react-intl"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"

export default function DropTarget({
  file,
  onChangeFile,
}: {
  file?: File
  onChangeFile: (newFile: File) => void
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    accept: { "video/*": [] },
    onDropAccepted: ([newFile]) => onChangeFile(newFile),
  })

  return (
    <div
      className={classNames(
        "bg-tv-coal rounded-4xl h-[250px] flex flex-col items-center justify-center cursor-pointer",
        "border border-dashed border-[#2286f7]"
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {!isDragActive && !file && (
        <>
          <Icon size="large" color="#2286f7" type={IconType.Upload} />
          <p className="text-title5 font-semibold mt-2">
            <FormattedMessage
              defaultMessage="Drop to upload"
              description="Upload video drop target accept title"
            />
          </p>
          <p className="text-subhead3 text-tv-dust mt-2">
            <FormattedMessage
              defaultMessage="or click to browse"
              description="Upload video drop target subtitle"
            />
          </p>
        </>
      )}
      {!isDragActive && !!file && (
        <p className="text-title5 font-semibold mt-2">{file.name}</p>
      )}
      {isDragAccept && (
        <>
          <Icon size="large" color="#2286f7" type={IconType.Upload} />
          <p className="text-xl">
            <FormattedMessage
              defaultMessage="Drop to upload"
              description="Upload video drop target accept title"
            />
          </p>
        </>
      )}
      {isDragReject && (
        <>
          <Icon size="large" color="#e31111" type={IconType.Close} />
          <p className="text-xl">
            <FormattedMessage
              defaultMessage="Invalid file type"
              description="Upload video drop target reject title"
            />
          </p>
        </>
      )}
    </div>
  )
}
