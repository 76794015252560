import { gql, useMutation } from "@apollo/client"
import CreateOrganizationForm, {
  ValueType,
} from "../features/create-organization/create-organization-form"
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
} from "../generated/graphql"

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $slug: String!, $email: String!) {
    createOrganization(
      input: {
        name: $name
        slug: $slug
        includeEmails: [$email]
        defaultPermissions: [
          READ_CHANNELS
          READ_LIVE_EVENTS
          READ_PLAYLISTS
          READ_VIDEOS
          READ_WRITE_PERMISSIONS
          UPDATE_ORGANIZATION
          WRITE_CHANNELS
          WRITE_LIVE_EVENTS
          WRITE_PEOPLE
          WRITE_PLAYLISTS
          WRITE_VIDEOS
        ]
      }
    ) {
      id
      slugs
    }
  }
`

export default function CreateOrganizationScreen({
  onDiscardClick,
}: {
  onDiscardClick: () => void
}) {
  const [runCreateOrganizationMutation, { loading }] = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CREATE_ORGANIZATION)

  const handleCreateOrganizationClick = async (value: ValueType) => {
    const result = await runCreateOrganizationMutation({
      variables: {
        name: value.name,
        slug: value.slug,
        email: value.email,
      },
    })

    const url = new URL(window.location.href)
    window.location.replace(
      `${url.protocol}//${url.host}/${result.data!.createOrganization.slugs[0]}`
    )
  }

  return (
    <div className="w-screen max-w-[512px]">
      <CreateOrganizationForm
        currentLocation={window.location.href}
        disabled={loading}
        onCreateOrganizationClick={handleCreateOrganizationClick}
        onDiscardClick={onDiscardClick}
      />
    </div>
  )
}
