/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames"
import { ReactNode } from "react"
import ReactModal from "react-modal"

export default function ModalContainer({
  anchor = "middle",
  children,
  height = "auto",
  isOpen,
  onDismiss,
}: {
  anchor?: "middle" | "top"
  children?: ReactNode
  height?: "auto" | "stretch"
  isOpen: boolean
  onDismiss: () => void
}) {
  return (
    <ReactModal
      className={classNames(
        "absolute inset-[32px]",
        "overflow-y-auto",
        "rounded-2xl bg-tv-obsidian drop-shadow-[0_0_10px_rgba(0,0,0,0.5)]",
        "w-min max-w-[calc(100vw-64px)] left-[50%] -translate-x-1/2",
        "max-h-[calc(100vh-64px)]",
        height === "auto" && "h-min",
        height === "stretch" && "h-screen",
        anchor === "middle" && "top-[50%] -translate-y-1/2",
        anchor === "top" && "top-[32px]"
      )}
      isOpen={isOpen}
      onRequestClose={onDismiss}
      overlayClassName="fixed inset-0 bg-[#000000a0] z-30"
    >
      {children}
    </ReactModal>
  )
}
