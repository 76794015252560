/* eslint-disable no-console */
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { withScalars } from "apollo-link-scalars"
import { createUploadLink } from "apollo-upload-client"
import { IntrospectionQuery, buildClientSchema } from "graphql"
import { getUserToken, subscribeToUserTokenChanges } from "./auth"
import env from "./env"
import schema from "./generated/schema.json"
import { getRollbarInstance } from "./get-rollbar-instance"
import { isConflictError } from "./utils/is-conflict-error"
import { isNotFoundError } from "./utils/is-not-found-error"

let loggedIn = !!getUserToken()
const rollbar = getRollbarInstance()

const authLink = setContext(async (_, { headers, ...prevContext }) => {
  const userToken = getUserToken()
  return {
    ...prevContext,
    headers: {
      ...headers,
      ...(userToken ? { Authorization: `Bearer ${userToken}` } : {}),
    },
  }
})

const httpLink = createUploadLink({
  uri: `${env.apiBaseUri()}graphql`,
  // Apollo server's CSRF protection requires that we include the Apollo-Require-Preflight header.
  // See https://www.apollographql.com/docs/apollo-server/security/cors/#preventing-cross-site-request-forgery-csrf
  headers: { "Apollo-Require-Preflight": "true" },
})

const scalarLink = withScalars({
  schema: buildClientSchema(schema as unknown as IntrospectionQuery),
  typesMap: {
    Date: {
      serialize: (value: unknown) => (value as Date).toISOString().slice(0, 10),
      parseValue: (value: unknown) => new Date(`${value}T00:00:00Z`),
    },
    DateTime: {
      serialize: (value: unknown) => (value as Date).toISOString(),
      parseValue: (value: unknown) => new Date(value as string),
    },
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  ;(graphQLErrors ?? [])
    .filter(error => !isNotFoundError(error) && !isConflictError(error))
    .forEach(error => {
      console.error(error)
      rollbar.error(error.message, error)
    })

  if (networkError) {
    console.error(networkError)
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Organization: {
      fields: {
        navItems: {
          merge: (_, incoming) => incoming,
        },
      },
    },
  },
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, scalarLink, errorLink, httpLink]),
  cache,
})

subscribeToUserTokenChanges(async newUserToken => {
  const newLoggedIn = !!newUserToken
  if (newLoggedIn === loggedIn) {
    return
  }

  loggedIn = newLoggedIn
  if (newLoggedIn === false) {
    await apolloClient.clearStore()
  }
})
