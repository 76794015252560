import classNames from "classnames"
import {
  ChangeEventHandler,
  DetailedHTMLProps,
  forwardRef,
  TextareaHTMLAttributes,
} from "react"

const TextArea = forwardRef<
  HTMLTextAreaElement,
  DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > & { invalid?: boolean; onChangeText?: (text: string) => void }
>(({ className, disabled, invalid, onChange, onChangeText, ...props }, ref) => {
  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    if (onChange) {
      onChange(event)
    }
    if (onChangeText) {
      onChangeText(event.target.value)
    }
  }

  return (
    <textarea
      className={classNames(
        "appearance-none m-0 p-[9px] rounded-lg inline-block align-top border",
        "outline-none outline-offset-0 focus:outline focus:outline-tv-milk",
        "text-[14px] leading-[20px]",
        "bg-tv-coal placeholder:text-tv-dust",
        !disabled && "border-tv-coal text-tv-milk",
        disabled && "border-tv-raven text-tv-gunmetal",
        !disabled && invalid && "border-tv-poppy",

        className
      )}
      disabled={disabled}
      onChange={handleChange}
      ref={ref}
      {...props}
    />
  )
})

TextArea.displayName = "TextArea"
export default TextArea
