import { useContext } from "react"
import Link from "../../atoms/link"
import { Permission } from "../../generated/graphql"
import { ScreenSize, useScreenSize } from "../../hooks/use-screen-size"
import { UserContext } from "../../user-provider"
import ChannelTile from "./channel-tile"
import CreateChannelTile from "./create-channel-tile"

const TILES_BY_SCREEN_SIZE: Record<ScreenSize, number> = {
  sm: 4,
  md: 2,
  lg: 3,
  xl: 4,
  "2xl": 4,
}
export default function ChannelGrid({
  channels,
  isLoading = false,
}: {
  channels: Array<{
    coverImageUri?: string
    episodeCount: number
    id: string
    logoImageUri?: string
    name: string
    slug: string
  }>
  isLoading: boolean
}) {
  const { hasPermission } = useContext(UserContext)
  const screenSize = useScreenSize()

  const hasWriteChannelsPermission = hasPermission(Permission.WriteChannels)

  // Pick as many channels as will fit in the grid
  let channelCount = TILES_BY_SCREEN_SIZE[screenSize]
  if (hasWriteChannelsPermission) {
    channelCount -= 1
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6">
      {isLoading
        ? Array.from(new Array(channelCount)).map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`channel-placeholder-${index}`}
              className="aspect-[8/7] max-w-[400px] rounded-xl overflow-hidden bg-tv-coal"
            />
          ))
        : channels
            .slice(0, channelCount)
            .map(
              ({
                coverImageUri,
                episodeCount,
                id,
                logoImageUri,
                name,
                slug,
              }) => (
                <Link key={id} to={`channels/${slug}`}>
                  <ChannelTile
                    coverImageUri={coverImageUri}
                    logoImageUri={logoImageUri}
                    name={name}
                    episodeCount={episodeCount}
                  />
                </Link>
              )
            )}
      {hasWriteChannelsPermission && (
        <Link to="create-channel">
          <CreateChannelTile />
        </Link>
      )}
    </div>
  )
}
