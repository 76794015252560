import axios from "axios"
import jwt_decode from "jwt-decode"
import env from "./env"
import { getOrganizationSlug } from "./utils/get-organization-slug"

// User token is null when user is not logged in, non-null when user is logged in, and undefined when initially loading.
let userToken: string | null | undefined
const subscribers: Array<(newUserToken: string | null) => void> = []
let refreshTimeout: ReturnType<typeof setTimeout> | null = null

const refreshUserToken = async () => {
  try {
    const response = await axios.post<{ userToken: string }>(
      `${env.apiBaseUri()}auth/refresh`,
      { organizationSlug: getOrganizationSlug() },
      { withCredentials: true }
    )

    if (response.data.userToken) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setUserToken(response.data.userToken)
      return
    }
  } catch (error) {
    if (!axios.isAxiosError(error) || error.response?.status !== 404) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  setUserToken(null)
}

/**
 * Returns the current user token.
 * @returns The current user token, or null if no user token is set, or undefined when user token is still being loaded.
 */
export const getUserToken = () => userToken

/**
 * Returns the current user token parsed.
 * @returns An object containing the claims of the current user token.
 */
export const getUserTokenClaims = () => {
  const {
    aud: organizationId = null,
    email = null,
    sub: personId = null,
    superAdmin = false,
  } = userToken
    ? jwt_decode<{
        aud?: string
        email?: string
        sub?: string
        superAdmin?: boolean
      }>(userToken)
    : {}
  return {
    email,
    organizationId,
    personId,
    superAdmin,
  }
}

const getUserTokenExpiredAt = (token: string | null) => {
  const expiredAt = token ? jwt_decode<{ exp?: number }>(token).exp : undefined
  return expiredAt ? new Date(expiredAt * 1000) : null
}

/**
 * Sets the current user token. Notifies subscribers of the change.
 * @param newUserToken - The user token to set.
 */
export const setUserToken = (newUserToken: string | null) => {
  // Only use new value if token is not expired.
  const expiredAt = getUserTokenExpiredAt(newUserToken)
  const tokenToSet =
    expiredAt && expiredAt.valueOf() > Date.now() ? newUserToken : null

  if (userToken === tokenToSet) {
    return
  }

  if (refreshTimeout) {
    clearTimeout(refreshTimeout)
    refreshTimeout = null
  }

  if (expiredAt !== null) {
    // Refresh token 60s before token expiry.
    const now = Date.now()
    const sixtySecondsBeforeExpiry = expiredAt.valueOf() - 60 * 1000
    const timeoutMs = Math.max(
      0,
      sixtySecondsBeforeExpiry.valueOf() - now.valueOf()
    )
    refreshTimeout = setTimeout(refreshUserToken, timeoutMs)
  }

  userToken = newUserToken
  ;[...subscribers].forEach(s => s(newUserToken))
}

/**
 * Subscribes to changes in the user token.
 * @param subscriber - The subscriber to add.
 */
export const subscribeToUserTokenChanges = (
  subscriber: (newUserToken: string | null) => void
) => {
  if (subscribers.indexOf(subscriber) < 0) {
    subscribers.push(subscriber)
  }
}

/**
 * Unsubscribes from changes in the user token.
 * @param subscriber - The subscriber to remove.
 */
export const unsubscribeFromUserTokenChanges = (
  subscriber: (newUserToken: string | null) => void
) => {
  const index = subscribers.indexOf(subscriber)
  if (index >= 0) {
    subscribers.splice(index, 1)
  }
}

refreshUserToken()
