export default function Avatar({
  initials,
  profileImageUri,
}: {
  initials: string
  profileImageUri?: string
}) {
  return (
    <div
      className="w-[32px] h-[48px] rounded-full bg-cover bg-center overflow-hidden bg-tv-milk flex items-center justify-center"
      style={{
        backgroundImage: profileImageUri
          ? `url(${profileImageUri})`
          : undefined,
      }}
    >
      {!profileImageUri && (
        <span className="text-body2 font-semibold text-tv-coal">
          {initials}
        </span>
      )}
    </div>
  )
}
