import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { Autoplay, EffectCards } from "swiper"
import "swiper/css"
import "swiper/css/effect-cards"
import { Swiper, SwiperSlide } from "swiper/react"
import Link from "../../atoms/link"
import { useDurationDescription } from "../../hooks/use-duration-description"
import PlayIcon from "./play-icon"

export default function VideoCarousel({
  isLoading = false,
  videos,
}: {
  isLoading: boolean
  videos: Array<{
    channelName?: string
    durationSeconds: number
    eventAttendeeCount?: number
    id: string
    thumbnailUri?: string
    title: string
  }>
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const getDurationDescription = useDurationDescription()

  return isLoading ? (
    <div className="mx-auto bg-tv-coal w-[75%] rounded-4xl aspect-video">
      &nbsp;
    </div>
  ) : (
    <Swiper
      autoplay={{ delay: 5000 }}
      cardsEffect={{
        perSlideOffset: 12.5,
        slideShadows: false,
        rotate: false,
      }}
      className="w-[75%]"
      effect="cards"
      grabCursor
      modules={[Autoplay, EffectCards]}
    >
      {videos.map(video => (
        <SwiperSlide
          key={video.id}
          className="w-full aspect-video drop-shadow-[0_0_15px_rgba(0,0,0,0.3)]"
        >
          {({ isActive }) => {
            const slide = (
              <div
                className={classNames(
                  "h-full relative overflow-hidden rounded-4xl",
                  isActive && "group"
                )}
              >
                {/* Background */}
                <div
                  className={classNames(
                    "absolute inset-0 bg-tv-coal bg-center bg-cover",
                    "transition ease-in-out duration-300",
                    "group-hover:scale-110"
                  )}
                  style={{
                    backgroundImage: video.thumbnailUri
                      ? `url(${video.thumbnailUri})`
                      : undefined,
                  }}
                />
                {/* Gradient */}
                {isActive && (
                  <div
                    className={classNames(
                      "absolute bottom-0 inset-x-0 h-[30%] origin-bottom",
                      "bg-gradient-to-b from-transparent to-[#000000c0]",
                      "transition ease-in-out duration-300",
                      "group-hover:scale-y-[1.5]"
                    )}
                  />
                )}
                {/* Text */}
                <div className="relative h-full px-8 py-6 flex flex-col gap-3 justify-end">
                  <div className="text-title2 transition ease-in-out duration-300 group-hover:text-tv-dandelion">
                    {video.title}
                  </div>
                  <div className="flex flex-row gap-3 text-title5">
                    <span>
                      {getDurationDescription(video.durationSeconds, "long")}
                    </span>
                    {video.eventAttendeeCount !== undefined && (
                      <span>
                        <FormattedMessage
                          defaultMessage="{attendeeCount, plural, one {# attendee} other {# attendees}}"
                          description="Video carousel video attendee count"
                          values={{
                            attendeeCount: video.eventAttendeeCount,
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* Play Icon */}
                <div
                  className={classNames(
                    "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-[0px_0px_10px_#000000c0]"
                  )}
                >
                  <PlayIcon />
                </div>
                {/* Shadow */}
                {!isActive && (
                  <div
                    className={classNames(
                      "absolute inset-0 bg-tv-obsidian opacity-60"
                    )}
                  />
                )}
              </div>
            )
            return isActive ? (
              <Link to={`/${organizationSlug}/videos/${video.id.slice(4)}`}>
                {slide}
              </Link>
            ) : (
              slide
            )
          }}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
