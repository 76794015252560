import { useParams } from "react-router-dom"
import Link from "../../atoms/link"
import VideoResult from "./video-result"

export default function VideoResultList({
  results,
}: {
  results: Array<{
    id: string
    title: string
    thumbnailUri?: string
    date: Date
    durationSeconds: number
  }>
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()

  return (
    <div className="flex flex-col gap-2">
      {results.map(({ id, title, thumbnailUri, date, durationSeconds }) => (
        <Link key={id} to={`/${organizationSlug}/videos/${id.slice(4)}`}>
          <VideoResult
            title={title}
            thumbnailUri={thumbnailUri}
            date={date}
            durationSeconds={durationSeconds}
          />
        </Link>
      ))}
    </div>
  )
}
