import { forwardRef } from "react"
import { LinkProps, Link as RouterLink } from "react-router-dom"

const isExternalLink = (str: string) => {
  try {
    const url = new URL(str)
    return !!url.protocol
  } catch (err) {
    return false
  }
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, to, ...props }, ref) => {
    if (typeof to === "string" && isExternalLink(to)) {
      return (
        <a href={to} ref={ref} {...props}>
          {children}
        </a>
      )
    }

    return (
      <RouterLink to={to} ref={ref} {...props}>
        {children}
      </RouterLink>
    )
  }
)

Link.displayName = "Link"
export default Link
