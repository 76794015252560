import classNames from "classnames"
import { FormattedDate, FormattedRelativeTime } from "react-intl"

export default function EventTile({
  creatorInitials,
  creatorName,
  imageUri,
  startedAt,
  title,
}: {
  creatorInitials?: string
  creatorName?: string
  imageUri?: string
  startedAt: Date
  title: string
}) {
  const startedAtOffsetSeconds = Math.floor(
    (startedAt.valueOf() - Date.now()) / 1000
  )

  return (
    <div className="group aspect-[8/7] max-w-[400px] flex flex-col justify-between">
      <div className="relative aspect-video p-2 flex flex-col justify-start items-start">
        <div
          className={classNames(
            "absolute inset-0 rounded-xl",
            "bg-tv-coal bg-center bg-cover",
            "transition ease-in-out duration-300",
            "group-hover:scale-105"
          )}
          style={{
            backgroundImage: imageUri ? `url(${imageUri})` : undefined,
          }}
        />
        <div className="absolute left-2 top-2 aspect-square bg-tv-milk rounded-lg h-[2.2rem] flex flex-col justify-center items-center">
          <p className="text-tv-beast text-subhead2 font-bold tracking-wide">
            <FormattedDate value={startedAt} day="2-digit" />
          </p>
          <p className="text-tv-beast text-subhead4 tracking-wider uppercase">
            <FormattedDate value={startedAt} month="short" />
          </p>
        </div>
      </div>
      <div className="text-title3 font-bold transition ease-in-out duration-300 group-hover:text-tv-dandelion">
        {title}
      </div>
      <div className="flex flex-row justify-between items-center gap-2">
        <div className="flex flex-row items-center gap-2">
          <div
            className={classNames(
              "w-[24px] h-[24px] rounded-full",
              "flex items-center justify-center",
              "bg-tv-beast bg-center bg-cover",
              "text-body2 font-semibold"
            )}
          >
            {creatorInitials}
          </div>
          <p className="font-semibold">{creatorName}</p>
        </div>
        <p className="text-tv-dust">
          <FormattedRelativeTime
            value={startedAtOffsetSeconds}
            updateIntervalInSeconds={30}
          />
        </p>
      </div>
    </div>
  )
}
