import { FormattedMessage, useIntl } from "react-intl"
import Button from "../../atoms/button"
import Label from "../../atoms/label"
import Link from "../../atoms/link"
import { Role } from "../../generated/graphql"
import ChannelPersonRoleView from "./channel-person-role-view"

export type ValueType = {
  name: string
}

export default function ChannelPermissionsListView({
  organizationSlug,
  channelSlug,
  channelRoles,
  onDiscardClick,
  onDeleteClick,
}: {
  organizationSlug: string
  channelSlug: string
  channelRoles: Array<Partial<Role>>
  onDiscardClick: () => void
  onDeleteClick: (roleId: string) => void
}) {
  const intl = useIntl()
  return (
    <div className="px-6 pt-6 flex flex-col gap-4 border-b border-tv-coal">
      <h3 className="text-title3 text-center">
        <FormattedMessage
          defaultMessage="Channel Permissions"
          description="Modify channel permissions form title"
        />
      </h3>

      {/* Channel Role View */}
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between">
          <Label className="pb-0">
            <FormattedMessage
              defaultMessage="Channel roles"
              description="Channel permissions form roles title"
            />
          </Label>
          <Link
            to={`/${organizationSlug}/channels/${channelSlug}/permissions/create`}
          >
            <span className="text-subhead4 text-tv-dandelion">
              <FormattedMessage
                defaultMessage="+ Create Role"
                description="Channel permissions form create role label"
              />
            </span>
          </Link>
        </div>

        {channelRoles.map(role => (
          <div key={`channel-role-${role.id}`} className="flex flex-col">
            <ChannelPersonRoleView
              roleName={role.name!}
              roleDescription={role.description ?? ""}
              assignRolePathUri={`/${organizationSlug}/channels/${channelSlug}/permissions/${role.id}/assign`}
              editRolePathUri={`/${organizationSlug}/channels/${channelSlug}/permissions/${role.id}/edit`}
              onDeleteClick={() => {
                onDeleteClick(role.id!)
              }}
            />
          </div>
        ))}
      </div>

      {/* Buttons */}
      <div className="py-4 flex flex-row justify-end">
        <Button
          onClick={onDiscardClick}
          theme="tertiary"
          label={intl.formatMessage({
            defaultMessage: "Back",
            description: "Channel permissions form submit button label",
          })}
        />
      </div>
    </div>
  )
}
