// cSpell:ignore pageview, filepicker
import posthog from "posthog-js"

/*
 * Recommendations for keeping analytics events consistent
 * 1. Track that the action actually happened by placing the event after success - error messages should be in Rollbar
 * 2. Follow this naming convention:
 *      - VideoUploaded - when user successfully uploaded a video (past-tense)
 * 3. Capture intent of an action by leveraging $pageview events and matching URLs, if an action is not a pageview follow this convention:
 *      - EventCreatedIntent - when user wants to create event
 * 4. There is always an exception to these conventions (such as $pageview which is a Posthog convention)
 * */
export enum TrackEventName {
  // Event fired when an event is created.
  ChannelCreated = "ChannelCreated",
  // Event fired when the deleted status of a channel is changed.
  ChannelDeleted = "ChannelDeleted",
  // Event fired when the visibility status of a channel is changed.
  ChannelVisibilityChanged = "ChannelVisibilityChanged",
  // Event fired when a user clicks to create an event using the dashboard.
  EventCreatedIntent = "EventCreatedIntent",
  // Event fired when a user copies a video or channel link to the clipboard.
  LinkShared = "LinkShared",
  // Event fired when a user views a page.
  PageViewed = "$pageview",
  // Event fired when the user pauses video playback.
  VideoPlayerPaused = "VideoPlayerPaused",
  // Event fired when the user resumes video playback.
  VideoPlayerPlayed = "VideoPlayerPlayed",
  // Event fired when a user uploads a video, or creates an event (since that will eventually result in an uploaded video).
  VideoUploaded = "VideoUploaded",
  // Event fired when the visibility status of a video is changed.
  VideoVisibilityChanged = "VideoVisibilityChanged",
}

export type TrackEventParams =
  | {
      name: TrackEventName.ChannelCreated
      attributes: {
        status: "complete" | "error"
      }
    }
  | {
      name: TrackEventName.ChannelDeleted
      attributes: { deleted: boolean }
    }
  | {
      name: TrackEventName.ChannelVisibilityChanged
      attributes: { public: boolean }
    }
  | {
      name: TrackEventName.EventCreatedIntent
      attributes?: {}
    }
  | {
      name: TrackEventName.LinkShared
      attributes:
        | {
            type: "video"
            timestamped: boolean
          }
        | {
            type: "channel"
          }
    }
  | {
      name: TrackEventName.PageViewed
      attributes?: {}
    }
  | {
      name: TrackEventName.VideoPlayerPaused
      attributes: { videoId: string }
    }
  | {
      name: TrackEventName.VideoPlayerPlayed
      attributes: { videoId: string }
    }
  | {
      name: TrackEventName.VideoUploaded
      attributes: { status: "complete" | "error" }
    }
  | {
      name: TrackEventName.VideoVisibilityChanged
      attributes: { public: boolean }
    }

export const trackEvent = ({ name, attributes = {} }: TrackEventParams) => {
  posthog.capture(name, attributes)
}
