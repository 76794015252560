import { FormattedMessage } from "react-intl"
import { VideoStatus } from "../../generated/graphql"
import VideoGrid from "../video-grid"

export default function SuggestedVideos({
  channelName,
  videos,
}: {
  channelName: string
  videos: Array<{
    date: Date
    durationSeconds: number
    id: string
    isChannelPublic: boolean
    isVideoPublic: boolean
    status: VideoStatus
    thumbnailUri?: string
    title: string
    viewCount: number
  }>
}) {
  return (
    <div>
      <div className="text-title3">
        <span className="text-tv-beast">
          <FormattedMessage
            defaultMessage="More from"
            description="More from channel"
          />
        </span>{" "}
        {channelName}
      </div>
      <VideoGrid videos={videos} />
    </div>
  )
}
