import classNames from "classnames"
import { useRef } from "react"
import { useInView } from "react-intersection-observer"
import Link from "../../atoms/link"
import { VideoStatus } from "../../generated/graphql"
import VideoTile from "./video-tile"

export default function VideoGrid({
  isDraggable = false,
  onLoadMore,
  videos,
}: {
  isDraggable?: boolean
  onLoadMore?: () => void
  videos: Array<{
    date: Date
    durationSeconds: number
    id: string
    isChannelPublic: boolean
    isVideoPublic: boolean
    status: VideoStatus
    thumbnailUri?: string
    title: string
    viewCount: number
  }>
}) {
  const sentryInView = useRef(true)

  const { ref: sentryRef } = useInView({
    delay: 100,
    onChange: inView => {
      if (inView === sentryInView.current) {
        return
      }
      sentryInView.current = inView

      if (!inView) {
        return
      }

      onLoadMore?.()
    },
    threshold: 0,
  })

  return (
    <div className="relative my-6">
      <div
        className={classNames(
          "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        )}
      >
        {videos.map(
          ({
            date,
            durationSeconds,
            id,
            isChannelPublic,
            isVideoPublic,
            status,
            thumbnailUri,
            title,
            viewCount,
          }) => (
            // eslint-disable-next-line react/no-array-index-key
            <Link key={id} to={`../../videos/${id.slice(4)}`} relative="path">
              <VideoTile
                date={date}
                durationSeconds={durationSeconds}
                id={id}
                isChannelPublic={isChannelPublic}
                isDraggable={isDraggable}
                isVideoPublic={isVideoPublic}
                thumbnailUri={thumbnailUri}
                status={status}
                title={title}
                viewCount={viewCount}
              />
            </Link>
          )
        )}
      </div>
      <div
        className="absolute bottom-0 w-full h-[400px] max-h-full z-[-1]"
        ref={sentryRef}
      />
    </div>
  )
}
