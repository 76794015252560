import classNames from "classnames"
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from "react"
import { Link } from "react-router-dom"
import { IconType } from "../../atoms/icon/types"
import NavItemIcon from "./nav-item-icon"

const NavItemWithIcon = forwardRef<
  HTMLDivElement,
  Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "children"
  > & {
    href?: string
    icon?: IconType
    imageUri?: string
    name: string
  }
>(({ className, href, icon, imageUri, name, ...otherProps }, ref) => {
  const element = (
    <div
      ref={ref}
      className={classNames(
        "rounded-4xl p-1",
        "flex flex-row items-center gap-2",
        "select-none cursor-pointer border-2 border-transparent hover:bg-tv-obsidian transition-colors",
        className
      )}
      {...otherProps}
    >
      <NavItemIcon
        imageUri={imageUri}
        icon={icon}
        initials={name.slice(0, 1)}
      />
      <div className="font-semibold inline-block truncate">{name}</div>
    </div>
  )

  return href ? <Link to={href}>{element}</Link> : element
})

NavItemWithIcon.displayName = "NavItemWithIcon"
export default NavItemWithIcon
