import { gql, useMutation, useQuery } from "@apollo/client"
import { createUpload } from "@mux/upchunk"
import { useContext } from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { ChannelPickerItem } from "../atoms/channel-picker"
import UploadVideoForm, { ValueType } from "../features/upload-video-form"
import { VideoUploadContext } from "../features/video-upload/video-upload-provider"
import {
  Permission,
  UploadVideoCreateVideoMutation,
  UploadVideoCreateVideoMutationVariables,
  UploadVideoGetChannelsQuery,
  UploadVideoGetChannelsQueryVariables,
} from "../generated/graphql"
import { TrackEventName, trackEvent } from "../track-event"
import { UserContext } from "../user-provider"

const QUERY = gql`
  query UploadVideoGetChannels($organizationSlug: String!) {
    organization(organizationSlug: $organizationSlug) {
      id
      channels(args: { limit: 100 }) {
        items {
          id
          name
          slugs
          logoImageUri
        }
      }
    }
  }
`

const CREATE_VIDEO_MUTATION = gql`
  mutation UploadVideoCreateVideo(
    $channelId: String!
    $title: String!
    $uri: String
  ) {
    createVideo(input: { channelId: $channelId, title: $title, uri: $uri }) {
      uploadUri
      video {
        id
        permalink
      }
    }
  }
`

export default function UploadVideoScreen({
  onDiscardClick,
  onVideoUploaded,
}: {
  onDiscardClick: () => void
  onVideoUploaded: () => void
}) {
  const location = useLocation()
  const { addUpload } = useContext(VideoUploadContext)
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { data } = useQuery<
    UploadVideoGetChannelsQuery,
    UploadVideoGetChannelsQueryVariables
  >(QUERY, {
    variables: {
      organizationSlug: organizationSlug!,
    },
  })
  const [runCreateVideoMutation] = useMutation<
    UploadVideoCreateVideoMutation,
    UploadVideoCreateVideoMutationVariables
  >(CREATE_VIDEO_MUTATION)
  const { hasPermission } = useContext(UserContext)
  // const { droppedFile, setDroppedFile } = useContext(VideoUploadContext)
  const [searchParams] = useSearchParams()
  const channelSlug = searchParams.get("channel")
  const navigate = useNavigate()

  const handleUploadVideoClick = async (value: ValueType) => {
    const { data: createVideoData } = await runCreateVideoMutation({
      variables: {
        channelId: value.channelId,
        title: value.title,
        uri: value.url || null,
      },
    })

    if (createVideoData!.createVideo.uploadUri) {
      addUpload(
        createVideoData!.createVideo.video.id,
        value.title,
        createUpload({
          endpoint: createVideoData!.createVideo.uploadUri!,
          file: value.file!,
          chunkSize: 5120,
        })
      )
    }

    trackEvent({
      name: TrackEventName.VideoUploaded,
      attributes: { status: "complete" },
    })

    onVideoUploaded()

    if (Date.now() < 0) {
      const navigateToChannelSlug =
        channelSlug ??
        data!.organization.channels.items.find(
          channel => channel.id === value.channelId
        )!.slugs[0]

      navigate(`/${organizationSlug}/channels/${navigateToChannelSlug}`, {
        replace: true,
      })
    }
  }

  if (!data?.organization) {
    return null
  }

  const channels = data.organization.channels.items
    .filter(channel => hasPermission(Permission.WriteVideos, channel.id))
    .map<ChannelPickerItem>(channel => ({
      id: channel.id,
      logoImageUri: channel.logoImageUri ?? null,
      name: channel.name,
    }))

  const selectedChannelId = data.organization.channels.items.find(channel =>
    channel.slugs.includes(channelSlug?.toLowerCase() || "")
  )?.id

  return (
    <div className="w-screen max-w-[512px]">
      <UploadVideoForm
        channels={channels}
        file={location.state?.file}
        onDiscardClick={onDiscardClick}
        onUploadVideoClick={handleUploadVideoClick}
        selectedChannelId={selectedChannelId}
      />
    </div>
  )
}
