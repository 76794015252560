import { Player } from "@lottiefiles/react-lottie-player"
import { FormattedMessage } from "react-intl"
import { useLocation, useParams } from "react-router-dom"
import ruhRoh from "../assets/lottiefiles/ruh-roh.json"
import Link from "../atoms/link"

export default function ErrorScreen({ message }: { message?: string }) {
  const { pathname } = useLocation()
  const { organizationSlug } = useParams<{ organizationSlug: string }>()

  const alreadyOnHomePage = pathname === `/${organizationSlug}`

  return (
    <div className="container flex min-h-screen h-full mx-auto items-center justify-center">
      <div className="flex flex-col items-center">
        <Player src={ruhRoh} autoplay loop style={{ height: "192px" }} />
        <h1 className="text-3xl font-bold">
          <FormattedMessage defaultMessage="Uhh, sorry..." />
        </h1>
        <div className="text-tv-dust text-lg">
          {message ? (
            `${message}`
          ) : (
            <FormattedMessage defaultMessage="Could not find what you are looking for." />
          )}
        </div>
        <Link
          to={
            organizationSlug && !alreadyOnHomePage
              ? `/${organizationSlug}`
              : "/"
          }
          className="mt-2 text-tv-dandelion text-base"
        >
          <FormattedMessage defaultMessage="Back home." />
        </Link>
      </div>
    </div>
  )
}
