import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import classNames from "classnames"
import { useContext } from "react"
import { useParams } from "react-router-dom"
import Icon from "../../atoms/icon"
import { IconType } from "../../atoms/icon/types"
import Link from "../../atoms/link"
import { Permission } from "../../generated/graphql"
import { UserContext } from "../../user-provider"
import { SideNavContext } from "./side-nav-provider"

export default function FolderNavItem({
  droppable,
  id,
  isOver,
  name,
  draggable,
}: {
  droppable: boolean
  id: string
  isOver: boolean
  name: string
  draggable: boolean
}) {
  const { organizationSlug } = useParams<{ organizationSlug: string }>()
  const { hasPermission } = useContext(UserContext)
  const { isFolderExpanded, setFolderExpanded } = useContext(SideNavContext)
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      disabled: { draggable: !draggable, droppable: !droppable },
    })

  const isExpanded = isFolderExpanded(id)

  return (
    <button
      type="button"
      onClick={() => setFolderExpanded(id, !isExpanded)}
      className="appearance-none"
    >
      <div
        ref={setNodeRef}
        className={classNames(
          isOver ? "border-tv-dandelion" : "border-transparent",
          "group",
          "rounded-4xl p-1",
          "flex flex-row items-center gap-2",
          "select-none cursor-pointer border-2"
        )}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        {...attributes}
        {...listeners}
      >
        <div className="w-[24px] flex justify-center">
          <Icon type={IconType.Folder} size="small" />
        </div>
        <div className="flex-1 font-semibold inline-block truncate text-left leading-[24px]">
          {name}
        </div>
        {hasPermission(Permission.WriteChannels) && (
          <Link
            className="hidden group-hover:flex basis-[16px] p-1"
            to={`/${organizationSlug}/channel-folders/${id}/edit`}
            onClick={evt => evt.stopPropagation()}
          >
            <Icon type={IconType.Edit} size="small" />
          </Link>
        )}
        <svg
          viewBox="0 0 8 8"
          width="18"
          height="8"
          xmlns="http://www.w3.org/2000/svg"
          className="basis-[8px]"
          color="#979797"
        >
          {isExpanded ? (
            <path d="M 0 2 L 4 6 L 8 2" stroke="currentcolor" fill="none" />
          ) : (
            <path d="M 0 6 L 4 2 L 8 6" stroke="currentcolor" fill="none" />
          )}
        </svg>
      </div>
    </button>
  )
}
